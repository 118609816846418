import MailOutlineIcon from '@mui/icons-material/MailOutline'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import { Divider } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'

import footerBg from '../../../assets/img/footer_bg.svg'
import logo from '../../../assets/img/logo.png'
import Container from '../../atoms/Container'
import Flex from '../../atoms/Flex'

type FooterPropsT = {
  auth: boolean | undefined
}

const StyledFooter = tw.footer`
  pt-1
  pr-0.5
  pb-1
  pl-0.5
  w-full
  bg-primary-dark
  text-secondary
`

const StyledFooterContainer = styled(Container)`
  background-image: url(${footerBg});
  background-position: center center;
`

const StyledFooterHeader = tw(Flex)`
  py-10
`

export const Footer: React.FC<FooterPropsT> = ({ auth }) => {
  return (
    <StyledFooter>
      <StyledFooterContainer className='h-full'>
        {auth && (
          <>
            <StyledFooterHeader justifyContent='space-between' alignItems='center'>
              <Flex alignItems='center'>
                <img src={logo} alt='Logo' width={84} height={50} />
                <nav className='ml-4'>
                  <span className='text-secondary text-xl font-bold'>Portál</span>
                </nav>
              </Flex>
              <Flex>
                <Flex className='text-xl py-3 gap-4'>
                  <Flex className='pr-4'>
                    <PhoneInTalkIcon className='mr-2' />
                    <strong>(+420) 604 342 654</strong>
                  </Flex>
                  <Flex>
                    <MailOutlineIcon className='mr-2' />
                    <strong>information@vuz.com</strong>
                  </Flex>
                </Flex>
              </Flex>
            </StyledFooterHeader>
            <Divider className='bg-secondary-dark' />
          </>
        )}
        <Flex className='py-10 text-xs' justifyContent='space-between' alignItems='center'>
          <Flex>
            <Link to='/'>
              <div className='text-secondary underline'>Podmínky užití</div>
            </Link>

            <Link to='/'>
              <div className='text-secondary underline ml-6'>Soukromí</div>
            </Link>
          </Flex>
          <div>© Copyright 2021 – Výzkumný Ústav Železniční, a.s.</div>
        </Flex>
      </StyledFooterContainer>
    </StyledFooter>
  )
}
