import { SNACKBAR } from '../constants/snackBar'
import { useDispatch } from '../store/hooks'
import { snackBarSlice, SnackBarState } from '../store/slices/snackBar'

export const useSnackbar = () => {
  const dispatch = useDispatch()

  const displaySnackBarWithMessage = ({ message, isOpen, severity }: SnackBarState) => {
    dispatch(
      snackBarSlice.actions.update({
        [SNACKBAR.SNACKBAR_IS_MESSAGE]: message,
        [SNACKBAR.SNACKBAR_IS_OPEN]: isOpen,
        [SNACKBAR.SNACKBAR_IS_SEVERITY]: severity,
      })
    )
  }

  return [displaySnackBarWithMessage]
}
