import { Endpoints } from '../constants/endpoints'
import { loginSlice } from '../store/slices/login'
import { LoginAuthT } from '../types/login'
import { postApiDataBasic } from './_GLOBAL/postApiData'

export const getBearerToken = (auth: LoginAuthT, dispatch: any) => {
  return postApiDataBasic(Endpoints.login(), {}, auth)
    .then((response) => {
      return response?.headers.authorization
    })
    .catch((error) => {
      const response = error.response?.data.split('[').pop().split(']')[0]
      dispatch(
        loginSlice.actions.update({
          errorResponse: response,
        })
      )
    })
}
