import { Button, InputLabel } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { getDataCustomer } from '../../api/data/customer'
import { getDataUserActive } from '../../api/data/user'
import { ROUTES } from '../../constants/routes'
import { useDispatch } from '../../store/hooks'
import { reservationSlice } from '../../store/slices/reservation'
import { DataUserT } from '../../types/types'
import { BaseSelect } from '../atoms/BaseSelect'

type PropsT = {
  title: string
}

const StyledContainer = tw.div`
  w-full
  px-5
  py-7
  bg-secondary-dark
  sm:p-20
`

const StyledTitle = tw.h2`
  text-center
  mb-6
`

const StyledButton = tw.div`
  flex
  justify-end
  mt-4
`

interface IFastReservationFormInput {
  customer: DataUserT['username'] | undefined
}

type SelectListT = {
  name: string
  id: number | string
  ico: string
  value?: string
}

export const FastReservationAdmin: React.FC<PropsT> = ({ title }) => {
  const dispatch = useDispatch()

  const [customersList, setCustomersList] = useState<SelectListT[]>([
    {
      name: '',
      id: 0,
      ico: '',
      value: '',
    },
  ])

  const { control, handleSubmit, setValue } = useForm<IFastReservationFormInput>({
    defaultValues: {
      customer: customersList[0].name,
    },
  })
  const navigate = useNavigate()

  const customersListSorted = useMemo(
    () => customersList.sort((a, b) => a.name.localeCompare(b.name)),
    [customersList]
  )

  useEffect(() => {
    Promise.all([getDataUserActive(), getDataCustomer()]).then((values) => {
      if (values[0] && values[0].length > 0 && values[1] && values[1].length > 0) {
        const customersList = values[0].map((customer, index): SelectListT => {
          return {
            name: `${customer.jmeno} ${customer.prijmeni} ${
              values[1] &&
              ', ' + values[1].find((company) => company.ico === customer.icoZakaznik)?.nazev
            }`,
            id: index,
            ico: customer.icoZakaznik,
            value: customer.username,
          }
        })
        setCustomersList(customersList)
      }
    })
  }, [])

  useEffect(() => {
    if (customersList && customersList.length > 0) {
      setValue('customer', customersList[0].value)
    }
  }, [customersList])

  const onSubmit: SubmitHandler<IFastReservationFormInput> = (data) => {
    if (data) {
      dispatch(
        reservationSlice.actions.update({
          currentCustomerUsername: data.customer,
        })
      )
      navigate(`${ROUTES.CREATE_RESERVATION}/`)
    }
  }

  return (
    <StyledContainer>
      <StyledTitle>{title}</StyledTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputLabel>Zákazník</InputLabel>
        <Controller
          name='customer'
          control={control}
          render={({ field: { onChange, value } }) => (
            <BaseSelect list={customersListSorted} value={value} onChange={onChange} />
          )}
          rules={{
            required: 'Musíte vybrat zakaznika.',
          }}
        />
        <StyledButton>
          <Button variant='contained' color='primary' size='large' type='submit'>
            Pokračovat
          </Button>
        </StyledButton>
      </form>
    </StyledContainer>
  )
}
