import { useResponseStatus } from '../../hooks/useResponseStatus'
import { axiosInstanceBearer } from './axiosInstance'

export async function putApiData<Response>(url: string, data: Object = {}) {
  return await axiosInstanceBearer()
    .put<Response>(url, data)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      useResponseStatus(error.response.status)
      console.log(error)
      throw new Error(error)
    })
}
