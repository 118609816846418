import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CloseIcon from '@mui/icons-material/Close'
import DirectionsTransitFilledIcon from '@mui/icons-material/DirectionsTransitFilled'
import DoneIcon from '@mui/icons-material/Done'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import HomeIcon from '@mui/icons-material/Home'
import WarningIcon from '@mui/icons-material/Warning'
import { IconButton, styled } from '@mui/material'
import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row } from 'react-table'
import tw from 'tailwind-styled-components'

import {
  confirmRequestList,
  confirmRequestListForUserT,
  confirmRequestListT,
  rejectRequestListForUser,
} from '../../api/action/specifyRequest'
import { getRequests } from '../../api/data/requests'
import { getDataSource, getDataSourceTypeAll } from '../../api/data/source'
import { FORMAT } from '../../constants/format'
import { MODALS } from '../../constants/modals'
import { ROUTES } from '../../constants/routes'
import styles, { COLORS } from '../../constants/styles'
import { useSnackbar } from '../../hooks/useSnackbar'
import { useDispatch, useSelector } from '../../store/hooks'
import { modalSlice } from '../../store/slices/modal'
import { reservationsSlice } from '../../store/slices/reservations'
import { snackBarSlice } from '../../store/slices/snackBar'
import { DataSourceT, DataSourceTypeT, Request, UserDetail } from '../../types/types'
import { formatDateWithIncomingFormat } from '../../utils/format'
import { BaseButton } from '../atoms/BaseButton'
import { BaseBreadcrumbs } from '../atoms/BreadCrumbs'
import Flex from '../atoms/Flex'
import { H1 } from '../atoms/Heading'
import StyledPaper from '../atoms/StyledPaper'
import TableWrapper from '../atoms/TableWrapper'
import { BaseTable } from '../molecules/BaseTable'
import { BaseTabs, TabItem } from '../molecules/BaseTabs'
import { RequestDetailDialog } from '../organisms/Modals/RequestDetailDialog/RequestDetailDialog'
import { NearestReservationsAdmin } from '../organisms/NearestReservationsAdmin'
import { RequestsHistoryTable } from '../organisms/RequestsHistoryTable'
import { BaseContainer } from '../templates/BaseContainer'

const StickyFooter = styled('div')(() => ({
  position: 'sticky',
  bottom: 0,
  width: '100%',
  minHeight: '56',
  background: COLORS.primary.dark,
  color: COLORS.primary.contrastText,
  padding: '20px',
}))

const TABS = [
  { name: 'timePlan', label: 'Časový plán' },
  { name: 'handleRequests', label: `Odbavení žádosti` },
  { name: 'requestsHistory', label: 'Historie žádostí' },
]

const renderActionButton = (
  rowItem: Request,
  rejectCallback: (id: number, username: string) => void,
  acceptCallback: (id: number) => void
) => {
  return (
    <Flex>
      {!rowItem.terminObsazeny && (
        <IconButton
          color='primary'
          size='small'
          onClick={(e) => {
            acceptCallback(rowItem.id)
            e.stopPropagation()
          }}>
          {' '}
          <CheckCircleIcon sx={{ fontSize: '1.25rem' }} />
        </IconButton>
      )}
      <IconButton
        color='primary'
        size='small'
        onClick={(e) => {
          rejectCallback(rowItem.id, rowItem.zadavatel.uzivatel.username)
          e.stopPropagation()
        }}>
        {' '}
        <CancelIcon sx={{ fontSize: '1.25rem' }} />
      </IconButton>
      {/* <IconButton
        onClick={() => {
          console.log('funguju clock')
        }}>
        {' '}
        <QueryBuilderIcon />
      </IconButton>
      <IconButton
        onClick={() => {
          console.log('funguju comment')
        }}>
        {' '}
        <CommentIcon />
      </IconButton> */}
    </Flex>
  )
}

const renderCreationDate = (rowItem: Request) => {
  return formatDateWithIncomingFormat(
    rowItem.vytvoreni,
    FORMAT.API_DDMMYYYY_HHMM,
    'DD. MMM., HH:mm:ss'
  )
}

const renderContractor = (rowItem: Request) => {
  return (
    <>
      <span>{`${rowItem.zadavatel.zakaznik.nazev ?? ''} `}</span>
      <span>{`(${rowItem.zadavatel.uzivatel.jmeno ?? ''} ${
        rowItem.zadavatel.uzivatel.prijmeni ?? ''
      })`}</span>
    </>
  )
}

const renderFix = (rowItem: Request) => {
  if (rowItem.fixni) return <CheckCircleIcon color='primary' sx={{ fontSize: '1.25rem' }} />

  return <HighlightOffIcon sx={{ color: styles.COLORS.grey[400], fontSize: '1.25rem' }} />
}

const renderColision = (rowItem: Request) => {
  if (rowItem.terminObsazeny) return <WarningIcon color='warning' sx={{ fontSize: '1.25rem' }} />

  if (rowItem.kolize === 0) return <span>{rowItem.kolize}</span>

  return rowItem.kolize
}

const renderSource = (rowItem: Request) => {
  let source: Request['zdroj']['nazevCz'] | ReactElement = rowItem.zdroj.nazevCz
  if (rowItem.zdroj.nazevCz === 'VZO' || rowItem.zdroj.nazevCz === 'MZO') {
    source = (
      <>
        <Flex alignItems='center'>
          <DirectionsTransitFilledIcon sx={{ fontSize: '1.25rem', marginRight: '0.25rem' }} />
          {rowItem.zdroj.nazevCz}
        </Flex>
      </>
    )
  } else {
    source = (
      <>
        <Flex alignItems='center'>
          <HomeIcon sx={{ fontSize: '1.25rem', marginRight: '0.25rem' }} />
          {rowItem.zdroj.nazevCz}
        </Flex>
      </>
    )
  }
  return source
}

const renderTermin = (rowItem: Request) => {
  const formatedDate =
    formatDateWithIncomingFormat(rowItem.termin.terminOd, FORMAT.API_DDMMYYYY_HHMM, 'DD') ===
    formatDateWithIncomingFormat(rowItem.termin.terminDo, FORMAT.API_DDMMYYYY_HHMM, 'DD')
      ? `${formatDateWithIncomingFormat(
          rowItem.termin.terminOd,
          FORMAT.API_DDMMYYYY_HHMM,
          'DD. MMM., HH:mm'
        )} - ${formatDateWithIncomingFormat(
          rowItem.termin.terminDo,
          FORMAT.API_DDMMYYYY_HHMM,
          'HH:mm'
        )}`
      : `${formatDateWithIncomingFormat(
          rowItem.termin.terminOd,
          FORMAT.API_DDMMYYYY_HHMM,
          'DD. MMM., HH:mm'
        )} - ${formatDateWithIncomingFormat(
          rowItem.termin.terminDo,
          FORMAT.API_DDMMYYYY_HHMM,
          'DD. MMM., HH:mm'
        )}`
  if (rowItem.terminObsazeny)
    return (
      <>
        <div>{formatedDate}</div>
        <div>Plno</div>
      </>
    )

  return formatedDate
}

interface IHandleReservationTable {
  tableData: Request[]
  loadData: () => Promise<void>
}

const HandleReservationTable: React.FC<IHandleReservationTable> = ({ tableData, loadData }) => {
  const dispatch = useDispatch()
  const [displaySnackBarWithMessage] = useSnackbar()

  const openDetailModal = (row: Row<Object>) => {
    dispatch(
      modalSlice.actions.update({
        // @ts-ignore
        [MODALS.REQUEST_DETAIL_ID]: row.original.id,
        // @ts-ignore
        [MODALS.REJECT_REQUEST_USERNAME]: row.original.zadavatel.uzivatel.username,
        [MODALS.REQUEST_DETAIL_MODAL]: true,
      })
    )
  }

  const [selectedFlatRows, setSelectedFlatRows] = useState<Row<object>[]>([])
  const ref = useRef<{ toggleAllRowsSelected: (value?: boolean | undefined) => void }>({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    toggleAllRowsSelected: (value?: boolean | undefined) => null,
  })

  useEffect(() => {
    return () => {
      snackBarSlice.actions.reset()
    }
  }, [])

  const rejectRequests = async (idArray: confirmRequestListForUserT[]) => {
    const response = await rejectRequestListForUser(idArray)
    const multipleOrOneId = idArray.length === 1 ? 'požadavek' : 'všechny požadavky'
    if (response.some((res) => 'error' in res)) {
      const errorMessage = response.find((responseItem) => responseItem.error)
      displaySnackBarWithMessage({
        message: `Nepodařilo se zamítnout  ${multipleOrOneId}, ${errorMessage?.error}`,
        isOpen: true,
        severity: 'error',
      })
    } else {
      displaySnackBarWithMessage({
        message: `Podařilo se zamítnout ${multipleOrOneId}`,
        isOpen: true,
        severity: 'success',
      })
    }
    await loadData()
  }

  const acceptRequests = async (idArray: confirmRequestListT[]) => {
    // Delete property usernameZadavatel during accept process
    idArray.forEach((object) => {
      delete object['usernameZadavatel']
    })

    const response = await confirmRequestList(idArray)
    const multipleOrOneId = idArray.length === 1 ? 'požadavek' : 'všechny požadavky'
    if (response.some((res) => 'error' in res)) {
      const errorMessage = response.find((responseItem) => responseItem.error)
      displaySnackBarWithMessage({
        message: `Nepodařilo se odsouhlasit ${multipleOrOneId}, ${errorMessage?.error}`,
        isOpen: true,
        severity: 'error',
      })
    } else {
      displaySnackBarWithMessage({
        message: `Podařilo se odsouhlasit ${multipleOrOneId}`,
        isOpen: true,
        severity: 'success',
      })
    }
    await loadData()
  }

  const openRejectModal = (id: number, username: UserDetail['username']) => {
    dispatch(
      modalSlice.actions.update({
        [MODALS.REJECT_REQUEST_MODAL]: true,
        [MODALS.REJECT_REQUEST_ID]: id,
        [MODALS.REJECT_REQUEST_USERNAME]: username,
      })
    )
  }

  const openAcceptModal = (id: number) => {
    dispatch(
      modalSlice.actions.update({
        [MODALS.ACCEPT_REQUEST_MODAL]: true,
        [MODALS.ACCEPT_REQUEST_ID]: id,
      })
    )
  }

  const currentFilter = useSelector((state) => state.reservations.filterBySource)

  function SourceFilter({
    // @ts-ignore
    column: { setFilter },
  }) {
    useEffect(() => setFilter(currentFilter), [currentFilter])

    return <></>
  }

  function filterSourceByName(rows: any[], id: any, filterValue: any) {
    return rows.filter((row) => {
      const rowValue = row.original.zdroj.nazevCz
      if (filterValue) return rowValue === filterValue
      return rowValue
    })
  }

  const data = useMemo(() => tableData, [tableData, currentFilter])
  const columns = useMemo(
    () => [
      {
        Header: 'Zdroj',
        accessor: 'zdroj',
        // @ts-ignore
        Cell: ({ row }) => {
          return renderSource(row.original)
        },
        Filter: SourceFilter,
        filter: filterSourceByName,
        sortType: (a: Row<any>, b: Row<any>) => {
          const rowA = a.original.zdroj.nazevCz
          const rowB = b.original.zdroj.nazevCz
          if (rowA > rowB) return -1
          if (rowB > rowA) return 1
          return 0
        },
        // Filter: (props: any) => {
        //   return (
        //     <AutocompleteColumnFilter
        //       autoCompleteProps={{
        //         options: testOptions,
        //         inputlabel: 'test',
        //         onChange: (event) => console.log(event, 'event'),
        //         renderOption: (props, option, state) => {
        //           // console.log(props, option, state)
        //           return (
        //             <Box component='li' key={props.id} {...props}>
        //               {option.icon} {`${option.label}`}
        //             </Box>
        //           )
        //         },
        //       }}
        //     />
        //   )
        // },
        // filter: 'equals',
      },
      {
        Header: 'Termín',
        accessor: 'termin.terminOd',
        // @ts-ignore
        Cell: ({ row }) => {
          return renderTermin(row.original)
        },
        disableFilters: true,
      },
      {
        Header: 'Kolize',
        accessor: 'kolize',
        // @ts-ignore
        Cell: ({ row }) => {
          return renderColision(row.original)
        },
        disableFilters: true,
      },
      {
        Header: 'Fixní',
        accessor: 'fixni',
        // @ts-ignore
        Cell: ({ row }) => {
          return renderFix(row.original)
        },
        disableFilters: true,
        sortType: (a: Row<any>, b: Row<any>) => {
          const rowA = a.original.fixni
          const rowB = b.original.fixni
          if (rowA > rowB) return -1
          if (rowB > rowA) return 1
          return 0
        },
      },
      {
        Header: 'Zadavatel',
        accessor: 'zadavatel.zakaznik.nazev',
        // @ts-ignore
        Cell: ({ row }) => {
          return renderContractor(row.original)
        },
        disableFilters: true,
      },
      {
        Header: 'Doplň. služby',
        accessor: 'doplnkoveSluzby',
        disableFilters: true,
      },
      {
        Header: 'Vytvoření',
        accessor: 'vytvoreni',
        // @ts-ignore
        Cell: ({ row }) => {
          return renderCreationDate(row.original)
        },
        disableFilters: true,
      },
      {
        Header: 'Rychlé akce',
        // @ts-ignore
        Cell: ({ row }) => {
          return renderActionButton(row.original, openRejectModal, openAcceptModal)
        },
        disableFilters: true,
      },
    ],
    [tableData, currentFilter]
  )

  const closeStickyFooter = () => {
    setSelectedFlatRows([])
    ref.current.toggleAllRowsSelected(false)
  }

  const someSourceIsOccupied = useMemo(() => {
    return selectedFlatRows.some((row) => {
      const original = row.original as Request
      return !!original.terminObsazeny
    })
  }, [selectedFlatRows])

  const selectedRowsIds = useMemo(() => {
    return selectedFlatRows.map((row) => {
      const original = row.original as Request
      return {
        id: original.id,
        usernameZadavatel: original.zadavatel.uzivatel.username,
        poznamka: '',
      }
    })
  }, [selectedFlatRows])

  return (
    <>
      <BaseTable
        small
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onSelectedRowsChange={(selectedFlatRows, _selectedRowIds) => {
          setSelectedFlatRows(selectedFlatRows)
        }}
        onRowClick={openDetailModal}
        showSelectable
        isSortable
        columns={columns}
        data={data}
        ref={ref}
      />
      {selectedFlatRows.length > 0 && (
        <StickyFooter>
          <Flex justifyContent='space-between' alignItems='center'>
            <Flex alignItems='center'>
              <IconButton color='secondary' onClick={() => closeStickyFooter()}>
                <CloseIcon />
              </IconButton>
              {`${selectedFlatRows.length} položek`}
            </Flex>
            <Flex alignItems='center'>
              {someSourceIsOccupied && <div>Alespoň jeden ze zdrojů je obsazený </div>}
              <BaseButton
                color='secondary'
                disabled={someSourceIsOccupied}
                onClick={async () => {
                  acceptRequests(selectedRowsIds)
                  closeStickyFooter()
                }}
                startIcon={<DoneIcon />}>
                Odsouhlasit
              </BaseButton>
              <BaseButton
                color='secondary'
                onClick={async () => {
                  rejectRequests(selectedRowsIds)
                  closeStickyFooter()
                }}
                startIcon={<CloseIcon />}>
                Zamítnout
              </BaseButton>
            </Flex>
          </Flex>
        </StickyFooter>
      )}
    </>
  )
}

const handleFilterResetClick = (dispatch: (e: any) => void) => {
  dispatch(reservationsSlice.actions.reset())
}

interface tableData {
  requestTable: Request[]
}

const StyledFilterSource = tw.div`
flex
justify-end
items-center
text-xs
pb-3
`

export const ReservationPage: React.FC = () => {
  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState(TABS[1].name)
  const [tableData, setTableData] = useState<tableData>({ requestTable: [] })
  const [sources, setSource] = useState<DataSourceT[]>([])
  const [sourcesType, setSourcesType] = useState<DataSourceTypeT[]>([])
  const navigate = useNavigate()

  const rejectRequestIsSending = useSelector((state) => !state.modal.rejectRequestIsSending)
  const acceptRequestIsSending = useSelector((state) => !state.modal.acceptRequestIsSending)
  const currentFilter = useSelector((state) => state.reservations.filterBySource)

  const loadData = async () => {
    const response = await getRequests()
    const filteredResponse = response.filter((request) => request.stav.name === 'PRIJATY')
    setTableData({ ...tableData, requestTable: filteredResponse })
  }

  useEffect(() => {
    Promise.all([getDataSource(), getDataSourceTypeAll()]).then((values) => {
      values[0] && setSource(values[0])
      if (values[1]) {
        setSourcesType(values[1])
      }
    })
  }, [])

  useEffect(() => {
    loadData()
  }, [rejectRequestIsSending, acceptRequestIsSending])

  return (
    <>
      <BaseContainer>
        <BaseBreadcrumbs />
        <Flex alignItems='center' justifyContent='space-between' className='mt-7 mb-7'>
          <H1 noMargin>Rezervace</H1>
          <BaseButton
            variant='outlined'
            size='large'
            onClick={() => navigate(ROUTES.CREATE_RESERVATION)}>
            Vytvořit rezervaci
          </BaseButton>
        </Flex>

        <StyledPaper>
          <BaseTabs
            value={selectedTab}
            tabs={TABS}
            onChange={function (currentSourceName: string): void {
              setSelectedTab(currentSourceName)
            }}
          />
          <TabItem currentValue={selectedTab} tabItemValue={TABS[0].name}>
            <NearestReservationsAdmin
              title=''
              hideShowMoreButton
              sourcesType={sourcesType}
              sources={sources}
            />
          </TabItem>
          <TabItem currentValue={selectedTab} tabItemValue={TABS[1].name}>
            <StyledPaper>
              {currentFilter && (
                <StyledFilterSource>
                  Vybraný zdroj: <strong className='ml-1'>{currentFilter}</strong>
                  <CancelIcon
                    color='error'
                    onClick={() => handleFilterResetClick(dispatch)}
                    className='ml-2'
                    sx={{ fontSize: '1.25rem' }}
                  />
                </StyledFilterSource>
              )}
              <TableWrapper>
                <HandleReservationTable loadData={loadData} tableData={tableData.requestTable} />
              </TableWrapper>
            </StyledPaper>
          </TabItem>
          <TabItem currentValue={selectedTab} tabItemValue={TABS[2].name}>
            <StyledPaper>
              <RequestsHistoryTable />
            </StyledPaper>
          </TabItem>
        </StyledPaper>
      </BaseContainer>
      <RequestDetailDialog
        requestState={selectedTab === 'handleRequests' ? 'PRIJATY' : 'POTVRZENY'}
      />
    </>
  )
}
