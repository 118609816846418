import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined'
import EventNoteIcon from '@mui/icons-material/EventNote'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import ListAltIcon from '@mui/icons-material/ListAlt'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { ROLES } from '../../constants/roles'
import { ROUTES } from '../../constants/routes'
import { useSelector } from '../../store/hooks'
import Flex from '../atoms/Flex'

type TitleProps = {
  $active?: boolean
}

const StyledSubMenuItem = tw(Flex)<TitleProps>`
  ${({ $active }) => ($active ? 'bg-primary' : '')}
  px-6
  h-full
  py-2.5
  hover:bg-primary
`

const StyledSubmenuWrapper = tw(Flex)`
  bg-primary-light
`

export const Submenu: React.FC = () => {
  const location = useLocation()

  const isAdmin = useSelector(
    (state) => state.user.role === ROLES.ADMIN || state.user.role === ROLES.VUZ_SPRAVCE
  )

  return (
    <StyledSubmenuWrapper justifyContent='center' alignItems='center'>
      <Link className='no-underline text-primary-contrast' to={ROUTES.DASHBOARD}>
        <StyledSubMenuItem alignItems='center' $active={location.pathname === ROUTES.DASHBOARD}>
          <HomeOutlinedIcon fontSize='small' className='mr-2 text-primary-contrast' />
          Přehled
        </StyledSubMenuItem>
      </Link>
      <Link className='no-underline text-primary-contrast' to={ROUTES.RESERVATION}>
        <StyledSubMenuItem alignItems='center' $active={location.pathname === ROUTES.RESERVATION}>
          <EventNoteIcon fontSize='small' className='mr-2 text-primary-contrast' />
          Rezervace
        </StyledSubMenuItem>
      </Link>
      <Link className='no-underline text-primary-contrast' to={ROUTES.NEWS}>
        <StyledSubMenuItem alignItems='center' $active={location.pathname === ROUTES.NEWS}>
          <ListAltIcon fontSize='small' className='mr-2 text-primary-contrast' />
          Provozní informace
        </StyledSubMenuItem>
      </Link>
      {isAdmin && (
        <Link className='no-underline text-primary-contrast' to={ROUTES.ADMINISTRATION}>
          <StyledSubMenuItem
            alignItems='center'
            $active={location.pathname === ROUTES.ADMINISTRATION}>
            <BusinessCenterOutlinedIcon fontSize='small' className='mr-2 text-primary-contrast' />
            Administrace
          </StyledSubMenuItem>
        </Link>
      )}
    </StyledSubmenuWrapper>
  )
}
