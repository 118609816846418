import './assets/styles/global.css'

import React, { useEffect } from 'react'
import { Navigate, useLocation, useRoutes } from 'react-router-dom'
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs'

import { AdministrationPage } from './components/pages/AdministrationPage'
import { CreateReservationPage } from './components/pages/CreateReservationPage'
import { CustomerDetailAdministrationPage } from './components/pages/CustomerDetailAdministrationPage'
import { LoginPage } from './components/pages/LoginPage'
import { NewsDetailPage } from './components/pages/NewsDetailPage'
import { NewsPage } from './components/pages/NewsPage'
import { OverviewPage } from './components/pages/OverviewPage'
import { OverviewPageAdmin } from './components/pages/OverviewPageAdmin'
import { ReservationPage } from './components/pages/ReservationPage'
import { ResetPasswordPage } from './components/pages/ResetPasswordPage'
import { Layout } from './components/templates/Layout'
import { API } from './constants/api'
import { APP } from './constants/app'
import { ROLES } from './constants/roles'
import { ROUTES } from './constants/routes'
import { useLogout } from './hooks/useLogout'
import { useSelector } from './store/hooks'
import { getFromLocalStorage } from './utils/localStorage'

type RequireAuthT = {
  children: React.ReactElement
}

function RequireAuth({ children }: RequireAuthT) {
  const isAuthenticated =
    useSelector((state) => state.user.logged) && !!getFromLocalStorage(API.BEARER_TOKEN)
  return isAuthenticated ? children : <Navigate to={ROUTES.LOGIN} />
}

export const useRoutesObject = () => {
  const isAuthenticated =
    useSelector((state) => state.user.logged) && !!getFromLocalStorage(API.BEARER_TOKEN)
  const isAdmin = useSelector(
    (state) => state.user.role === ROLES.ADMIN || state.user.role === ROLES.VUZ_SPRAVCE
  )
  const isEditor = useSelector((state) => state.user.role === ROLES.VUZ_EDITOR)

  const routesObject: BreadcrumbsRoute[] = [
    {
      path: ROUTES.DASHBOARD,
      element: <Layout auth={isAuthenticated} />,
      children: [
        {
          breadcrumb: 'Dashboard',
          index: true,
          element: (
            <RequireAuth>
              {isAdmin || isEditor ? <OverviewPageAdmin /> : <OverviewPage />}
            </RequireAuth>
          ),
        },
        {
          path: ROUTES.LOGIN,
          element: !isAuthenticated ? <LoginPage /> : <Navigate to={ROUTES.DASHBOARD} />,
        },
        {
          path: ROUTES.RESET_PASSWORD,
          element: <ResetPasswordPage />,
        },
        {
          path: ROUTES.RESERVATION,
          element: (
            <RequireAuth>
              {isAdmin || isEditor ? <ReservationPage /> : <CreateReservationPage />}
            </RequireAuth>
          ),
        },
        {
          path: ROUTES.CREATE_RESERVATION,
          element: (
            <RequireAuth>
              <CreateReservationPage />
            </RequireAuth>
          ),
        },
        {
          path: `${ROUTES.CREATE_RESERVATION}/:projectId`,
          element: (
            <RequireAuth>
              <CreateReservationPage />
            </RequireAuth>
          ),
        },
        // Possible child component ?
        {
          path: `${ROUTES.RESERVATION}/:projectId`,
          element: (
            <RequireAuth>
              <ReservationPage />
            </RequireAuth>
          ),
        },
        {
          path: ROUTES.NEWS,
          element: (
            <RequireAuth>
              <NewsPage />
            </RequireAuth>
          ),
        },
        {
          path: `${ROUTES.NEWS_DETAIL}/:articleId`,
          element: (
            <RequireAuth>
              <NewsDetailPage />
            </RequireAuth>
          ),
        },
        {
          path: ROUTES.ADMINISTRATION,
          breadcrumb: 'admin',
          element: isAdmin && (
            <RequireAuth>
              <AdministrationPage />
            </RequireAuth>
          ),
        },
        {
          path: `${ROUTES.ADMINISTRATION_CUSTOMER_DETAIL}/:id`,
          breadcrumb: 'detail zákazníka',
          element: (
            <RequireAuth>
              <CustomerDetailAdministrationPage />
            </RequireAuth>
          ),
        },
        {
          path: '*',
          element: <LoginPage />,
        },
      ],
    },
  ]
  return routesObject
}

export const App: React.FC = () => {
  // Logout user after inactivity
  const location = useLocation()
  useEffect(() => {
    let time: any
    if (location.pathname !== ROUTES.LOGIN) {
      const resetTimer = () => {
        clearTimeout(time)
        time = setTimeout(logout, APP.CONFIG.USER_INACTIVITY)
      }
      const logout = () => {
        alert('Byli jste odhlášeni z důvodu nečinnosti v posledních 30 minutách.')
        useLogout()
      }
      window.onload = resetTimer
      document.onmousemove = resetTimer
      document.onkeydown = resetTimer
    } else {
      window.onload = null
      document.onmousemove = null
      document.onkeydown = null
    }
    return () => clearTimeout(time)
  }, [location])

  const routesObject = useRoutesObject()
  const element = useRoutes(routesObject)

  return <div>{element}</div>
}
