/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'

import StyledPaper from './StyledPaper'

export type InfoRowTableCell = {
  title: string
  icon: JSX.Element
  info: JSX.Element
}

interface ImportantInfoRowProps {
  cells: InfoRowTableCell[]
}

const StyledImportantInfoCell = styled.div`
  &:not(:last-of-type) {
    border-right-width: 1px;
  }
`

const ImportantInfoCell = tw(StyledImportantInfoCell)`
  border-gray-400
  px-5
  first:pl-0
  last:pr-0
  text-sm
`

const ImportantInfoCellTitle = tw.div`
  mb-2
  flex
  gap-1
  items-center
  text-gray-500
  text-xs
`

export const ImportantInfoRow: React.FC<ImportantInfoRowProps> = ({ cells }) => {
  return (
    <>
      {cells.length > 0 && (
        <StyledPaper
          small
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            width: '100%',
            marginBottom: '1rem',
          }}>
          {cells.map((cell, index) => (
            <ImportantInfoCell key={index}>
              <ImportantInfoCellTitle>
                {cell.icon}
                {cell.title}
              </ImportantInfoCellTitle>
              <div>{cell.info}</div>
            </ImportantInfoCell>
          ))}
        </StyledPaper>
      )}
    </>
  )
}
