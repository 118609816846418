import React from 'react'
import tw from 'tailwind-styled-components'

type HeadingSizes = 'H1' | 'H2'

type Props = {
  children: any
  size?: HeadingSizes
  centered?: boolean
  noMargin?: boolean
  primaryColor?: boolean
}

type PropsHeadingH1 = {
  $size?: HeadingSizes
  $centered?: boolean
  $noMargin?: boolean
}

type PropsHeadinghH2 = {
  $centered?: boolean
}

type PropsHeadinghH3 = {
  $centered?: boolean
  $primaryColor?: boolean
}

type PropsHeadinghH4 = {
  $centered?: boolean
  $primaryColor?: boolean
}

type PropsHeadinghH5 = {
  $centered?: boolean
}

const HeadingH1 = tw.h1<PropsHeadingH1>`
  text-5xl
  font-bold
  ${({ $size }) => ($size === 'H2' ? 'text-4xl' : 'text-5xl')}
  ${(p) => (p.$centered ? 'text-center' : '')}
  ${(p) => (p.$noMargin ? '' : 'mb-5')}
`

const HeadingH2 = tw.h2<PropsHeadinghH2>`
  text-4xl
  mb-5
  font-medium
  ${(p) => (p.$centered ? 'text-center' : '')} 
`

const HeadingH3 = tw.h3<PropsHeadinghH3>`
  text-2xl
  font-medium
  mb-4
  ${(p) => (p.$centered ? 'text-center' : '')} 
  ${(p) => (p.$primaryColor ? 'text-primary hover:text-primary-light' : '')} 
`

const HeadingH5 = tw.h4<PropsHeadinghH5>`
  font-bold
  text-xs
  mb-2
  ${(p) => (p.$centered ? 'text-center' : '')}
`

const HeadingH4 = tw.h4<PropsHeadinghH4>`
  font-medium
  ${(p) => (p.$centered ? 'text-center' : '')}
  ${(p) => (p.$primaryColor ? 'text-primary hover:text-primary-light' : '')} 
`

export const H1: React.ComponentType<Props> = ({ children, size, centered, noMargin }) => {
  return (
    <HeadingH1 $size={size} $centered={centered} $noMargin={noMargin}>
      {children}
    </HeadingH1>
  )
}

export const H2: React.ComponentType<Props> = ({ children, centered }) => {
  return <HeadingH2 $centered={centered}>{children}</HeadingH2>
}

export const H3: React.ComponentType<Props> = ({ children, centered, primaryColor }) => {
  return (
    <HeadingH3 $centered={centered} $primaryColor={primaryColor}>
      {children}
    </HeadingH3>
  )
}

export const H4: React.ComponentType<Props> = ({ children, centered, primaryColor }) => {
  return (
    <HeadingH4 $centered={centered} $primaryColor={primaryColor}>
      {children}
    </HeadingH4>
  )
}

export const H5: React.ComponentType<Props> = ({ children, centered }) => {
  return <HeadingH5 $centered={centered}>{children}</HeadingH5>
}
