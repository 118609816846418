import { createSlice } from '@reduxjs/toolkit'

import { objectReducerGenerator } from '../objectReducerGenerator'

export interface LoginState {
  errorResponse: string
}

const initialState: LoginState = {
  errorResponse: '',
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    ...objectReducerGenerator<LoginState>(),
  },
})
