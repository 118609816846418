import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'

import { COLORS } from '../../constants/styles'
import { TimeSlot } from '../atoms/TimeSlot'

type PropsT = {
  name?: string
  value: string
  time: string
  usedSlots: number
  maxSlots: number
  disabled?: boolean
}

type StyledRadioButtonLabelPropsT = {
  $usedSlots: number
  $maxSlots: number
}

type StyledRadioButtonT = {
  $clickable: boolean
}

const StyledRadioButton = styled.div<StyledRadioButtonT>`
  flex: 1;
  position: relative;
  & input[type='radio'] {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: ${({ $clickable }) => ($clickable ? 'pointer' : 'auto')};
  }
  & input[type='radio']:checked + label {
    color: ${COLORS.primary.contrastText};
    background-color: ${COLORS.primary.main};
    border-color: ${COLORS.primary.dark};
  }
  & label:hover {
    color: ${COLORS.primary.contrastText};
    background-color: ${COLORS.primary.dark};
    border-color: ${COLORS.primary.dark};
  }
`

const StyledRadioButtonLabel = tw.label<StyledRadioButtonLabelPropsT>`
${({ $usedSlots }) => ($usedSlots === 0 ? 'bg-secondary' : '')}
${({ $usedSlots, $maxSlots }) => ($usedSlots > 0 && $usedSlots < $maxSlots ? 'bg-gray-100' : '')}
${({ $usedSlots, $maxSlots }) => ($usedSlots === $maxSlots ? 'bg-secondary-light border-none' : '')}
  inline-block
  w-full
  rounded
  border
  border-solid 
  border-gray-300
  text-center
`

export const FieldTimeSlot: React.FC<PropsT> = ({
  name = 'shift',
  value,
  time,
  usedSlots,
  maxSlots,
  disabled = false,
}) => {
  const { register } = useFormContext()
  return (
    <StyledRadioButton $clickable={usedSlots !== maxSlots && !disabled}>
      <input
        type='radio'
        id={value}
        {...register(name, { required: 'Musíte vybrat časový slot.' })}
        value={value}
        disabled={usedSlots === maxSlots || disabled}
      />
      <StyledRadioButtonLabel $usedSlots={usedSlots} $maxSlots={maxSlots} htmlFor={name}>
        <TimeSlot time={time} usedSlots={usedSlots} maxSlots={maxSlots} />
      </StyledRadioButtonLabel>
    </StyledRadioButton>
  )
}
