/* eslint-disable react/react-in-jsx-scope */
import { Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'

import { useRoutesObject } from '../../App'

export const BaseBreadcrumbs: React.FC = () => {
  const routesObjec = useRoutesObject()
  const breadcrumbs = useBreadcrumbs(routesObjec)

  return (
    <>
      {breadcrumbs.map((breadCrumbItem, index) => {
        const { breadcrumb } = breadCrumbItem
        return (
          <React.Fragment key={index}>
            {index === breadcrumbs.length - 1 ? (
              <Typography component='span' sx={{ fontSize: '0.75rem' }}>
                {' '}
                {breadcrumb}
              </Typography>
            ) : (
              <>
                <Link to={breadCrumbItem.key}>
                  {' '}
                  <Typography component='span' sx={{ fontSize: '0.75rem' }}>
                    {breadcrumb}
                  </Typography>
                </Link>{' '}
                /{' '}
              </>
            )}{' '}
          </React.Fragment>
        )
      })}
    </>
  )
}
