import * as React from 'react'
import { Link } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { ROUTES } from '../../constants/routes'
import { useDispatch } from '../../store/hooks'
import { reservationsSlice } from '../../store/slices/reservations'
import Flex from './Flex'

type PropsT = {
  icon: React.ReactNode
  title: string
  requestsQuantity: number
}

type StyledContainerPropsT = {
  $active: boolean
}

const StyledContainer = tw.div<StyledContainerPropsT>`
flex
p-2
border
${(p) => (p.$active ? 'bg-secondary-light' : 'bg-secondary-dark')}
rounded-md
border-gray-300
`

const StyledIcon = tw.div`
mr-1
`

const StyledDesc = tw.div`
text-xs
`

const handleSourceClick = (dispatch: (e: any) => void, title: PropsT['title']) => {
  dispatch(
    reservationsSlice.actions.update({
      filterBySource: title,
    })
  )
}

export const SourceRequestBox: React.ComponentType<PropsT> = ({
  icon,
  title,
  requestsQuantity,
}) => {
  const dispatch = useDispatch()
  const RenderSourceRequestBox = () => (
    <StyledContainer $active={requestsQuantity > 0}>
      <StyledIcon>{icon}</StyledIcon>
      <Flex justifyContent='center' flexDirection='column'>
        <strong>{title}</strong>
        <StyledDesc>
          {requestsQuantity > 0 ? `${requestsQuantity} neodbavených` : `Vše odbaveno`}
        </StyledDesc>
      </Flex>
    </StyledContainer>
  )

  return (
    <>
      {requestsQuantity > 0 ? (
        <Link
          className='no-underline'
          onClick={() => handleSourceClick(dispatch, title)}
          to={ROUTES.RESERVATION}>
          <RenderSourceRequestBox />
        </Link>
      ) : (
        <RenderSourceRequestBox />
      )}
    </>
  )
}
