import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import { Button, Dialog, DialogContent } from '@mui/material'
import ModalMaterial from '@mui/material/Modal'
import * as React from 'react'
import { useState } from 'react'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'

import { useDispatch } from '../../store/hooks'
import { modalSlice } from '../../store/slices/modal'
import { H3 } from '../atoms/Heading'

export interface Props {
  modalId: string
  title?: string
  onClose?: () => void
  open?: boolean
  centered?: boolean
  type?: 'main' | 'small' | 'noPadding'
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg'
}

type StyledModalChildrenT = {
  $centered?: boolean
}

type StyledModalContentT = {
  $type?: 'main' | 'small' | 'noPadding'
}

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 0.5rem;
  }
  .MuiDialogContent-root {
    padding: 0;
  }
`

const StyledModalGrid = tw.div`
  h-full
  w-full
  flex
  justify-center
  items-start
  rounded-lg
`

const StyledModalContent = tw.div<StyledModalContentT>`
  flex
  flex-col
  relative
  w-full
  transform
  bg-white
  h-full
  overflow-hidden
  ${({ $type }) => ($type === 'main' ? 'max-w-full py-20 px-24' : '')}
  ${({ $type }) => ($type === 'small' ? 'max-w-480 p-8' : '')}
  ${({ $type }) => ($type === 'noPadding' ? 'max-w-full' : '')}

`

const StyledModalButtonClose = tw.div`
  flex
  justify-end
  pb-4
  absolute
  right-1
  top-2
`

const StyledModalChildren = tw.div<StyledModalChildrenT>`
  ${({ $centered }) => ($centered ? 'flex justify-center' : '')}
`

export const Modal: React.FC<Props> = ({
  modalId,
  children,
  title,
  onClose,
  open = false,
  centered = false,
  type = 'main',
  maxWidth = 'sm',
}) => {
  const [openState, setOpenState] = useState(false)

  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(
      modalSlice.actions.update({
        [modalId]: false,
      })
    )
    if (onClose) {
      onClose()
    }
    setOpenState(false)
  }

  React.useEffect(() => setOpenState(open), [open])

  return (
    <ModalMaterial open={openState} onClose={handleClose} onBackdropClick={() => null}>
      <StyledDialog open={openState} scroll='paper' maxWidth={maxWidth} fullWidth>
        <DialogContent>
          <StyledModalGrid>
            <StyledModalContent $type={type}>
              <StyledModalButtonClose>
                <Button onClick={handleClose}>
                  <CloseOutlinedIcon color='primary' />
                </Button>
              </StyledModalButtonClose>
              {title && <H3>{title}</H3>}
              <StyledModalChildren $centered={centered}>{children}</StyledModalChildren>
            </StyledModalContent>
          </StyledModalGrid>
        </DialogContent>
      </StyledDialog>
    </ModalMaterial>
  )
}
