import { Button, ButtonGroup } from '@mui/material'
import * as React from 'react'
import tw from 'tailwind-styled-components'

import styles from '../../constants/styles'
import Flex from './Flex'

export type HandleOnClickCounterButtonsT = {
  handleOnClickCounterButtons: (action: 'increase' | 'decrease', value: number) => void
}

interface IProps extends HandleOnClickCounterButtonsT {
  value: any
}

const Input = tw.input`
  border-t
  border-b
  border-gray-300
  text-center
  bg-gray-50
  w-full
  min-h-56
`

export const BaseCounterInput: React.FC<IProps> = ({ value, handleOnClickCounterButtons }) => {
  return (
    <ButtonGroup className='w-full'>
      <Flex className='w-full'>
        <Button
          sx={{
            borderColor: styles.COLORS.grey[300],
            backgroundColor: styles.COLORS.grey[50],
          }}
          onClick={() => handleOnClickCounterButtons('decrease', value)}>
          -
        </Button>
        <Input disabled value={value} />

        <Button
          sx={{
            borderColor: styles.COLORS.grey[300],
            backgroundColor: styles.COLORS.grey[50],
            borderLeftColor: 'transparent',
          }}
          onClick={() => handleOnClickCounterButtons('increase', value)}>
          +
        </Button>
      </Flex>
    </ButtonGroup>
  )
}
