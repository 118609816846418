import React from 'react'
import tw from 'tailwind-styled-components'

type InfoBoxPropsT = {
  children: React.ReactNode
  icon: React.ReactNode
}

const StyledContainer = tw.div`
  flex
  items-center
  gap-4
  bg-secondary-dark
  w-full
  px-
  px-12
  py-5
  mb-2
  rounded-sm
`

const StyledIcon = tw.div`
  text-primary
  text-4xl
`

const StyledChildren = tw.div`
  text-sm
`

export const InfoBox: React.FC<InfoBoxPropsT> = ({ children, icon }) => {
  return (
    <StyledContainer>
      <StyledIcon>{icon}</StyledIcon>
      <StyledChildren>{children}</StyledChildren>
    </StyledContainer>
  )
}
