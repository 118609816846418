import React from 'react'
import { Controller } from 'react-hook-form'

import { BaseCounterInput, HandleOnClickCounterButtonsT } from '../atoms/BaseCounterInput'
import type { BaseInputPropsT } from '../atoms/BaseInput'

interface IFieldInputProps extends BaseInputPropsT, HandleOnClickCounterButtonsT {
  name: string
  control: any | undefined
  rules?: Object
}

export const FieldBaseCounterInput: React.FC<IFieldInputProps> = ({
  name,
  control,
  rules = {},
  handleOnClickCounterButtons,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={0}
      render={({ field: { value } }) => (
        <BaseCounterInput value={value} handleOnClickCounterButtons={handleOnClickCounterButtons} />
      )}
      rules={rules}
      {...rest}
    />
  )
}
