import { MenuItem } from '@mui/material'
import Select from '@mui/material/Select'
import * as React from 'react'

import { SelectListT } from '../../types/types'

export type MultipleSelectNativePropsT = {
  list: SelectListT[]
  onChange: any
  value: any
  multiple?: boolean
}

export const BaseSelect: React.FC<MultipleSelectNativePropsT> = ({ list, onChange, value }) => {
  const handleChangeMultiple = (event: React.ChangeEvent<HTMLSelectElement>) => {
    return event.target.value
  }

  return (
    <Select
      value={value}
      // @ts-ignore Typings are not considering `native`
      onChange={(e) => onChange(handleChangeMultiple(e))}
      className='w-full border-none bg-secondary-light'>
      {list.length > 0 &&
        list.map((item) => (
          <MenuItem key={item.id} value={item.value}>
            {item.name}
          </MenuItem>
        ))}
    </Select>
  )
}
