import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import * as React from 'react'

import styles from '../../constants/styles'

export type BasicSelectDataT = {
  id: number | string
  name: string | number
}

type PropsT = {
  value: string | number
  onChange: () => void
  label?: string
  data: BasicSelectDataT[]
}

export const BasicSelect: React.FC<PropsT> = ({ value, onChange, label, data }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id='demo-simple-select-label'>{label}</InputLabel>
      <Select
        sx={{ backgroundColor: styles.COLORS.grey[50] }}
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        value={value}
        label={label}
        onChange={onChange}>
        {data.length > 0 &&
          data.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  )
}
