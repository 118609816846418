import { Skeleton } from '@mui/material'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { getDataConfigParametersCode } from '../../api/data/configParameters'
import { useDispatch, useSelector } from '../../store/hooks'
import { reservationSlice } from '../../store/slices/reservation'
import { ReservationTimesStateT } from '../../types/types'
import { formatDate } from '../../utils/format'
import { InfoBox } from '../molecules/InfoBox'

dayjs.extend(duration)

type PropsT = {
  icon: React.ReactChild
  titleOnOpen: string
  titleOnClose: string
  descOnOpen: string
  descOnClose: string
}

const dayNow = dayjs().day()
const hoursNow = dayjs().hour()

export const InfoBoxOpeningTime: React.ComponentType<PropsT> = ({
  icon,
  titleOnOpen,
  titleOnClose,
  descOnOpen,
  descOnClose,
}) => {
  const dispatch = useDispatch()

  const [isLoadingDataConfig, setIsLoadingDataConfig] = useState<boolean>(true)
  const [isOpenState, setIsOpenState] = useState<boolean>(false)
  const [reservationTimes, setReservationTimes] = useState<ReservationTimesStateT>({
    openDay: 0,
    openHour: 0,
    closeDay: 0,
    closeHour: 0,
  })
  const isReservationOpen = useSelector((state) => state.reservation.isOpen)

  const openDate = useMemo(() => {
    return dayNow > reservationTimes.openDay
      ? dayjs(dayjs().add(7, 'day').day(reservationTimes.openDay).hour(reservationTimes.openHour))
      : dayjs(dayjs().day(reservationTimes.openDay).hour(reservationTimes.openHour))
  }, [reservationTimes])

  const closeDate = useMemo(() => {
    return dayjs(dayjs().day(reservationTimes.closeDay).hour(reservationTimes.closeHour))
  }, [reservationTimes])

  const diffTime = useMemo(() => {
    return isReservationOpen
      ? dayjs.duration(closeDate.diff(dayjs()))
      : dayjs.duration(openDate.diff(dayjs()))
  }, [isReservationOpen, closeDate, openDate])

  useEffect(() => {
    setIsReservationOpen(isOpenState)
  }, [isReservationOpen, isOpenState])

  const setIsReservationOpen = (isOpen: boolean) => {
    if (isOpen !== isReservationOpen) {
      dispatch(
        reservationSlice.actions.update({
          isOpen,
        })
      )
    }
  }

  useEffect(() => {
    const fetchDataConfigParameters = async () => {
      Promise.all([
        getDataConfigParametersCode('FIXNI_POZADAVKY_OTEVRENI_DEN_V_TYDNU'),
        getDataConfigParametersCode('FIXNI_POZADAVKY_OTEVRENI_HODINA'),
        getDataConfigParametersCode('FIXNI_POZADAVKY_UZAVRENI_DEN_V_TYDNU'),
        getDataConfigParametersCode('FIXNI_POZADAVKY_UZAVRENI_HODINA'),
      ]).then((values) => {
        if (values[0] && values[1] && values[2] && values[3]) {
          setReservationTimes({
            openDay: values[0].hodnota,
            openHour: values[1].hodnota,
            closeDay: values[2].hodnota,
            closeHour: values[3].hodnota,
          })
        }
      })
      setIsLoadingDataConfig(false)
    }
    fetchDataConfigParameters().catch(console.error)
  }, [])

  const checkOpenTime = useCallback(() => {
    if (dayNow >= reservationTimes.openDay && dayNow <= reservationTimes.closeDay) {
      if (dayNow == reservationTimes.openDay && hoursNow >= reservationTimes.openHour) {
        if (hoursNow >= reservationTimes.openHour) setIsOpenState(true)
      }
      if (dayNow == reservationTimes.closeDay && hoursNow < reservationTimes.closeHour)
        setIsOpenState(true)
      if (dayNow > reservationTimes.openDay && dayNow < reservationTimes.closeDay)
        setIsOpenState(true)
    } else {
      setIsOpenState(false)
    }
  }, [reservationTimes])

  useEffect(() => {
    let checkDateInterval: NodeJS.Timeout
    if (!isLoadingDataConfig) {
      checkOpenTime()
      checkDateInterval = setInterval(() => {
        checkOpenTime()
      }, 5000)
    }
    return () => clearInterval(checkDateInterval)
  }, [isLoadingDataConfig, reservationTimes])

  return (
    <>
      {!isLoadingDataConfig ? (
        <InfoBox icon={icon}>
          {isReservationOpen ? (
            <>
              <strong>{titleOnOpen}</strong>
              <br />
              {descOnOpen} {formatDate(closeDate, 'dddd D. MMMM YYYY')} (za {diffTime.days()} dny,{' '}
              {diffTime.hours()} hodin).
            </>
          ) : (
            <>
              <strong>{titleOnClose}</strong>
              <br />
              {descOnClose} {formatDate(openDate, 'dddd D. MMMM YYYY')} (za {diffTime.days()} dny,{' '}
              {diffTime.hours()} hodin).
            </>
          )}
        </InfoBox>
      ) : (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      )}
    </>
  )
}
