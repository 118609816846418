import { Button } from '@mui/material'
import * as React from 'react'
import tw from 'tailwind-styled-components'

import { delAdminArticle } from '../../../api/admin/news'
import { MODALS } from '../../../constants/modals'
import { useSnackbar } from '../../../hooks/useSnackbar'
import { useDispatch, useSelector } from '../../../store/hooks'
import { modalSlice } from '../../../store/slices/modal'
import { Modal } from '../../molecules/Modal'

type PropsT = {}

const StyledSButtons = tw.div`
mt-6
flex
justify-center
gap-4
`

export const RemoveNewsArticleDialog: React.FC<PropsT> = () => {
  const [displaySnackBarWithMessage] = useSnackbar()

  const isOpened = useSelector((state) => state.modal.removeNewsArticle)
  const articleId = useSelector((state) => state.modal.removeNewsArticleId)

  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(
      modalSlice.actions.update({
        [MODALS.REMOVE_NEWS_ARTICLE]: false,
      })
    )
  }

  const handleDeleteArticle = () => {
    delAdminArticle(articleId).then((response) => {
      if (response && response.error) {
        displaySnackBarWithMessage({
          message: `Nepodařilo se smazat novinku`,
          isOpen: true,
          severity: 'error',
        })
        handleClose()
      } else {
        displaySnackBarWithMessage({
          message: `Podařilo se smazat novinku`,
          isOpen: true,
          severity: 'success',
        })
        handleClose()
      }
    })
  }

  return (
    <Modal
      open={isOpened}
      title='Opravdu chcete článek trvale smazat?'
      modalId={MODALS.REMOVE_NEWS_ARTICLE}
      centered>
      <StyledSButtons>
        <Button
          size='large'
          color='error'
          variant='contained'
          onClick={() => handleDeleteArticle()}>
          SMAZAT
        </Button>
        <Button size='large' variant='outlined' onClick={() => handleClose()}>
          PONECHAT
        </Button>
      </StyledSButtons>
    </Modal>
  )
}
