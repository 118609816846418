import { useResponseStatus } from '../../hooks/useResponseStatus'
import { axiosInstanceBearer } from './axiosInstance'

export async function getApiData<Response>(url: string) {
  return await axiosInstanceBearer()
    .get<Response>(url)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      useResponseStatus(error.response.status)
      console.log(error)
      throw new Error(error)
    })
}

export async function getApiDataFile<Response>(url: string) {
  return await axiosInstanceBearer()
    .get<Response>(url, { responseType: 'blob' })
    .then((response) => {
      return response
    })
    .catch((error) => {
      useResponseStatus(error.response.status)
      console.log(error)
      throw new Error(error)
    })
}
