import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { loginSlice } from './slices/login'
import { modalSlice } from './slices/modal'
import { reservationSlice } from './slices/reservation'
import { reservationsSlice } from './slices/reservations'
import { snackBarSlice } from './slices/snackBar'
import { userSlice } from './slices/user'

const persistConfig = {
  key: 'user',
  storage,
  blacklist: ['modal', 'snackbar'],
}

const reducers = combineReducers({
  modal: modalSlice.reducer,
  user: userSlice.reducer,
  reservation: reservationSlice.reducer,
  reservations: reservationsSlice.reducer,
  snackBar: snackBarSlice.reducer,
  login: loginSlice.reducer,
})

const persistedReducer = persistReducer(persistConfig, reducers)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
