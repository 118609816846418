import { createSlice } from '@reduxjs/toolkit'

import { GetDataProjectsResponseT } from '../../api/data/project'
import { Contract, Customer } from '../../types/types'
import { objectReducerGenerator } from '../objectReducerGenerator'

type CreateOrChangeModalState = Customer

type CustomerContractCreatedOrChangeInitialState = Pick<
  Contract,
  'cislo' | 'popis' | 'fixMo' | 'fixVo' | 'platnostOd' | 'platnostDo' | 'icoZakaznik' | 'id'
>
export interface ModalState {
  removeNewsArticle?: boolean
  removeNewsArticleId: number
  addNewsArticle?: boolean
  editNewsArticle?: boolean
  editNewsArticleId: number
  customerContract?: boolean
  customerContractCreatedOrChangeInitialState: CustomerContractCreatedOrChangeInitialState
  customerProject?: boolean
  customerProjectCreatedOrChangeInitialState: Pick<
    GetDataProjectsResponseT,
    'nazevCz' | 'nazevEn' | 'popisCz' | 'popisEn' | 'cisloSmlouva' | 'id'
  >
  customerCreateOrChange?: boolean
  customerCreateOrChangeIsSend?: boolean
  customerCreatedOrChangeInitialState: CreateOrChangeModalState
  requestDetail?: boolean
  requestDetailId: number
  rejectRequestModal?: boolean
  rejectRequestId: number
  rejectRequestUsername: string
  rejectRequestIsSending: boolean
  acceptRequestModal?: boolean
  acceptRequestId: number
  acceptRequestIsSending: boolean
}

export const modalInitialState: ModalState = {
  removeNewsArticle: false,
  removeNewsArticleId: 0,
  addNewsArticle: false,
  editNewsArticle: false,
  editNewsArticleId: 0,
  customerContract: false,
  customerContractCreatedOrChangeInitialState: {
    id: 0,
    cislo: '',
    popis: '',
    fixMo: 0,
    fixVo: 0,
    platnostOd: null,
    platnostDo: null,
    icoZakaznik: '',
  },
  customerProject: false,
  customerProjectCreatedOrChangeInitialState: {
    id: 0,
    nazevCz: '',
    popisCz: '',
    nazevEn: '',
    popisEn: '',
    cisloSmlouva: '',
  },
  customerCreateOrChange: false,
  customerCreateOrChangeIsSend: false,
  customerCreatedOrChangeInitialState: {
    adresa: '',
    ico: '',
    id: 0,
    jazyk: { name: 'CZ', popis: 'Čeština' },
    nazev: '',
    platnostOd: null,
    platnostDo: null,
  },
  requestDetail: false,
  requestDetailId: 0,
  rejectRequestModal: false,
  rejectRequestId: 0,
  rejectRequestUsername: '',
  rejectRequestIsSending: false,
  acceptRequestModal: false,
  acceptRequestId: 0,
  acceptRequestIsSending: false,
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState: modalInitialState,
  reducers: {
    ...objectReducerGenerator<ModalState>(),
    reset(state) {
      Object.assign(state, modalInitialState)
    },
  },
})
