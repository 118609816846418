// export const currentDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)

import { endOfWeek, startOfWeek } from 'date-fns'
import dayjs from 'dayjs'
import cs from 'dayjs/locale/cs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

import { detect } from 'detect-browser'

import { FORMAT } from '../constants/format'
const browser = detect()

const formatDateForSafari = (date: string) => {
  return browser?.name === 'safari' ? date.replaceAll('.', '/') : date
}

export const getFirstDayWeek = (date: Date) => {
  return startOfWeek(date, { weekStartsOn: 1 })
}

export const getLastDayWeek = (date: Date) => {
  return endOfWeek(date, { weekStartsOn: 1 })
}

// dayNumberOfWeek: 1 - monday, 7 - sonday
export const getDayWeek = (date: Date, dayNumberOfWeek: number) => {
  const monday = new Date(getFirstDayWeek(date))
  return new Date(monday.setDate(monday.getDate() + dayNumberOfWeek - 1))
}

type DateTimeFormatOptionsT = Partial<
  Pick<Intl.DateTimeFormatOptions, 'weekday' | 'year' | 'month' | 'day'>
>

export const formatDateCZ = (
  date: Date,
  { weekday = 'long', year = 'numeric', month = 'short', day = 'numeric' }: DateTimeFormatOptionsT
) => {
  return date.toLocaleDateString('cs-CZ', { weekday: weekday, year: year, month: month, day: day })
}

export const formatDateISO = (date: Date) => {
  return dayjs(date).locale(cs).format(FORMAT.YYYYMMDD)
}

export const parseTimeFromDate = (date: string) => {
  const parseDateAndTime = date.split(' ')
  const parseHoursAndMinutes = parseDateAndTime[1].split(':')
  return `${parseHoursAndMinutes[0]}:${parseHoursAndMinutes[1]}`
}

export const formatDate = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
  format?: string
) => {
  if (typeof date === 'string') date = formatDateForSafari(date)
  return dayjs(date).locale(cs).format(format)
}

export const formatDateWithIncomingFormat = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
  incomingFormat: string,
  format: string,
  locale?: string
) => {
  if (typeof date === 'string') date = formatDateForSafari(date)
  return dayjs(date, incomingFormat, locale || 'cs').format(format)
}
export const parseCustomFormatToDayJs = (
  date: string | number | Date | dayjs.Dayjs | null | undefined,
  incomingFormat: string
) => dayjs(date, incomingFormat)
