import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'

import styles from '../../constants/styles'
import rem from '../../utils/styles/rem'
import { BaseButton } from './BaseButton'
import Flex from './Flex'

interface SelectWithArrowsProps<T> {
  data: Record<string, any>[]
  value: T
  label?: string
  onChange: (value: T) => void
}

export const SelectWithArrowButton = <T,>({
  label,
  value,
  data,
  onChange,
}: SelectWithArrowsProps<T>) => {
  const handleChange = (event: SelectChangeEvent<T>) => {
    onChange(event.target.value as T)
  }

  const handleButtonClick = (index: number) => {
    onChange(data[index].id)
  }

  const selectedIndex = data.findIndex((item) => item.id === value)

  return (
    <>
      <FormControl fullWidth>
        {label && <InputLabel id='demo-simple-select-label'>{label}</InputLabel>}
        <Flex className='border border-gray-300 rounded'>
          <Select
            size='small'
            sx={{ fieldset: { border: 'none' }, width: '100%' }}
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={value}
            label={label}
            onChange={handleChange}>
            {data.length > 0 &&
              data.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
          <BaseButton
            sx={{
              minWidth: rem(45),
              borderLeft: '1px solid',
              borderColor: styles.COLORS.grey[300],
              borderRadius: 0,
              span: { margin: 0 },
            }}
            size='small'
            startIcon={<ArrowBackIcon />}
            disabled={selectedIndex === 0}
            onClick={() => handleButtonClick(selectedIndex - 1)}
          />
          <BaseButton
            sx={{ minWidth: rem(45), span: { margin: 0 } }}
            size='small'
            endIcon={<ArrowForwardIcon />}
            disabled={selectedIndex >= data.length - 1}
            onClick={() => handleButtonClick(selectedIndex + 1)}
          />
        </Flex>
      </FormControl>
    </>
  )
}
