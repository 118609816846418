import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'

import {
  getDataProductRequestState,
  getProductRequestNoDuplicates,
} from '../../api/data/productRequest'
import { getRequests } from '../../api/data/requests'
import { getDataSource, getDataSourceTypeAll } from '../../api/data/source'
import {
  DataProductRequestHoursRequestT,
  DataSourceT,
  DataSourceTypeT,
  Request,
} from '../../types/types'
import { formatDate } from '../../utils/format'
import { SatisfiedService } from '../molecules/SatisfiedService'
import { AgreedRequests } from '../organisms/AgreedRequests'
import { FastReservationAdmin } from '../organisms/FastReservationAdmin'
import { InfoBoxOpeningTime } from '../organisms/InfoBoxOpeningTime'
import { NearestReservationsAdmin } from '../organisms/NearestReservationsAdmin'
import { NewsBackOfficeOverview } from '../organisms/NewsBackOfficeOverview'
import { BaseContainer, BaseContainerItem } from '../templates/BaseContainer'

export const OverviewPageAdmin: React.ComponentType = () => {
  const [sources, setSource] = useState<DataSourceT[]>([])
  const [sourcesType, setSourcesType] = useState<DataSourceTypeT[]>([])
  const [productRequests, setProductRequests] = useState<DataProductRequestHoursRequestT[]>([])
  const [productRequestsAccepted, setProductRequestsAccepted] = useState<Request[]>([])

  useEffect(() => {
    Promise.all([
      getDataSource(),
      getDataProductRequestState(
        formatDate(dayjs().add(7, 'day').day(1), 'DD.MM.YYYY'),
        formatDate(dayjs().add(7, 'day').day(7), 'DD.MM.YYYY'),
        'POTVRZENY'
      ),
      getDataSourceTypeAll(),
      getRequests(),
    ]).then((values) => {
      values[0] && setSource(values[0])
      if (values[1]) {
        setProductRequests(getProductRequestNoDuplicates(values[1]))
      }
      if (values[2]) {
        setSourcesType(values[2])
      }
      if (values[3]) {
        setProductRequestsAccepted(values[3])
      }
    })
  }, [])

  return (
    <BaseContainer>
      <InfoBoxOpeningTime
        icon={<EventNoteOutlinedIcon fontSize='inherit' />}
        titleOnOpen='Nyní můžete odbavovat požadavky na FIXNÍ nároky'
        titleOnClose='Nyní můžete odbavovat všechny požadavky'
        descOnOpen='Tato fáze se uzavře dne'
        descOnClose='Tato fáze se uzavře dne'
      />
      <BaseContainerItem>
        <SatisfiedService
          title='Odbavení žádostí'
          requests={productRequestsAccepted}
          sources={sources}
        />
      </BaseContainerItem>
      <BaseContainerItem>
        <FastReservationAdmin title='Rychlé vytvoření rezervace' />
      </BaseContainerItem>
      <BaseContainerItem>
        <AgreedRequests
          title='Právě odsouhlasené žádosti'
          requests={productRequests}
          sources={sources}
        />
      </BaseContainerItem>
      <BaseContainerItem>
        <NearestReservationsAdmin
          title='Nejbližší rezervace'
          sourcesType={sourcesType}
          sources={sources}
        />
      </BaseContainerItem>
      <BaseContainerItem>
        <NewsBackOfficeOverview title='Poslední provozní informace' />
      </BaseContainerItem>
    </BaseContainer>
  )
}
