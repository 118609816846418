import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { DataProjectInfoT, getDataProjectInfo } from '../../api/data/project'
import { ROUTES } from '../../constants/routes'
import { useSelector } from '../../store/hooks'
import { H2 } from '../atoms/Heading'
import { SelectList } from '../atoms/SelectList'

type PropsT = {}

const StyledContainer = tw.div`
  w-full
  px-5
  py-7
  bg-secondary-dark
  sm:p-20
`

const StyledButton = tw.div`
  flex
  justify-end
  mt-4
`

interface IFastReservationFormInput {
  projectName: string
}

export const FastReservation: React.FC<PropsT> = () => {
  const [projectsNameList, setProjectsNameList] = useState<Array<string>>([])
  const [projects, setProjects] = useState<DataProjectInfoT[]>([])

  const userIcoZakaznik = useSelector((state) => state.user.icoZakaznik)

  const { control, handleSubmit, reset } = useForm<IFastReservationFormInput>()
  const navigate = useNavigate()

  useEffect(() => {
    getDataProjectInfo().then((projects) => {
      if (projects && userIcoZakaznik) {
        const projectsForUser = projects.filter(
          (project) => project.zakaznik.ico === userIcoZakaznik
        )
        const projectsNamesForUser = projectsForUser.map((project) => project.projekt.nazevCz)
        setProjectsNameList(projectsNamesForUser)
        setProjects(projectsForUser)
      }
    })
  }, [userIcoZakaznik])

  useEffect(() => {
    projectsNameList &&
      projectsNameList.length > 0 &&
      reset({ projectName: projectsNameList[0].toString() })
  }, [projectsNameList])

  const onSubmit: SubmitHandler<IFastReservationFormInput> = (data) => {
    const selectedProject = projects.find(
      (project) => project.projekt.nazevCz === data.projectName.toString()
    )

    navigate(`${ROUTES.CREATE_RESERVATION}/${selectedProject && selectedProject.projekt.id}`)
  }

  return (
    <StyledContainer>
      <H2 centered>Rychlá rezervace</H2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name='projectName'
          control={control}
          render={({ field: { onChange, value } }) => (
            <SelectList list={projectsNameList} onChange={onChange} value={value} />
          )}
          rules={{
            required: 'Musíte vybrat projekt.',
          }}
        />
        <StyledButton>
          <Button variant='contained' color='primary' size='large' type='submit'>
            Pokračovat
          </Button>
        </StyledButton>
      </form>
    </StyledContainer>
  )
}
