import { Endpoints } from '../../constants/endpoints'
import { Contract } from '../../types/types'
import { delApiData } from '../_GLOBAL/delApiData'
import { getApiData } from '../_GLOBAL/getApiData'
import { postApiData } from '../_GLOBAL/postApiData'
import { putApiData } from '../_GLOBAL/putApiData'

type createContractData = Pick<
  Contract,
  'cislo' | 'fixMo' | 'fixVo' | 'icoZakaznik' | 'platnostDo' | 'platnostOd' | 'popis'
>

export const getAdminContractId = (id: number) => getApiData(Endpoints.adminContractId(id))

export const delAdminContractId = (id: number) => delApiData(Endpoints.adminContractId(id))

export const getAdminContract = () => getApiData(Endpoints.adminContract())

export const createContract = (data: createContractData) =>
  postApiData(Endpoints.adminContract(), data)
export const updateContract = (data: createContractData & { id: number }) =>
  putApiData(Endpoints.adminContract(), data)
