import CloseIcon from '@mui/icons-material/Close'
import { Button } from '@mui/material'
import { useState } from 'react'

import {
  confirmRequestListForUserT,
  rejectRequestListForUser,
} from '../../../api/action/specifyRequest'
import { MODALS } from '../../../constants/modals'
import { useSnackbar } from '../../../hooks/useSnackbar'
import { useDispatch, useSelector } from '../../../store/hooks'
import { modalSlice } from '../../../store/slices/modal'
import { BaseInput } from '../../atoms/BaseInput'
import Flex from '../../atoms/Flex'
import { Modal } from '../../molecules/Modal'

export const RejectRequestDialog: React.FC = () => {
  const [message, setMessage] = useState('')
  // const [startNewReservationAfterClosing, setStartNewReservationAfterClosing] = useState(false)
  const isOpen = useSelector((state) => state.modal.rejectRequestModal)
  const requestId = useSelector((state) => state.modal.rejectRequestId)
  const requestUsername = useSelector((state) => state.modal.rejectRequestUsername)
  const [displaySnackBarWithMessage] = useSnackbar()
  const dispatch = useDispatch()

  const handleClose = () => {
    setMessage('')
    dispatch(
      modalSlice.actions.update({
        [MODALS.REJECT_REQUEST_MODAL]: false,
        [MODALS.REJECT_REQUEST_ID]: 0,
      })
    )
  }

  const rejectRequest = async (idArray: confirmRequestListForUserT[]) => {
    dispatch(
      modalSlice.actions.update({
        [MODALS.REJECT_REQUEST_IS_SENDING]: true,
      })
    )
    const response = await rejectRequestListForUser(idArray)
    const multipleOrOneId = idArray.length === 1 ? 'požadavek' : 'všechny požadavky'
    if (response.some((res) => 'error' in res)) {
      displaySnackBarWithMessage({
        message: `Nepodařilo se zamítnout ${multipleOrOneId}`,
        isOpen: true,
        severity: 'error',
      })
      handleClose()
    } else {
      displaySnackBarWithMessage({
        message: `Podařilo se zamítnout ${multipleOrOneId}`,
        isOpen: true,
        severity: 'success',
      })
      dispatch(
        modalSlice.actions.update({
          [MODALS.REJECT_REQUEST_IS_SENDING]: false,
        })
      )
      handleClose()
    }
  }

  return (
    <Modal open={isOpen} modalId={MODALS.REJECT_REQUEST_MODAL} onClose={handleClose}>
      {' '}
      <div className='mb-2'>Poznámka (nepovinná)</div>
      <div>
        <BaseInput
          fullWidth
          multiline
          rows={6}
          label=''
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
      </div>
      {/* <div>
        <BaseSwitch
          value={startNewReservationAfterClosing}
          onClick={() => setStartNewReservationAfterClosing(!startNewReservationAfterClosing)}
        />{' '}
        Navázat předvyplněnou novou rezervací
      </div> */}
      <Flex justifyContent='end' className='mt-4'>
        <Button
          variant='contained'
          size='large'
          onClick={() => {
            if (requestId) {
              rejectRequest([
                { id: requestId, usernameZadavatel: requestUsername, poznamka: message },
              ])
            }
          }}
          startIcon={<CloseIcon />}>
          Zamítnout žádost
        </Button>
      </Flex>
    </Modal>
  )
}
