import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import * as React from 'react'

const ITEM_HEIGHT = 48

type MoreOptionT = {
  id: number
  name: string
  action: () => void
}

type MoreOptionsT = {
  options: MoreOptionT[]
}

export const MoreOptions: React.FC<MoreOptionsT> = ({ options }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        sx={{ padding: 0, marginLeft: 1 }}
        aria-label='more'
        id='long-button'
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleClick}>
        <MoreHorizIcon className='cursor-pointer' />
      </IconButton>
      <Menu
        id='long-menu'
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}>
        {options.map((option: any) => (
          <MenuItem
            key={option.id}
            onClick={() => {
              handleClose()
              option.action()
            }}>
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
