import { Endpoints } from '../../constants/endpoints'
import { getApiData } from '../_GLOBAL/getApiData'

export type GetDataConfigParametersResponseT = {
  kod: string
  vyznam: string
  hodnota: number
  usernameUprava: string
  datumUprava: Date
}

export const getDataConfigParameters = () =>
  getApiData<GetDataConfigParametersResponseT[]>(Endpoints.dataConfigParameters())

export const getDataConfigParametersCode = (kod: string) =>
  getApiData<GetDataConfigParametersResponseT>(Endpoints.dataConfigParametersCode(kod))
