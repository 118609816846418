import React from 'react'
import tw from 'tailwind-styled-components'

type FlexPropsT = {
  className?: string
  children: any
  flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse' | null
  justifyContent?:
    | 'start'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'end'
    | null
  alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch' | null
  flexWrap?: 'wrap' | 'no-wrap' | null
}

type StyledFlexPropsT = {
  $flexDirection?: 'row' | 'row-reverse' | 'column' | 'column-reverse' | null
  $justifyContent?:
    | 'start'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'end'
    | null
  $alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch' | null
  $flexWrap?: 'wrap' | 'no-wrap' | null
}

const StyledFlex = tw.div<StyledFlexPropsT>`
  flex
  ${({ $flexDirection }) => {
    switch ($flexDirection) {
      case 'row':
        return 'flex-row'
      case 'row-reverse':
        return 'flex-row-reverse'
      case 'column':
        return 'flex-col'
      case 'column-reverse':
        return 'flex-col-reverse'
      default:
        return null
    }
  }}

  ${({ $justifyContent }) => {
    switch ($justifyContent) {
      case 'start':
        return 'justify-start'
      case 'center':
        return 'justify-center'
      case 'space-between':
        return 'justify-between'
      case 'space-around':
        return 'justify-around'
      case 'space-evenly':
        return 'justify-evenly'
      case 'end':
        return 'justify-end'
      default:
        return null
    }
  }}

  ${({ $alignItems }) => {
    switch ($alignItems) {
      case 'start':
        return 'items-start'
      case 'end':
        return 'items-end'
      case 'center':
        return 'items-center'
      case 'baseline':
        return 'items-baseline'
      case 'stretch':
        return 'items-stretch'
      default:
        return null
    }
  }}

  ${({ $flexWrap }) => {
    switch ($flexWrap) {
      case 'wrap':
        return 'flex-wrap'
      case 'no-wrap':
        return 'flex-no-wrap'
      default:
        return null
    }
  }}
`

const Flex: React.FC<FlexPropsT> = ({
  flexDirection = null,
  justifyContent = null,
  alignItems = null,
  flexWrap = null,
  children,
  className,
}) => {
  return (
    <StyledFlex
      className={className}
      $flexDirection={flexDirection}
      $justifyContent={justifyContent}
      $alignItems={alignItems}
      $flexWrap={flexWrap}>
      {children}
    </StyledFlex>
  )
}

export default Flex
