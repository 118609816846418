import * as React from 'react'
import tw from 'tailwind-styled-components'

import Flex from './Flex'

type PropsT = {
  time: string
  usedSlots: number
  maxSlots: number
}

const StyledTimeSlot = tw.div`
  flex
  items-center
  justify-center
  px-4
  py-2
  border-1
`

export const TimeSlot: React.FC<PropsT> = ({ time, usedSlots, maxSlots }) => {
  return (
    <StyledTimeSlot>
      <Flex justifyContent='center' flexDirection='column'>
        <strong className='text-center'>{time}</strong>
        <small className='text-center text-xs'>{`${usedSlots}/${maxSlots}`}</small>
      </Flex>
    </StyledTimeSlot>
  )
}
