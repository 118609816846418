import { Slide, SlideProps, Snackbar } from '@mui/material'
import MuiAlert, { AlertProps } from '@mui/material/Alert'
import * as React from 'react'

import { useDispatch, useSelector } from '../../store/hooks'
import { snackBarSlice } from '../../store/slices/snackBar'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

export const BaseSnackBar: React.FC = () => {
  const { isOpen, message, severity } = useSelector((state) => state.snackBar)
  const dispatch = useDispatch()

  function TransitionLeft(props: SlideProps) {
    return <Slide {...props} direction='right' />
  }

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    dispatch(snackBarSlice.actions.reset())
  }

  return (
    <Snackbar
      color='error'
      open={isOpen}
      autoHideDuration={3000}
      TransitionComponent={TransitionLeft}
      onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  )
}
