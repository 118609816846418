import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import * as React from 'react'
import styled from 'styled-components'

import { isEmptyString } from '../../utils/validate'

export interface TabObject {
  name: string
  label: string
  icon?: React.ReactElement
}

type PropsT = {
  value: string
  tabs: TabObject[]
  onChange: (currentSourceName: any) => void
  disabled?: boolean
  sx?: any
}

const StyledTab = styled(Tab)`
  .MuiSvgIcon-root {
    margin-right: 8px;
  }
`

export const TabItem: React.FC<{ currentValue: string; tabItemValue: string }> = ({
  currentValue,
  tabItemValue,
  children,
}) => {
  return <>{currentValue === tabItemValue && <div>{children}</div>}</>
}

export const BaseTabs: React.FC<PropsT> = ({ tabs, onChange, value, sx, disabled = false }) => {
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    return newValue
  }

  if (!isEmptyString(value)) {
    return (
      <Tabs
        sx={sx}
        centered
        value={value}
        onChange={(e, value) => onChange(handleChange(e, value))}
        textColor='primary'
        indicatorColor='primary'
        aria-label='primary tabs example'>
        {tabs.map((tab) => (
          <StyledTab
            icon={tab.icon ? tab.icon : undefined}
            iconPosition='start'
            className='font-medium'
            disabled={disabled}
            key={tab.name}
            value={tab.name}
            label={tab.label}
          />
        ))}
      </Tabs>
    )
  }
  return <></>
}
