import React from 'react'
import { Outlet } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { BaseSnackBar } from '../atoms/BaseSnackBar'
import { Footer } from '../organisms/Footer/Footer'
import { Header } from '../organisms/Header/Header'
import { AcceptRequestDialog } from '../organisms/Modals/AcceptRequestDialog'
import { AddNewsArticleDialog } from '../organisms/Modals/AddNewsArticleDialog'
import { CustomerCreateOrChangeDialog } from '../organisms/Modals/CustomerCreateOrChangeDialog'
import { EditNewsArticleDialog } from '../organisms/Modals/EditNewsArticleDialog'
import { RejectRequestDialog } from '../organisms/Modals/RejectRequestDialog'
import { RemoveNewsArticleDialog } from '../organisms/Modals/RemoveNewsArticleDialog'

type LayoutPropsT = {
  auth: boolean | undefined
}

const StyledLayout = tw.div`
flex
flex-col
min-h-screen
justify-between
`

export const Layout: React.FC<LayoutPropsT> = ({ auth }) => (
  <>
    <StyledLayout>
      <Header auth={auth} />
      <Outlet />
      <Footer auth={auth} />
    </StyledLayout>
    {auth && (
      <>
        <RemoveNewsArticleDialog />
        <AddNewsArticleDialog />
        <EditNewsArticleDialog />
        <BaseSnackBar />
        <AcceptRequestDialog />
        <RejectRequestDialog />
        <CustomerCreateOrChangeDialog />
      </>
    )}
  </>
)
