import { Endpoints } from '../../constants/endpoints'
import { DataNotificationsT } from '../../types/types'
import { delApiData } from '../_GLOBAL/delApiData'
import { getApiData } from '../_GLOBAL/getApiData'

export type DataNewsT = {
  id: number
  nazev: string
  popis: string
  obsah: string
  usernameVytvoreni: string
  datumVytvoreni: string
  usernameUprava: string
  datumUprava: string
  files: File[]
}

export const getNews = (limit: number, offset: number) =>
  getApiData<DataNewsT[]>(Endpoints.dataNews(limit, offset))

export const delAdminNotification = (notificationId: DataNotificationsT['notifikace']['id']) =>
  delApiData(Endpoints.adminNotification(notificationId))

export const getNewsArticleById = (articleId: number) =>
  getApiData<DataNewsT>(Endpoints.dataNewsArticle(articleId))

export const getNewsCount = () => getApiData<number>(Endpoints.dataNewsCount())
