import React from 'react'

import { NewsList } from '../organisms/NewsList'
import { BaseContainer } from '../templates/BaseContainer'

export const NewsPage: React.FC = () => {
  return (
    <BaseContainer>
      <NewsList title='Provozní informace' />
    </BaseContainer>
  )
}
