import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Row } from 'react-table'

import { DataProjectInfoT } from '../../api/data/project'
import { FORMAT } from '../../constants/format'
import { REQUEST } from '../../constants/request'
import { DataProductRequestHoursRequestT } from '../../types/types'
import { formatDateWithIncomingFormat } from '../../utils/format'
import Flex from '../atoms/Flex'
import { H2 } from '../atoms/Heading'
import StyledPaper from '../atoms/StyledPaper'
import TableWrapper from '../atoms/TableWrapper'
import { BaseTable } from '../molecules/BaseTable'
import { ButtonGroupBadges } from '../molecules/ButtonGroupBadges'

type PropsT = {
  title: string
  requests: DataProductRequestHoursRequestT[]
  projects: DataProjectInfoT[]
}

type RequestsFormattedArrayT = {
  time: string
  project: string
  source: string
  product: string
}

export const NearestReservations: React.FC<PropsT> = ({ title, requests, projects }) => {
  const [currentProjectId, setCurrentProjectId] = useState<number>(0)
  const [requestsToRender, setRequestsToRender] = useState<RequestsFormattedArrayT[]>([])

  const requestsToShowFirstTime = 5

  const requestsTableColumns = React.useMemo(
    () => [
      {
        Header: 'Čas',
        accessor: 'time',
        disableFilters: true,
        sortType: (a: Row<any>, b: Row<any>) => {
          const rowA = new Date(a.original.timeRaw).getTime()
          const rowB = new Date(b.original.timeRaw).getTime()
          if (rowA > rowB) return -1
          if (rowB > rowA) return 1
          return 0
        },
      },
      {
        Header: 'Projekt',
        accessor: 'project',
        disableFilters: true,
      },
      {
        Header: 'Zdroj',
        accessor: 'source',
        disableFilters: true,
      },
      {
        Header: 'Produkt',
        accessor: 'product',
        disableFilters: true,
      },
    ],
    []
  )

  const acceptedRequests = React.useMemo(
    () => requests.filter((request) => request.pozadavek.stav.kod === REQUEST.STATE.CONFIRMED),
    [requests]
  )

  const requestsFormattedArray = React.useMemo(() => {
    const filteredRequests =
      currentProjectId > 0
        ? acceptedRequests.filter((request) => request.projekt.id === currentProjectId)
        : acceptedRequests
    return filteredRequests.map((request) => ({
      time: `${formatDateWithIncomingFormat(
        request.pozadavek.zadani.dny[0].den,
        FORMAT.API_DDMMYYYY,
        'DD. MMM, '
      )} ${request.pozadavek.zadani.dny[0].hodinaOd}:00-${
        request.pozadavek.zadani.dny[0].hodinaDo
      }:00`,
      timeRaw: formatDateWithIncomingFormat(
        `${request.pozadavek.zadani.dny[0].den} ${request.pozadavek.zadani.dny[0].hodinaOd}:00`,
        FORMAT.API_DDMMYYYY_HHMM,
        'YYYY-MM-DD h:mm'
      ),
      project: request.projekt.nazevCz,
      source: request.zdroj.nazevCz,
      product: request.produkt.nazevCz,
    }))
  }, [acceptedRequests, currentProjectId])

  const sliceRequests = (start: number, end: number) => {
    const slicedRequests = requestsFormattedArray.slice(start, end)
    setRequestsToRender(slicedRequests)
  }

  useEffect(() => {
    if (requestsFormattedArray && requestsFormattedArray.length > requestsToShowFirstTime) {
      sliceRequests(0, requestsToShowFirstTime)
    } else {
      handleShowAllRequests()
    }
  }, [requestsFormattedArray])

  const handleShowAllRequests = () => {
    setRequestsToRender(requestsFormattedArray)
  }

  const requestsTableData = React.useMemo(
    () => requestsToRender,
    [requestsToRender, currentProjectId]
  )

  return (
    <StyledPaper>
      <H2 centered>{title}</H2>
      <Flex justifyContent='center'>
        <ButtonGroupBadges
          projects={projects}
          requests={acceptedRequests}
          currentProjectId={currentProjectId}
          onChange={(projectId) => setCurrentProjectId(projectId)}
        />
      </Flex>
      <TableWrapper>
        <BaseTable data={requestsTableData} columns={requestsTableColumns} isSortable={true} />
      </TableWrapper>
      {requestsToRender &&
        requestsFormattedArray &&
        requestsToRender.length === requestsToShowFirstTime &&
        requestsFormattedArray.length > requestsToShowFirstTime && (
          <Flex justifyContent='center' className='mt-6'>
            <Button variant='outlined' size='medium' type='submit' onClick={handleShowAllRequests}>
              Plný časový plán
            </Button>
          </Flex>
        )}
    </StyledPaper>
  )
}
