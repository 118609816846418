import axios from 'axios'

import { API } from '../../constants/api'
import { LoginAuthT } from '../../types/login'
import { getFromLocalStorage } from '../../utils/localStorage'
import axiosConfig from './axiosConfig'

export const axiosInstanceBearer = () =>
  axios.create({
    headers: {
      Authorization: `${getFromLocalStorage(API.BEARER_TOKEN)}`,
    },
    ...axiosConfig,
  })

export const axiosInstanceBasic = (auth: LoginAuthT) =>
  axios.create({
    auth: {
      username: auth.username,
      password: auth.password,
    },
    ...axiosConfig,
  })
