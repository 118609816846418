import { Badge, BadgeProps, Button, ButtonGroup } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'

import { DataProjectInfoT } from '../../api/data/project'
import { DataProductRequestHoursRequestT } from '../../types/types'
import Flex from '../atoms/Flex'

type PropsT = {
  projects: DataProjectInfoT[]
  requests: DataProductRequestHoursRequestT[]
  currentProjectId: number
  onChange: (projectId: number) => void
}

const StyledBadge = styled(Badge)<BadgeProps>(() => ({
  '& .MuiBadge-badge': {
    right: -14,
  },
}))

const StyledTWBadge = tw(StyledBadge)`
mr-6
ml-1
`

export const ButtonGroupBadges: React.FC<PropsT> = ({
  projects,
  requests,
  currentProjectId,
  onChange,
}) => {
  return (
    <>
      {projects && (
        <ButtonGroup variant='outlined' aria-label='outlined button group' className='mb-4'>
          <Button onClick={() => onChange(0)} size='small'>
            <Flex alignItems='center' className='normal-case'>
              Všechny projekty
              <StyledTWBadge
                badgeContent={requests.length}
                color={currentProjectId === 0 ? 'primary' : 'secondary'}
              />
            </Flex>
          </Button>
          {projects.map((project) => {
            const filteredRequestsByProject = requests.filter(
              (request) => request.projekt.id === project.projekt.id
            )
            return (
              <Button
                onClick={() => onChange(project.projekt.id)}
                key={project.projekt.id}
                size='small'>
                <Flex alignItems='center' className='normal-case'>
                  {project.projekt.nazevCz}{' '}
                  {filteredRequestsByProject.length > 0 && (
                    <StyledTWBadge
                      badgeContent={filteredRequestsByProject.length}
                      color={project.projekt.id === currentProjectId ? 'primary' : 'secondary'}
                    />
                  )}
                </Flex>
              </Button>
            )
          })}
        </ButtonGroup>
      )}
    </>
  )
}
