import React from 'react'
import tw from 'tailwind-styled-components'

import Container from '../atoms/Container'

type BaseContainerPropsT = {
  children: React.ReactNode
  className?: string
  centered?: boolean
}

type BaseContainerItemProps = {
  children: React.ReactNode
}

const StyledBaseContainer = tw(Container)<BaseContainerPropsT>`
${(p) =>
  p.className || p.centered ? 'pt-4 pb-10 bg-secondary' : 'pt-4 pb-10 mb-auto bg-secondary'}
`

const StyledBaseContainerItem = tw.div`
  mt-4
`

export const BaseContainer: React.FC<BaseContainerPropsT> = ({ children, className, centered }) => (
  <StyledBaseContainer className={className} centered={centered}>
    {children}
  </StyledBaseContainer>
)

export const BaseContainerItem: React.FC<BaseContainerItemProps> = ({ children }) => {
  return <StyledBaseContainerItem> {children} </StyledBaseContainerItem>
}
