import CloseIcon from '@mui/icons-material/Close'
import Alert, { AlertProps } from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import * as React from 'react'
import tw from 'tailwind-styled-components'

import { H3 } from '../atoms/Heading'

const StyledAlertContainer = tw.div`
w-full
`

export type TransitionAlertT = {
  variant: AlertProps['color']
  title: string
  desc: string
  icon: React.ReactNode
}

export interface ITransitionAlert extends TransitionAlertT {
  open: boolean
  setOpen: () => void
}

export const TransitionAlert: React.FC<ITransitionAlert> = ({
  open = false,
  setOpen,
  variant,
  title,
  desc,
  icon,
}) => {
  return (
    <StyledAlertContainer>
      <Collapse in={open}>
        <Alert
          icon={icon}
          action={
            <IconButton aria-label='close' color='inherit' size='small' onClick={() => setOpen()}>
              <CloseIcon fontSize='inherit' />
            </IconButton>
          }
          color={variant}>
          <H3>{title}</H3>
          {desc}
        </Alert>
      </Collapse>
    </StyledAlertContainer>
  )
}
