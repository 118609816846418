import { Paper } from '@mui/material'
import tw from 'tailwind-styled-components'

type Props = {
  className?: string
  children: any
  small?: boolean
  sx?: any
}

type StyledPaperProps = {
  $small?: boolean
}

const StyledPaperTW = tw(Paper)<StyledPaperProps>`
  p-12
  rounded-sm
  ${(p) => (p.$small ? 'px-12 py-7' : 'p-12')}
`

const StyledPaper: React.FC<Props> = ({ children, small, sx }) => {
  return (
    <StyledPaperTW $small={small} sx={sx}>
      {children}
    </StyledPaperTW>
  )
}

export default StyledPaper
