import { Alert, AlertTitle, Button, TextField } from '@mui/material'
import React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import tw from 'tailwind-styled-components'

import { H1 } from '../atoms/Heading'
import { BaseContainer } from '../templates/BaseContainer'

interface IResetPasswordFormInput {
  resetInput: string
}

const StyledForm = tw.div`
flex
justify-center
`

const StyledFormContainer = tw.div`
grid
grid-cols-2
gap-6
`

const StyledFormTitle = tw.div`
col-span-full
text-center
`

const StyledFormAlert = tw.div`
col-span-full
`

const StyledFormInput = tw.div`
col-span-2
flex
justify-center
`

const StyledFormButton = tw.div`
flex
justify-center
col-span-full
`

export const ResetPasswordPage: React.FC = () => {
  const { control, handleSubmit } = useForm<IResetPasswordFormInput>()

  const onSubmit: SubmitHandler<IResetPasswordFormInput> = (data) => {
    console.log(data)
  }

  return (
    <BaseContainer centered>
      <form onSubmit={handleSubmit(onSubmit)}>
        <StyledForm>
          <StyledFormContainer>
            <StyledFormTitle>
              <H1 size='H1'>Žádost o nové heslo</H1>
              <span>Vyplňte e-mail, na který Vám zašleme nové heslo.</span>
            </StyledFormTitle>

            <StyledFormAlert>
              <Alert severity='info'>
                <AlertTitle>Upozornění</AlertTitle>
                Zpracování žádosti může trvat několik hodin.
              </Alert>
            </StyledFormAlert>

            <StyledFormInput>
              <Controller
                name='resetInput'
                control={control}
                defaultValue=''
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    className='w-full'
                    label='Zadejte Váš e-mail'
                    variant='outlined'
                    value={value}
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
                rules={{
                  required: 'Zadal/a jste špatné heslo.',
                }}
              />
            </StyledFormInput>

            <StyledFormButton>
              <div className='flex justify-center'>
                <Button variant='contained' size='large' type='submit'>
                  Zaslat nové heslo
                </Button>
              </div>
            </StyledFormButton>
          </StyledFormContainer>
        </StyledForm>
      </form>
    </BaseContainer>
  )
}
