import React from 'react'
import tw from 'tailwind-styled-components'

type Props = {
  className?: string
  children: any
}

const StyledContainer = tw.div`
  max-w-full
  px-5
  mx-auto
  w-full
  sm:px-7
  lg:max-w-containerLG
`

const Container: React.FC<Props> = ({ children, className }) => {
  return <StyledContainer className={className}>{children}</StyledContainer>
}

export default Container
