import { Endpoints } from '../../constants/endpoints'
import { Language } from '../../types/types'
import { postApiData } from '../_GLOBAL/postApiData'
import { putApiData } from '../_GLOBAL/putApiData'

interface CustomerData {
  adresa: string
  ico: string
  jazyk: Language
  nazev: string
  platnostOd: string | null
  platnostDo: string | null
}

export const putAdminCustomer = (
  data: CustomerData & { id: number },
  responseCallback: () => void
) => {
  // temporary before API can handle language parameter
  const dataWithoutLanguage = { ...data } as Partial<CustomerData>
  delete dataWithoutLanguage.jazyk
  putApiData(Endpoints.adminCustomer(), dataWithoutLanguage).then(
    (response) => response && responseCallback()
  )
}

export const postAdminCustomer = (data: CustomerData, responseCallback: () => void) => {
  const dataWithoutLanguage = { ...data } as Partial<CustomerData>
  delete dataWithoutLanguage.jazyk
  postApiData(Endpoints.adminCustomer(), dataWithoutLanguage).then(
    (response) => response && responseCallback()
  )
}

export const delAdminCustomerId = (id: number) => putApiData(Endpoints.adminCustomerId(id))
