import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import {
  getDataAdditionalService,
  GetDataAdditionalServiceT,
} from '../../api/data/additionalService'
import { getDataCustomer } from '../../api/data/customer'
import { getDataProduct } from '../../api/data/product'
import { getDataSource } from '../../api/data/source'
import { MODALS } from '../../constants/modals'
import { ROUTES } from '../../constants/routes'
import { useDispatch, useSelector } from '../../store/hooks'
import { modalSlice } from '../../store/slices/modal'
import { DataCustomerT } from '../../types/types'
import { Customer, DataSourceT, Product } from '../../types/types'
import { BaseSwitch } from '../atoms/BaseSwitch'
import { BaseBreadcrumbs } from '../atoms/BreadCrumbs'
import { H1 } from '../atoms/Heading'
import StyledPaper from '../atoms/StyledPaper'
import TableWrapper from '../atoms/TableWrapper'
import { BaseTable, UseMemoColumn } from '../molecules/BaseTable'
import { BaseTabs } from '../molecules/BaseTabs'
import { BaseContainer } from '../templates/BaseContainer'

const TABS = [
  { name: 'customers', label: 'Zákazníci' },
  { name: 'source', label: 'Zdroje' },
  // { name: 'offTime', label: 'Výluky' },
  { name: 'products', label: 'Produkty' },
  { name: 'addServices', label: 'Doplň. Služby' },
  // { name: 'users', label: 'Užívatelé VÚŽ' },
]

const TabItemHeader = tw.div`
  flex
  justify-between
  py-2
  px-4
  min-h-58
`

const TabItemSwitch = tw.div`
  flex
  items-center
  text-sm
`

const TabItem: React.FC<{ currentValue: string; tabItemValue: string }> = ({
  currentValue,
  tabItemValue,
  children,
}) => {
  return <>{currentValue === tabItemValue && <TableWrapper>{children}</TableWrapper>}</>
}

interface TablesData {
  customers: DataCustomerT[]
  sources: DataSourceT[]
  products: Product[]
  additionalServices: GetDataAdditionalServiceT[]
}

const AdministrationTables: React.FC = () => {
  const [value, setValue] = useState('customers')
  const [tablesData, setTablesData] = useState<TablesData>({
    customers: [],
    sources: [],
    products: [],
    additionalServices: [],
  })
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // const openModal = () => {
  //   dispatch(
  //     modalSlice.actions.update({
  //       [MODALS.CUSTOMER_CREATER_OR_CHANGE_MODAL]: true,
  //     })
  //   )
  // }

  const isCustomerDataSend = useSelector((state) => state.modal.customerCreateOrChangeIsSend)

  const customerTableColumns = useMemo<UseMemoColumn<DataCustomerT>[]>(
    () => [
      { Header: 'Název', accessor: 'nazev', disableFilters: true },
      // { Header: 'Správce', accessor: 'manager' },
      { Header: 'IČO', accessor: 'ico', disableFilters: true },
      // { Header: 'Aktivní', accessor: 'active' },
    ],
    []
  )

  const customerTableData = useMemo(() => tablesData.customers, [tablesData.customers])

  const sourcesTableColumns = useMemo<UseMemoColumn<DataSourceT>[]>(
    () => [{ Header: 'Název', accessor: 'nazevCz', disableFilters: true }],
    []
  )

  const sourcesTableData = useMemo(() => tablesData.sources, [tablesData.sources])

  const productsTableColumns = useMemo<UseMemoColumn<Product>[]>(
    () => [
      { Header: 'Název', accessor: 'nazevCz', disableFilters: true },
      { Header: 'Popis', accessor: 'popisCz', disableFilters: true },
    ],
    []
  )

  const productsTableData = useMemo(() => tablesData.products, [tablesData.products])

  const additionServicesTableColumns = useMemo<UseMemoColumn<Product>[]>(
    () => [
      { Header: 'Název', accessor: 'nazevCz', disableFilters: true },
      { Header: 'Popis', accessor: 'popisCz', disableFilters: true },
    ],
    []
  )

  const additionServicesTableData = useMemo(
    () => tablesData.additionalServices,
    [tablesData.additionalServices]
  )

  const resetCustomerSendStatusIsSend = () => {
    dispatch(
      modalSlice.actions.update({
        [MODALS.CUSTOMER_CREATER_OR_CHANGE_MODAL_IS_SEND]: false,
      })
    )
  }

  const loadData = async () => {
    const data = await Promise.all([
      getDataCustomer(),
      getDataSource(),
      getDataProduct(),
      getDataAdditionalService(),
    ])
    data[0] &&
      setTablesData({
        ...tablesData,
        customers: data[0],
        sources: data[1],
        products: data[2],
        additionalServices: data[3],
      })
  }

  useEffect(() => {
    loadData()
  }, [])

  // Load data from APIs on modal close
  useEffect(() => {
    if (isCustomerDataSend) {
      resetCustomerSendStatusIsSend()
      loadData()
    }
  }, [isCustomerDataSend])

  return (
    <>
      <BaseTabs
        value={value}
        tabs={TABS}
        onChange={function (currentSourceName: string): void {
          setValue(currentSourceName)
        }}
      />
      <div>
        <TabItem currentValue={value} tabItemValue='customers'>
          <TabItemHeader>
            <TabItemSwitch>
              <BaseSwitch color='success' size='small' /> Jen aktivní zákazníci
            </TabItemSwitch>
            {/* <>*/}
            {/*  <BaseButton*/}
            {/*    startIcon={<AddCircleOutlineIcon />}*/}
            {/*    color='primary'*/}
            {/*    variant='outlined'*/}
            {/*    size='large'*/}
            {/*    onClick={() => openModal()}>*/}
            {/*    Přidat zákazníka*/}
            {/*  </BaseButton>*/}
            {/* </>*/}
          </TabItemHeader>
          <BaseTable
            columns={customerTableColumns}
            data={customerTableData}
            onRowClick={(data) => {
              const { original } = data
              const customer = original as Customer
              navigate(`${ROUTES.ADMINISTRATION_CUSTOMER_DETAIL}/${customer.ico}`)
            }}
            showPagination
          />
        </TabItem>
        <TabItem currentValue={value} tabItemValue='source'>
          <BaseTable columns={sourcesTableColumns} data={sourcesTableData} showPagination />{' '}
        </TabItem>
        <TabItem currentValue={value} tabItemValue='products'>
          <BaseTable columns={productsTableColumns} data={productsTableData} showPagination />{' '}
        </TabItem>
        <TabItem currentValue={value} tabItemValue='addServices'>
          <BaseTable
            columns={additionServicesTableColumns}
            data={additionServicesTableData}
            showPagination
          />{' '}
        </TabItem>
      </div>
    </>
  )
}

export const AdministrationPage: React.FC = () => {
  return (
    <BaseContainer>
      <BaseBreadcrumbs />
      <div className='mt-7 mb-7'>
        <H1 noMargin>Administrace</H1>
      </div>
      <StyledPaper>
        <AdministrationTables />
      </StyledPaper>
    </BaseContainer>
  )
}
