import Select from '@mui/material/Select'
import * as React from 'react'
import { useEffect } from 'react'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'

import STYLES from '../../constants/styles'
import { isNullOrUndefinedOrEmpty } from '../../utils/validate'

export type MultipleSelectNativePropsT = {
  list: Array<string>
  onChange: any
  value: any
  multiple?: boolean
}

const SelectStyled = styled(Select)`
  border-radius: 0.375rem;
  select {
    padding: 0 !important;
    appearance: none;
  }

  select#select-multiple-native:focus option:checked {
    background: ${STYLES.COLORS.primary.main};
    color: ${STYLES.COLORS_OTHERS.white};
  }

  option:checked {
    background: ${STYLES.COLORS.primary.main};
    color: ${STYLES.COLORS_OTHERS.white};
    font-weight: 700;
  }
`

const SelectTW = tw(SelectStyled)`
  py-2
  px-0
  shadow-sm
  w-full
  border-none
  bg-secondary-light
`

const Option = tw.option`
  px-5
  py-3
  rounded-md
  hover:bg-gray-100
`

export const SelectList: React.FC<MultipleSelectNativePropsT> = ({ list, onChange, value }) => {
  const [listName, setListName] = React.useState<string[]>([])
  const handleChangeMultiple = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { options } = event.target
    const value: string[] = []
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value)
      }
    }
    setListName(value)
    return value
  }

  useEffect(() => {
    setListName([list[0]])
  }, [list])

  return (
    <SelectTW
      multiple
      native
      value={!isNullOrUndefinedOrEmpty(value) ? [value] : [listName]}
      // @ts-ignore Typings are not considering `native`
      onChange={(e) => onChange(handleChangeMultiple(e))}
      sx={{ borderRadius: '0.375rem' }}
      inputProps={{
        id: 'select-multiple-native',
      }}>
      {!isNullOrUndefinedOrEmpty(list) &&
        list.map((name) => (
          <Option key={name} value={name}>
            {name}
          </Option>
        ))}
    </SelectTW>
  )
}
