import MeetingRoomIcon from '@mui/icons-material/MeetingRoom'
import TrainIcon from '@mui/icons-material/Train'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import React from 'react'

import { SOURCES } from '../../constants/sources'
import { DataSourceTypeT } from '../../types/types'

type PropsT = {
  sourceType: DataSourceTypeT
  fontSize?: 'small' | 'inherit' | 'large' | 'medium' | undefined
  color?:
    | 'secondary'
    | 'success'
    | 'inherit'
    | 'action'
    | 'warning'
    | 'disabled'
    | 'error'
    | 'primary'
    | 'info'
}

const SourceIcon: React.FC<PropsT> = ({ sourceType, fontSize = 'small', color = 'inherit' }) => {
  switch (sourceType.name) {
    case SOURCES.TYPE.RAIL_INFRASTRUCTURE:
      return <TrainIcon fontSize={fontSize} color={color} />
    case SOURCES.TYPE.HALLS:
      return <WarehouseIcon fontSize={fontSize} color={color} />
    case SOURCES.TYPE.ROOMS:
      return <MeetingRoomIcon fontSize={fontSize} color={color} />
    default:
      return <></>
  }
}

export const SourceTypeIcons: React.FC<PropsT> = ({
  sourceType,
  fontSize = 'small',
  color = 'inherit',
}) => {
  return <SourceIcon fontSize={fontSize} color={color} sourceType={sourceType} />
}
