import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import BadgeIcon from '@mui/icons-material/Badge'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import { Card } from '@mui/material'
import React, { useMemo } from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { getDataContractsCustomer } from '../../api/data/contracts'
import { getDataCustomerId, getDataCustomerIdUser } from '../../api/data/customer'
import { getDataProjectsCustomer, GetDataProjectsResponseT } from '../../api/data/project'
import { MODALS } from '../../constants/modals'
import { useDispatch, useSelector } from '../../store/hooks'
import { modalSlice } from '../../store/slices/modal'
import { Contract, Customer, CustomerDetailUzivatele, Uzivatel } from '../../types/types'
import { BaseButton } from '../atoms/BaseButton'
import { BaseSwitch } from '../atoms/BaseSwitch'
import Flex from '../atoms/Flex'
import { H1 } from '../atoms/Heading'
import { ImportantInfoRow, InfoRowTableCell } from '../atoms/ImportantInfoRow'
import { BaseTable, UseMemoColumn } from '../molecules/BaseTable'
import { BaseTabs, TabItem } from '../molecules/BaseTabs'
import { CustomerContractDialog } from '../organisms/Modals/CustomerContractDialog'
import { CustomerProjectDialog } from '../organisms/Modals/CustomerProjectDialog'
import { BaseContainer } from '../templates/BaseContainer'

interface CustomerDetailState {
  customer: Customer | null
  users: CustomerDetailUzivatele['uzivatele']
  contracts: Contract[]
  projects: GetDataProjectsResponseT[]
}

type TabsType = { name: 'contracts' | 'users' | 'projects'; label: string }

const TABS: TabsType[] = [
  { name: 'contracts', label: 'Smlouvy' },
  { name: 'projects', label: 'Projekty' },
  { name: 'users', label: 'Uživatelé' },
]

type ContractsTabProps = Pick<CustomerDetailState, 'contracts'> & {
  ico: Customer['ico'] | undefined
}

const TabItemHeader = tw.div`
  flex
  justify-between
  py-2
  px-4
  min-h-58
`

const TabItemSwitch = tw.div`
  flex
  items-center
  text-sm
`

const ContractsTabContent: React.FC<ContractsTabProps> = ({ contracts, ico = '' }) => {
  const dispatch = useDispatch()
  const { modal } = useSelector((state) => state)
  const initialFormState = modal.customerContractCreatedOrChangeInitialState

  const openCustomerUpdateModal = (data: any) => {
    const updatedData = { ...data.original }
    dispatch(
      modalSlice.actions.update({
        [MODALS.CUSTOMER_CONTRACT_CREATER_OR_CHANGE_STATE_MODAL]: updatedData,
      })
    )
    dispatch(
      modalSlice.actions.update({
        [MODALS.CUSTOMER_CONTRACT_MODAL]: true,
      })
    )
  }

  const contractsTableColumns = useMemo<UseMemoColumn<Contract>[]>(
    () => [
      {
        Header: 'Číslo smlouvy',
        accessor: 'cislo',
        disableFilters: true,
      },
      { Header: 'Popis', accessor: 'popis', disableFilters: true },
      { Header: 'Fixní VZO', accessor: 'fixVo', disableFilters: true },
      { Header: 'Fixní MZO', accessor: 'fixMo', disableFilters: true },
      { Header: 'Platnost od', accessor: 'platnostOd', disableFilters: true },
      { Header: 'Platnost do', accessor: 'platnostDo', disableFilters: true },
      // { Header: 'Platná', accessor: '' },
    ],
    [contracts]
  )

  const contractsTableData = useMemo(() => contracts, [contracts])
  return (
    <>
      <TabItemHeader>
        <TabItemSwitch>
          <BaseSwitch color='success' size='small' /> Jen platné smlouvy
        </TabItemSwitch>
        <>
          <BaseButton
            onClick={() => {
              openCustomerUpdateModal({ original: { ...initialFormState, icoZakaznik: ico } })
            }}
            startIcon={<AddCircleOutlineIcon />}
            color='primary'
            variant='outlined'
            size='large'>
            Přidat smlouvu
          </BaseButton>
        </>
      </TabItemHeader>
      <BaseTable
        onRowClick={(data) => {
          openCustomerUpdateModal(data)
        }}
        columns={contractsTableColumns}
        data={contractsTableData}
        isSortable
      />
    </>
  )
}

type ProjectsTabProps = Pick<CustomerDetailState, 'projects'>

const ProjectsTabContent: React.FC<ProjectsTabProps> = ({ projects }) => {
  const { modal } = useSelector((state) => state)
  const initialFormState = modal.customerProjectCreatedOrChangeInitialState
  const dispatch = useDispatch()

  const openProjectModal = (data: any) => {
    const updatedData = { ...data.original }
    dispatch(
      modalSlice.actions.update({
        [MODALS.CUSTOMER_PROJECT_CREATER_OR_CHANGE_STATE_MODAL]: updatedData,
      })
    )
    dispatch(
      modalSlice.actions.update({
        [MODALS.CUSTOMER_PROJECT_MODAL]: true,
      })
    )
  }

  const projectsTableColumns = useMemo<UseMemoColumn<GetDataProjectsResponseT>[]>(
    () => [
      {
        Header: 'Název',
        accessor: 'nazevCz',
        disableFilters: true,
      },
      { Header: 'Popis', accessor: 'popisCz', disableFilters: true },
      // { Header: 'Platná', accessor: '' },
    ],
    [projects]
  )

  const projectsTableData = useMemo(() => projects, [projects])

  return (
    <>
      <TabItemHeader>
        <TabItemSwitch>
          <BaseSwitch color='success' size='small' /> Jen aktivní projekty
        </TabItemSwitch>
        <>
          <BaseButton
            onClick={() => openProjectModal({ original: { ...initialFormState } })}
            startIcon={<AddCircleOutlineIcon />}
            color='primary'
            variant='outlined'
            size='large'>
            Přidat projekt
          </BaseButton>
        </>
      </TabItemHeader>
      <BaseTable
        onRowClick={(data) => {
          const { original } = data

          const { nazevCz, nazevEn, popisCz, popisEn, cisloSmlouva, id } =
            original as typeof initialFormState

          const updatedData: typeof initialFormState = {
            id,
            nazevCz,
            nazevEn,
            popisCz,
            popisEn,
            cisloSmlouva,
          }

          openProjectModal({ original: updatedData })
        }}
        columns={projectsTableColumns}
        data={projectsTableData}
        isSortable
      />
    </>
  )
}

type ModifiedUser = Uzivatel & {
  jmenoPrijmeni: string
}

const UsersTabContent: React.FC<{ users: CustomerDetailState['users'] }> = ({ users }) => {
  const modifiedUsers: ModifiedUser[] = []
  if (users.length > 0) {
    users.forEach((user) => {
      const modifiedUser = { ...user, jmenoPrijmeni: `${user.jmeno} ${user.prijmeni}` }
      modifiedUsers.push(modifiedUser)
    })
  }

  const usersTableColumns = useMemo<UseMemoColumn<typeof modifiedUsers[0]>[]>(
    () => [
      {
        Header: 'Jméno',
        accessor: 'jmenoPrijmeni',
        disableFilters: true,
      },
      { Header: 'Telefon', accessor: 'telefon', disableFilters: true },
      { Header: 'Email', accessor: 'email', disableFilters: true },
      { Header: 'Role', accessor: 'role', disableFilters: true },
      // { Header: 'Login', accessor: "telefon" },
      // { Header: 'Aktivní', accessor: "telefon" },
    ],
    [modifiedUsers]
  )

  const usersTableData = useMemo(() => modifiedUsers, [modifiedUsers])
  return (
    <>
      <TabItemHeader>
        <TabItemSwitch>
          <BaseSwitch color='success' size='small' /> Jen aktivní uživatelé
        </TabItemSwitch>
      </TabItemHeader>
      <BaseTable columns={usersTableColumns} data={usersTableData} isSortable />
    </>
  )
}

const makeImportantInfoData = (customer: CustomerDetailState['customer']): InfoRowTableCell[] => {
  return [
    {
      title: 'Adresa',
      icon: <LocationOnIcon />,
      info: <div>{customer?.adresa}</div>,
    },
    {
      title: 'IČO',
      icon: <BadgeIcon />,
      info: <div>{customer?.ico}</div>,
    },
    // TODO: add contact person. Currently not in api
  ]
  return []
}

export const CustomerDetailAdministrationPage: React.FC = () => {
  const [data, setData] = useState<CustomerDetailState>({
    customer: null,
    users: [],
    contracts: [],
    projects: [],
  })
  const [value, setValue] = useState(TABS[0].name)
  const params = useParams()

  // const openCustomerUpdateModal = (data: any) => {
  //   const updatedData = { ...data.original }
  //   dispatch(
  //     modalSlice.actions.update({
  //       [MODALS.CUSTOMER_CREATER_OR_CHANGE_STATE_MODAL]: updatedData,
  //     })
  //   )
  //   dispatch(
  //     modalSlice.actions.update({
  //       [MODALS.CUSTOMER_CREATER_OR_CHANGE_MODAL]: true,
  //     })
  //   )
  // }

  const isModalOpened = useSelector((state) => state.modal.customerCreateOrChange)

  const loadData = async () => {
    if (params.id) {
      const idAsNumber = parseInt(params.id)
      const responseCustomer = await Promise.all([getDataCustomerId(idAsNumber)])
      if (responseCustomer) {
        const response = await Promise.all([
          getDataCustomerId(idAsNumber),
          getDataCustomerIdUser(idAsNumber),
          getDataContractsCustomer(responseCustomer[0].ico),
          getDataProjectsCustomer(responseCustomer[0].ico),
        ])
        if (response) {
          setData({
            ...data,
            customer: response[0] || [],
            users: response[1].uzivatele || [],
            contracts: response[2] || [],
            projects: response[3] || [],
          })
        }
      }
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  // Load data from APIs on modal close
  useEffect(() => {
    if (!isModalOpened) loadData()
  }, [isModalOpened])

  return (
    <BaseContainer>
      <Flex justifyContent='space-between' alignItems='center' className='mt-7 mb-7'>
        <H1 noMargin>Zákazník {data.customer?.nazev}</H1>
        {/* <BaseButton*/}
        {/*  onClick={() => {*/}
        {/*    openCustomerUpdateModal({ original: data.customer })*/}
        {/*  }}*/}
        {/*  variant='outlined'*/}
        {/*  size='large'*/}
        {/*  color='primary'*/}
        {/*  startIcon={<EditIcon />}>*/}
        {/*  Upravit*/}
        {/* </BaseButton>*/}
      </Flex>
      <ImportantInfoRow cells={makeImportantInfoData(data.customer)} />
      <BaseTabs
        value={value}
        tabs={TABS}
        onChange={function (currentSourceName: string): void {
          setValue(currentSourceName as TabsType['name'])
        }}
      />
      <Card>
        <TabItem currentValue={value} tabItemValue={TABS[0].name}>
          <ContractsTabContent contracts={data.contracts} ico={data.customer?.ico} />
        </TabItem>
        <TabItem currentValue={value} tabItemValue={TABS[1].name}>
          <ProjectsTabContent projects={data.projects} />
        </TabItem>
        <TabItem currentValue={value} tabItemValue={TABS[2].name}>
          <UsersTabContent users={data.users} />
        </TabItem>
      </Card>
      <CustomerContractDialog onClose={loadData} />
      <CustomerProjectDialog onClose={loadData} customerIco={data?.customer?.ico || ''} />
    </BaseContainer>
  )
}
