import dayjs from 'dayjs'

export const isNullOrUndefined = (value: any): boolean => value === null || value === undefined

export const isNullOrUndefinedOrEmpty = (value: any): boolean =>
  value === null ||
  value === undefined ||
  value === '' ||
  value === 'null' ||
  String(value) === 'NaN' ||
  value.length === 0

export const isEmptyString = (s: string | null): boolean =>
  s === null || s === undefined || s === ''

export const isNotEmptyOrUndefined = (value: any): boolean =>
  value !== null && value !== '' && value !== undefined

export const isObjectEmpty = (object: Object) =>
  Object.keys(object).length === 0 && object.constructor === Object

export const isSameDay = (day: string | Date, otherDay: string | Date) =>
  dayjs(day).isSame(dayjs(otherDay))
