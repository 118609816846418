import * as React from 'react'
import { Controller } from 'react-hook-form'

import { Dropzone } from '../atoms/Dropzone'

type FieldDrozonePropsT = {
  name: string
  multiple: boolean
  control: any | undefined
}

export const FieldDrozone: React.FC<FieldDrozonePropsT> = ({
  name,
  multiple,
  control,
  ...rest
}) => {
  return (
    <Controller
      render={({ field: { onChange } }) => (
        <Dropzone
          multiple={multiple}
          onChange={(e) =>
            e?.target?.files && onChange(multiple ? e.target.files : e.target.files[0])
          }
          {...rest}
        />
      )}
      name={name}
      control={control}
      defaultValue=''
    />
  )
}
