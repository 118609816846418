import { Endpoints } from '../../constants/endpoints'
import { postApiData } from '../_GLOBAL/postApiData'
import { putApiData } from '../_GLOBAL/putApiData'

interface ProjectData {
  cisloSmlouva: string
  nazevCz: string
  nazevEn: string
  popisCz: string
  popisEn: string
}

export const createDataProject = (data: ProjectData) => postApiData(Endpoints.adminProject(), data)

export const updateDataProject = (data: ProjectData) => putApiData(Endpoints.adminProject(), data)
