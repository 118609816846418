export const useIsImageFileFormatAllowed = (fileName: string) => {
  return (
    fileName.slice(-4) === 'jpeg' ||
    fileName.slice(-4) === 'JPEG' ||
    fileName.slice(-3) === 'jpg' ||
    fileName.slice(-3) === 'JPG' ||
    fileName.slice(-3) === 'png' ||
    fileName.slice(-3) === 'PNG'
  )
}
