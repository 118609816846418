export const MODALS = {
  ADD_NEWS_ARTICLE: 'addNewsArticle',
  REMOVE_NEWS_ARTICLE: 'removeNewsArticle',
  REMOVE_NEWS_ARTICLE_ID: 'removeNewsArticleId',
  EDIT_NEWS_ARTICLE: 'editNewsArticle',
  EDIT_NEWS_ARTICLE_ID: 'editNewsArticleId',
  CUSTOMER_CONTRACT_MODAL: 'customerContract',
  CUSTOMER_CONTRACT_CREATER_OR_CHANGE_STATE_MODAL: 'customerContractCreatedOrChangeInitialState',
  CUSTOMER_PROJECT_MODAL: 'customerProject',
  CUSTOMER_PROJECT_CREATER_OR_CHANGE_STATE_MODAL: 'customerProjectCreatedOrChangeInitialState',
  CUSTOMER_CREATER_OR_CHANGE_MODAL: 'customerCreateOrChange',
  CUSTOMER_CREATER_OR_CHANGE_MODAL_IS_SEND: 'customerCreateOrChangeIsSend',
  CUSTOMER_CREATER_OR_CHANGE_STATE_MODAL: 'customerCreatedOrChangeInitialState',
  REQUEST_DETAIL_MODAL: 'requestDetail',
  REQUEST_DETAIL_ID: 'requestDetailId',
  REJECT_REQUEST_MODAL: 'rejectRequestModal',
  REJECT_REQUEST_ID: 'rejectRequestId',
  REJECT_REQUEST_USERNAME: 'rejectRequestUsername',
  REJECT_REQUEST_IS_SENDING: 'rejectRequestIsSending',
  ACCEPT_REQUEST_MODAL: 'acceptRequestModal',
  ACCEPT_REQUEST_ID: 'acceptRequestId',
  ACCEPT_REQUEST_IS_SENDING: 'acceptRequestIsSending',
}
