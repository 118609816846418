import { useResponseStatus } from '../../hooks/useResponseStatus'
import { LoginAuthT } from '../../types/login'
import { axiosInstanceBasic, axiosInstanceBearer } from './axiosInstance'

export async function postApiData<Response>(url: string, data: Object = {}) {
  return await axiosInstanceBearer()
    .post<Response>(url, data)
    .then((response) => {
      return {
        data: response,
        error: '',
      }
    })
    .catch((error) => {
      useResponseStatus(error.response.status)
      console.log('error', error)
      return {
        error: error.response?.data,
      }
    })
}

export async function postApiDataBasic(url: string, data: Object = {}, auth: LoginAuthT) {
  return await axiosInstanceBasic(auth).post(url, data)
}
