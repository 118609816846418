import { Endpoints } from '../../constants/endpoints'
import { DataNotificationsT, DataUserT } from '../../types/types'
import { delApiData } from '../_GLOBAL/delApiData'
import { getApiData } from '../_GLOBAL/getApiData'
import { putApiData } from '../_GLOBAL/putApiData'

export const getDataNotificationsUserName = (userName: DataUserT['username']) =>
  getApiData<DataNotificationsT[]>(Endpoints.dataNotificationsUserName(userName))

export const getDataNotificationsUserNameCount = (userName: string) =>
  getApiData(Endpoints.dataNotificationsUserNameCount(userName))

export const getDataNotificationsUserNameAll = (userName: string) =>
  getApiData(Endpoints.dataNotificationsUserNameAll(userName))

export const putDataNotifications = (id: number) => putApiData(Endpoints.dataNotificationsId(id))

export const delDataNotifications = (id: number) => delApiData(Endpoints.dataNotificationsId(id))
