export const REQUEST = {
  STATE: {
    ACCEPTED: 1,
    NOT_RECEIVED: 2,
    CONFIRMED: 3,
    NOT_CONFIRMED: 4,
    CANCELED: 5,
    CANCELED_SOURCE_EXCLUSION: 6,
  },
}
