import React from 'react'
import { Controller } from 'react-hook-form'

import type { BaseInputPropsT } from '../atoms/BaseInput'
import { BasicSelect, BasicSelectDataT } from '../atoms/BasicSelect'

interface IFieldInputProps extends BaseInputPropsT {
  name: string
  control: any | undefined
  rules?: Object
  data: BasicSelectDataT[]
}

export const FieldBaseSelect: React.FC<IFieldInputProps> = ({
  name,
  label,
  control,
  rules = {},
  data,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => {
        return <BasicSelect value={value} onChange={onChange} label={label} data={data} />
      }}
      rules={rules}
      {...rest}
    />
  )
}
