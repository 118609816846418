import { Endpoints } from '../../constants/endpoints'
import {
  IPostActionSpecifyRequest,
  IPostActionSpecifyRequestForUser,
  ReponseErrorT,
} from '../../types/types'
import { delApiData } from '../_GLOBAL/delApiData'
import { getApiData } from '../_GLOBAL/getApiData'
import { postApiData } from '../_GLOBAL/postApiData'
import { putApiData } from '../_GLOBAL/putApiData'

type AcceptErrorfullResponse = {
  potvrzeni: boolean
  error: string
  id: number
}

type AcceptRequestResponse = AcceptErrorfullResponse

type RejectSuccesfullResponse = {
  id: number
  zruseni: boolean
  zrusenyPozadavek: Record<string, any>
  error: string
}
type RejectErrorfullResponse = {
  error: string
  id: number
}

export type confirmRequestListT = {
  id: number
  poznamka: string
  usernameZadavatel?: string
}

export type confirmRequestListForUserT = confirmRequestListT & { usernameZadavatel: string }

type RejectRequestResponse = RejectSuccesfullResponse | RejectErrorfullResponse

export const getActionSpecifyRequestId = (id: number) =>
  getApiData(Endpoints.actionSpecifyRequestId(id))

export const putActionSpecifyRequestId = (id: number) =>
  putApiData(Endpoints.actionSpecifyRequestId(id))

export const delActionSpecifyRequestId = (id: number) =>
  delApiData(Endpoints.actionSpecifyRequestId(id))

export const confirmRequestList = (listId: confirmRequestListT[]) => {
  const response = putApiData<AcceptRequestResponse[]>(Endpoints.actionSpecifyRequestList(), listId)
  return response
}

export const rejectRequestList = (listId: confirmRequestListT[]) => {
  const response = putApiData<RejectRequestResponse[]>(
    Endpoints.actionSpecifyRequestDelete(),
    listId
  )
  return response
}

export const rejectRequestListForUser = (listId: confirmRequestListForUserT[]) => {
  const response = putApiData<RejectRequestResponse[]>(
    Endpoints.actionSpecifyRequestDeleteForUser(),
    listId
  )
  return response
}

export const delActionSpecifyRequestIdUserName = (id: number, userName: string) =>
  delApiData(Endpoints.actionSpecifyRequestIdUserName(id, userName))

export const postActionSpecifyRequest = (request: IPostActionSpecifyRequest) =>
  postApiData<any | ReponseErrorT>(Endpoints.actionSpecifyRequest(), request)

export const postActionSpecifyRequestForUser = (request: IPostActionSpecifyRequestForUser) =>
  postApiData<any | ReponseErrorT>(Endpoints.actionSpecifyRequestForUser(), request)
