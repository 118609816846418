import { AlertColor } from '@mui/material'
import { createSlice } from '@reduxjs/toolkit'

import { objectReducerGenerator } from '../objectReducerGenerator'

export interface SnackBarState {
  isOpen: boolean
  message: string
  severity: AlertColor
}

export const snackbarInitiState: SnackBarState = {
  isOpen: false,
  message: '',
  severity: 'info',
}

export const snackBarSlice = createSlice({
  name: 'snackBar',
  initialState: snackbarInitiState,
  reducers: {
    ...objectReducerGenerator<SnackBarState>(),
    reset(state) {
      Object.assign(state, snackbarInitiState)
    },
  },
})
