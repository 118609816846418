import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import { ButtonGroup } from '@mui/material'
import { FC, useState } from 'react'
import tw from 'tailwind-styled-components'

import { MODALS } from '../../../../constants/modals'
import styles from '../../../../constants/styles'
import { useDispatch } from '../../../../store/hooks'
import { modalSlice } from '../../../../store/slices/modal'
import { RequestDetail } from '../../../../types/types'
import { BaseButton } from '../../../atoms/BaseButton'
import { BaseTabs, TabItem } from '../../../molecules/BaseTabs'
import { DetailContent } from './DetailContent'
import { HistoryContent } from './HistoryContent'
import { NotesContent } from './NotesContent'
import { REQUEST_DETAIL_DIALOG_TABS } from './RequestDetailDialog'

interface RequestDetailProps {
  selectedRequest: RequestDetail
  selectedRequestId: number
}

const ModalRightColContent = tw.div`
  pb-12
  relative
`

const ModalRightColTabContent = tw.div`
  pt-3
  pb-3
  px-7
  min-h-350
`

const ModalRightColFooter = tw.div`
  absolute
  left-0
  right-0
  bottom-0
`

export const RequestDetailInfo: FC<RequestDetailProps> = ({
  selectedRequest,
  selectedRequestId,
}) => {
  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState(REQUEST_DETAIL_DIALOG_TABS[0].name)

  return (
    <>
      <ModalRightColContent>
        <BaseTabs
          sx={{ borderBottom: '1px solid', borderColor: styles.COLORS.grey[300] }}
          value={selectedTab}
          tabs={REQUEST_DETAIL_DIALOG_TABS}
          onChange={function (currentSourceName: string): void {
            setSelectedTab(currentSourceName)
          }}
        />
        <ModalRightColTabContent>
          <TabItem currentValue={selectedTab} tabItemValue={REQUEST_DETAIL_DIALOG_TABS[0].name}>
            <DetailContent setSelectedTab={setSelectedTab} selectedRequest={selectedRequest} />
          </TabItem>
          <TabItem currentValue={selectedTab} tabItemValue={REQUEST_DETAIL_DIALOG_TABS[1].name}>
            <NotesContent notes={selectedRequest.poznamky} />
          </TabItem>
          <TabItem currentValue={selectedTab} tabItemValue={REQUEST_DETAIL_DIALOG_TABS[2].name}>
            <HistoryContent history={selectedRequest.historie} />
          </TabItem>
        </ModalRightColTabContent>
        {selectedRequest.stav && selectedRequest.stav.name === 'PRIJATY' && (
          <ModalRightColFooter>
            <ButtonGroup sx={{ display: 'flex' }}>
              {/* <BaseButton*/}
              {/*  sx={{ flex: 1, borderRadius: 0 }}*/}
              {/*  size='large'*/}
              {/*  color='primary'*/}
              {/*  variant='contained'*/}
              {/*  startIcon={<AccessTimeIcon />}>*/}
              {/*  Jiný termín*/}
              {/* </BaseButton>*/}
              {!selectedRequest.podrobnosti.terminObsazeny && (
                <BaseButton
                  sx={{ flex: 1, borderRadius: 0 }}
                  size='large'
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    dispatch(
                      modalSlice.actions.update({
                        [MODALS.ACCEPT_REQUEST_MODAL]: true,
                        [MODALS.ACCEPT_REQUEST_ID]: selectedRequestId,
                      })
                    )
                  }}
                  startIcon={<DoneIcon />}>
                  Odsouhlasit
                </BaseButton>
              )}
              <BaseButton
                sx={{ flex: 1, borderRadius: 0 }}
                size='large'
                color='primary'
                variant='contained'
                onClick={() => {
                  dispatch(
                    modalSlice.actions.update({
                      [MODALS.REJECT_REQUEST_MODAL]: true,
                      [MODALS.REJECT_REQUEST_ID]: selectedRequestId,
                    })
                  )
                }}
                startIcon={<CloseIcon />}>
                Zamítnout
              </BaseButton>
            </ButtonGroup>
          </ModalRightColFooter>
        )}
      </ModalRightColContent>
    </>
  )
}
