import { createSlice } from '@reduxjs/toolkit'

import { objectReducerGenerator } from '../objectReducerGenerator'

export interface ReservationsState {
  filterBySource: string
}

const initialState: ReservationsState = {
  filterBySource: '',
}

export const reservationsSlice = createSlice({
  name: 'reservations',
  initialState,
  reducers: {
    ...objectReducerGenerator<ReservationsState>(),
    reset(state) {
      Object.assign(state, initialState)
    },
  },
})
