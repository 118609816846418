import { Endpoints } from '../../constants/endpoints'
import { DataNotificationsT } from '../../types/types'
import { delApiData } from '../_GLOBAL/delApiData'
import { putApiData } from '../_GLOBAL/putApiData'

export const putAdminNotification = (notificationId: DataNotificationsT['notifikace']['id']) =>
  putApiData(Endpoints.adminNotification(notificationId))

export const delAdminNotification = (notificationId: DataNotificationsT['notifikace']['id']) =>
  delApiData(Endpoints.adminNotification(notificationId))
