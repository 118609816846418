import { Endpoints } from '../../constants/endpoints'
import { Product } from '../../types/types'
import { getApiData } from '../_GLOBAL/getApiData'
import { GetDataAdditionalServiceT } from './additionalService'

export type AdditionalServicesT = {
  id: number
  nazevCz: string
  nazevEn: string
}

export type GetDataProductBySourceT = {
  produkt: Product
  zdroj: {
    id: number
    nazevCz: string
    popisCz: string
    nazevEn: string
    popisEn: string
    exkluzivita: boolean
    pozadavekSmena: boolean
    typZdroje: {
      name: string
      vyznamCz: string
      popisCz: string
      vyznamEn: string
      popisEn: string
    }
  }
  doplnkoveSluzby: GetDataAdditionalServiceT[]
}

export const getDataProduct = () => getApiData<Product[]>(Endpoints.dataProduct())

export const getDataProductId = (id: number) => getApiData(Endpoints.dataProductId(id))

export const getDataProductBySource = (sourceId: number) =>
  getApiData<GetDataProductBySourceT[]>(Endpoints.dataProductBySource(sourceId))
