import dayjs from 'dayjs'

import { Endpoints } from '../../constants/endpoints'
import { Request, RequestDetailWithTimeSlots } from '../../types/types'
import { formatDate } from '../../utils/format'
import { getApiData } from '../_GLOBAL/getApiData'

export const NEXT_MONDAY = formatDate(dayjs().add(7, 'day').day(1), 'DD.MM.YYYY')
export const NEXT_SUNDAY = formatDate(dayjs().add(7, 'day').day(7), 'DD.MM.YYYY')
export const PREVIOUS_MONDAY = formatDate(dayjs().subtract(7, 'day').day(7), 'DD.MM.YYYY')
export const MONDAY = formatDate(dayjs().day(0), 'DD.MM.YYYY')
export const SUNDAY = formatDate(dayjs().day(7), 'DD.MM.YYYY')

export const getRequests = (dateFrom = NEXT_MONDAY, dateTo = NEXT_SUNDAY) => {
  return getApiData<Request[]>(Endpoints.dataRequests(dateFrom, dateTo))
}

export const getRequestsHistory = (dateFrom = PREVIOUS_MONDAY, dateTo = NEXT_SUNDAY) => {
  return getApiData<Request[]>(Endpoints.dataRequests(dateFrom, dateTo))
}

export const getRequestsDetail = (dateFrom = NEXT_MONDAY, dateTo = NEXT_SUNDAY) => {
  return getApiData<RequestDetailWithTimeSlots[]>(Endpoints.dataRequestsDetail(dateFrom, dateTo))
}
