import { Endpoints } from '../../constants/endpoints'
import { delApiData } from '../_GLOBAL/delApiData'
import { postApiData } from '../_GLOBAL/postApiData'
import { putApiData } from '../_GLOBAL/putApiData'

type RejectErrorfullResponse = {
  error: string
  id: number
}

export const postAdminNews = (request: FormData) => postApiData(Endpoints.adminNews(), request)

// export const putAdminNews = (request: FormData) => putApiData(Endpoints.adminNews(), request)

export const delAdminArticle = (articleId: number) =>
  delApiData(Endpoints.adminNewsDelete(articleId))

export const putAdminNews = (request: FormData) => {
  const response = putApiData<RejectErrorfullResponse[]>(Endpoints.adminNews(), request)
  return response
}
