import InputLabel from '@mui/material/InputLabel'
import React from 'react'

import styles from '../../constants/styles'

export type BaseLabelPropsT = {
  children: any
}

export const BaseLabel: React.FC<BaseLabelPropsT> = ({ children }) => {
  return (
    <InputLabel
      sx={{
        fontWeight: 'bold',
        fontSize: '0.875rem',
        color: styles.COLORS_OTHERS.black,
        marginBottom: '0.875em',
      }}>
      {children}
    </InputLabel>
  )
}
