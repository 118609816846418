import styled from '@emotion/styled'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import * as React from 'react'
import tw from 'tailwind-styled-components/dist/tailwind'

import styles from '../../constants/styles'
import {
  DataProductRequestCountDaysT,
  DataProductRequestCountSourceAllT,
  DataShiftsT,
  DataSourceT,
} from '../../types/types'
import {
  formatDate,
  formatDateISO,
  getDayWeek,
  getFirstDayWeek,
  getLastDayWeek,
} from '../../utils/format'
import Flex from '../atoms/Flex'
import { FieldTimeSlot } from '../molecules/FieldTimeSlot'
import { ConfigParametersShiftsT } from './TimeSlotsTable'

const RenderDateTW = tw.div`
  text-right
`

const ArrowsStyled = styled(Flex)`
  svg {
    cursor: pointer;
    fill: ${styles.COLORS.primary.main};
  }
`

const Arrows = tw(ArrowsStyled)`
  gap-1
`

type RenderDatePropsT = {
  day: string
  date: string
}

const RenderDate: React.FC<RenderDatePropsT> = ({ day, date }) => (
  <RenderDateTW>
    {day} <strong>{date.split(' ').slice(1, 3).join(' ')}</strong>
  </RenderDateTW>
)

export interface ITimeSlotsTableData {
  date: string | React.ReactChild
  [i: string]: any
}

type FieldTimeSlotsMultipleT = {
  disabled: boolean
  usedSlots: DataShiftsT[]
  maxSlots: number
  firstShiftHour: number
  shiftHourLength: number
}

const FieldTimeSlotsMultiple: React.FC<FieldTimeSlotsMultipleT> = ({
  disabled,
  usedSlots,
  maxSlots,
  firstShiftHour,
  shiftHourLength,
}) => {
  const firstShiftHourReal = parseInt(String(firstShiftHour))
  const shiftHourLengthReal = parseInt(String(shiftHourLength))
  return (
    <Flex className='gap-2'>
      <FieldTimeSlot
        value='1'
        time={`${firstShiftHourReal}-${
          parseInt(String(firstShiftHourReal)) + parseInt(String(shiftHourLengthReal))
        }`}
        usedSlots={usedSlots && usedSlots.length > 0 ? usedSlots[0].pocet : 0}
        maxSlots={maxSlots}
        disabled={(usedSlots && usedSlots.length > 0 && usedSlots[0].pocet >= maxSlots) || disabled}
      />
      <FieldTimeSlot
        value='2'
        time={`${parseInt(String(firstShiftHourReal)) + parseInt(String(shiftHourLengthReal))}-${
          parseInt(String(firstShiftHourReal)) + parseInt(String(shiftHourLengthReal)) * 2
        }`}
        usedSlots={usedSlots && usedSlots.length > 0 ? usedSlots[1].pocet : 0}
        maxSlots={maxSlots}
        disabled={(usedSlots && usedSlots.length > 0 && usedSlots[1].pocet >= maxSlots) || disabled}
      />
      <FieldTimeSlot
        value='3'
        time={`${
          parseInt(String(firstShiftHourReal)) + parseInt(String(shiftHourLengthReal)) * 2
        }-${parseInt(String(firstShiftHourReal)) + parseInt(String(shiftHourLengthReal)) * 3 - 24}`}
        usedSlots={usedSlots && usedSlots.length > 0 ? usedSlots[2].pocet : 0}
        maxSlots={maxSlots}
        disabled={(usedSlots && usedSlots.length > 0 && usedSlots[2].pocet >= maxSlots) || disabled}
      />
    </Flex>
  )
}

type FieldTimeSlotsCustomTimeT = {
  disabled: boolean
  usedSlots: DataProductRequestCountDaysT['celyDen']
  maxSlots: number
}

const FieldTimeSlotsCustomTime: React.FC<FieldTimeSlotsCustomTimeT> = ({
  disabled,
  usedSlots,
  maxSlots,
}) => (
  <FieldTimeSlot
    value='0'
    time='Vlastní čas'
    usedSlots={usedSlots}
    maxSlots={maxSlots}
    disabled={(usedSlots && usedSlots >= maxSlots) || disabled}
  />
)

export const timeSlotsTableData = (
  headers: DataSourceT[],
  disabled: boolean,
  date: Date,
  timeslotsRequests: DataProductRequestCountSourceAllT[],
  configParametersShifts: ConfigParametersShiftsT
) => {
  const restHeadersObj = (headers: DataSourceT[], disabled: boolean, date: string): Object => {
    const object: { [id: number]: React.ReactNode | string } = {}
    headers.forEach((header) => {
      const filteredRequest = timeslotsRequests.filter((request) => request.zdroj.id === header.id)
      const selectRequestsByDate = filteredRequest
        ? filteredRequest.map((request) => {
            const selectByDate = request.dny.find((day) => day.datum === date)
            return selectByDate
              ? selectByDate.smeny
              : [
                  {
                    smena: 1,
                    pocet: 0,
                  },
                  {
                    smena: 2,
                    pocet: 0,
                  },
                  {
                    smena: 3,
                    pocet: 0,
                  },
                ]
          })
        : []

      const selectAllDayShiftsByDate = filteredRequest
        ? filteredRequest.map((request) => {
            const selectByDate = request.dny.find((day) => day.datum === date)
            return selectByDate ? selectByDate.celyDen : 0
          })?.[0] || 0
        : 0

      return (object[header.id] =
        header.id === 1 || header.id === 2 ? (
          <FieldTimeSlotsMultiple
            usedSlots={selectRequestsByDate[0]}
            maxSlots={configParametersShifts.maxShiftHoursPerDay}
            firstShiftHour={configParametersShifts.firstShiftHour}
            shiftHourLength={configParametersShifts.shiftHoursLength}
            disabled={disabled}
          />
        ) : (
          <FieldTimeSlotsCustomTime
            usedSlots={selectAllDayShiftsByDate}
            maxSlots={configParametersShifts.maxShiftHoursPerDay}
            disabled={disabled}
          />
        ))
    })
    return object
  }

  const days = {
    monday: getDayWeek(date, 1),
    tuesday: getDayWeek(date, 2),
    wednesday: getDayWeek(date, 3),
    thursday: getDayWeek(date, 4),
    friday: getDayWeek(date, 5),
    saturday: getDayWeek(date, 6),
    sunday: getDayWeek(date, 7),
  }

  return [
    {
      date: (
        <RenderDate
          key={formatDateISO(days.monday)}
          day='Pondělí'
          date={formatDate(days.monday, 'dddd D. MMM')}
        />
      ),
      ...restHeadersObj(headers, disabled, formatDate(days.monday, 'DD.MM.YYYY')),
    },
    {
      date: (
        <RenderDate
          key={formatDateISO(days.tuesday)}
          day='Úterý'
          date={formatDate(days.tuesday, 'dddd D. MMM')}
        />
      ),
      ...restHeadersObj(headers, disabled, formatDate(days.tuesday, 'DD.MM.YYYY')),
    },
    {
      date: (
        <RenderDate
          key={formatDateISO(days.wednesday)}
          day='Středa'
          date={formatDate(days.wednesday, 'dddd D. MMM')}
        />
      ),
      ...restHeadersObj(headers, disabled, formatDate(days.wednesday, 'DD.MM.YYYY')),
    },
    {
      date: (
        <RenderDate
          key={formatDateISO(days.thursday)}
          day='Čtvrtek'
          date={formatDate(days.thursday, 'dddd D. MMM')}
        />
      ),
      ...restHeadersObj(headers, disabled, formatDate(days.thursday, 'DD.MM.YYYY')),
    },
    {
      date: (
        <RenderDate
          key={formatDateISO(days.friday)}
          day='Pátek'
          date={formatDate(days.friday, 'dddd D. MMM')}
        />
      ),

      ...restHeadersObj(headers, disabled, formatDate(days.friday, 'DD.MM.YYYY')),
    },
    {
      date: (
        <RenderDate
          key={formatDateISO(days.saturday)}
          day='Sobota'
          date={formatDate(days.saturday, 'dddd D. MMM')}
        />
      ),
      ...restHeadersObj(headers, disabled, formatDate(days.saturday, 'DD.MM.YYYY')),
    },
    {
      date: (
        <RenderDate
          key={formatDateISO(days.sunday)}
          day='Neděle'
          date={formatDate(days.sunday, 'dddd D. MMM')}
        />
      ),
      ...restHeadersObj(headers, disabled, formatDate(days.sunday, 'DD.MM.YYYY')),
    },
  ]
}

export const timeSlotsTableDataColumns = (
  headers: DataSourceT[],
  date: Date,
  onChangeDate: (action: 'nextWeek' | 'prevWeek') => void
) => [
  {
    Header: (
      <Flex alignItems='center' justifyContent='end' className='gap-3'>
        <Flex flexDirection='column'>
          <div>{formatDate(getFirstDayWeek(date), 'dddd D. MMM YYYY')} -</div>
          <div>{formatDate(getLastDayWeek(date), 'dddd D. MMM YYYY')}</div>
        </Flex>
        <Arrows>
          <span className=''>
            <ArrowBackIcon sx={{ fontSize: 20 }} onClick={() => onChangeDate('prevWeek')} />
          </span>
          <span>
            <ArrowForwardIcon sx={{ fontSize: 20 }} onClick={() => onChangeDate('nextWeek')} />
          </span>
        </Arrows>
      </Flex>
    ),
    accessor: 'date',
    disableFilters: true,
  },
  ...headers.map((header) => ({
    Header: header.nazevCz,
    accessor: header.id.toString(),
    disableFilters: true,
  })),
]
