import { TextField, TextFieldProps } from '@mui/material'
import React from 'react'

import styles from '../../constants/styles'

export type BaseInputPropsT = {
  label?: string
  className?: string
  variant?: 'outlined' | 'filled' | 'standard'
  multiline?: boolean
  rows?: number
  value?: string
  error?: any
  hiddenLabel?: boolean
  defaultValue?: string | number
}

export const BaseInput: React.FC<BaseInputPropsT & TextFieldProps> = ({
  label,
  className,
  variant = 'outlined',
  multiline = false,
  rows = undefined,
  value,
  error,
  hiddenLabel,
  ...rest
}) => {
  return (
    <TextField
      sx={{ backgroundColor: styles.COLORS.grey[50] }}
      hiddenLabel={hiddenLabel}
      label={label}
      className={className}
      variant={variant}
      multiline={multiline}
      rows={rows}
      value={value}
      error={!!error}
      helperText={error ? error.message : null}
      {...rest}
    />
  )
}
