import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined'
import { Divider } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { MODALS } from '../../constants/modals'
import { useDispatch } from '../../store/hooks'
import { modalSlice } from '../../store/slices/modal'
import Flex from '../atoms/Flex'
import { H3 } from '../atoms/Heading'

type NewsPreviewPostPropsT = {
  url: string
  title: string
  date: string
  dateIcon?: boolean
  content: string
  editable?: boolean
  id: number
}

const StyledTitleContainer = tw.div`
  flex
  items-center
  justify-between
`

const StyledTitleIcons = tw.div`
  flex
`

const StyledEditOutlinedIcon = tw(EditOutlinedIcon)`
  text-primary
  hover:text-primary-light
  cursor-pointer
`

const StyledDeleteForeverOutlinedIcon = tw(DeleteForeverOutlinedIcon)`
  ml-3
  text-primary
  hover:text-primary-light
  cursor-pointer
`

const StyledDateIcon = tw.div`
  flex
  items-center
  text-sm
`

const StyledWrapper = tw.div`
  grid
  grid-flow-row
  gap-2
`

const StyledDate = tw.span`
  text-sm
`

const StyledPreviewText = tw.span`
  text-sm
`

export const NewsPreviewPost: React.FC<NewsPreviewPostPropsT> = ({
  url,
  title,
  date,
  dateIcon = false,
  content,
  editable = false,
  id,
}) => {
  const dispatch = useDispatch()

  const openRemoveNewsMessageModal = () => {
    dispatch(
      modalSlice.actions.update({
        [MODALS.REMOVE_NEWS_ARTICLE]: true,
        [MODALS.REMOVE_NEWS_ARTICLE_ID]: id,
      })
    )
  }

  const openEditNewsMessageModal = () => {
    dispatch(
      modalSlice.actions.update({
        [MODALS.EDIT_NEWS_ARTICLE]: true,
        [MODALS.EDIT_NEWS_ARTICLE_ID]: id,
      })
    )
  }

  return (
    <StyledWrapper>
      <StyledTitleContainer>
        <Link to={url}>
          <H3 primaryColor>{title}</H3>
        </Link>
        {editable && (
          <StyledTitleIcons>
            <StyledEditOutlinedIcon onClick={() => openEditNewsMessageModal()} />
            <StyledDeleteForeverOutlinedIcon onClick={() => openRemoveNewsMessageModal()} />
          </StyledTitleIcons>
        )}
      </StyledTitleContainer>
      <Flex alignItems='center' className='text-grey-500'>
        {dateIcon && (
          <StyledDateIcon>
            <EventNoteOutlinedIcon fontSize='inherit' />
          </StyledDateIcon>
        )}
        <StyledDate>{date}</StyledDate>
      </Flex>
      <StyledPreviewText>{content}</StyledPreviewText>
      <Divider />
    </StyledWrapper>
  )
}
