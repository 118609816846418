import React from 'react'
import { Link } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { H4 } from '../atoms/Heading'

type NewsBoxPropsT = {
  url: string
  title: string
  date: string
}

const StyledNewsBox = tw.div`
  flex
  flex-col
  p-4
  border
  border-gray-200
  rounded
`

const StyledDate = tw.div`
  text-gray-500
  mt-2
  text-sm
`

export const NewsBox: React.FC<NewsBoxPropsT> = ({ url, title, date }) => {
  return (
    <StyledNewsBox>
      <Link to={url}>
        <H4 primaryColor>{title}</H4>
      </Link>
      <StyledDate>{date}</StyledDate>
    </StyledNewsBox>
  )
}
