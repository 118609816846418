import { Button } from '@mui/material'
import * as React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import ReactQuill from 'react-quill'
import tw from 'tailwind-styled-components'

import { postAdminNews } from '../../../api/admin/news'
import { MODALS } from '../../../constants/modals'
import { useSnackbar } from '../../../hooks/useSnackbar'
import { useDispatch, useSelector } from '../../../store/hooks'
import { modalSlice } from '../../../store/slices/modal'
import Flex from '../../atoms/Flex'
import { FieldBaseInput } from '../../molecules/FieldBaseInput'
import { FieldDrozone } from '../../molecules/FieldDropzone'
import { Modal } from '../../molecules/Modal'

type PropsT = {}

interface IAddNewsArticleDialog {
  image: any
  title: string
  description: string
  date: string
  text: string
}

const StyledButtons = tw.div`
mt-6
flex
justify-center
gap-4
`

export const AddNewsArticleDialog: React.FC<PropsT> = () => {
  const [displaySnackBarWithMessage] = useSnackbar()

  const isOpened = useSelector((state) => state.modal.addNewsArticle)

  const { control, handleSubmit, reset } = useForm<IAddNewsArticleDialog>()

  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(
      modalSlice.actions.update({
        [MODALS.ADD_NEWS_ARTICLE]: false,
      })
    )
  }

  const onSubmit: SubmitHandler<IAddNewsArticleDialog> = (data) => {
    console.log(data)

    const formData = new FormData()
    formData.append(
      'novinka',
      JSON.stringify({
        nazev: data.title,
        popis: data.description,
        obsah: data.text,
      })
    )
    formData.append('files', data.image)

    postAdminNews(formData).then((response) => {
      console.log('response', response)
      if (response && response.error) {
        displaySnackBarWithMessage({
          message: `Nepodařilo se vytvořit novinku`,
          isOpen: true,
          severity: 'error',
        })
        handleClose()
        reset()
      } else {
        displaySnackBarWithMessage({
          message: `Podařilo se vytvořit novinku`,
          isOpen: true,
          severity: 'success',
        })
        handleClose()
        reset()
      }
    })
  }

  return (
    <Modal open={isOpened} type='main' maxWidth='md' modalId={MODALS.ADD_NEWS_ARTICLE}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDirection='column' className='gap-3'>
          <FieldDrozone name='image' multiple={false} control={control} />
          <FieldBaseInput
            name='title'
            control={control}
            label='Nadpis článku'
            className='min-w-full'
            rules={{
              required: 'Zadejte nadpis článku',
            }}
          />

          <FieldBaseInput
            name='description'
            control={control}
            multiline
            rows={4}
            label='Popis článku'
            className='min-w-full'
            rules={{
              required: 'Zadejte popis článku',
            }}
          />

          <Controller
            name='text'
            control={control}
            render={({ field: { onChange, value } }) => (
              <ReactQuill value={value || ''} onChange={onChange} />
            )}
          />

          <StyledButtons>
            <Button variant='contained' size='large' className='col-span-4' type='submit'>
              Vložit
            </Button>
            <Button
              variant='outlined'
              size='large'
              className='col-span-4'
              type='submit'
              onClick={() => handleClose()}>
              Zahodit
            </Button>
          </StyledButtons>
        </Flex>
      </form>
    </Modal>
  )
}
