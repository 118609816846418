import { Endpoints } from '../../constants/endpoints'
import { DataSourceT, DataSourceTypeT } from '../../types/types'
import { getApiData } from '../_GLOBAL/getApiData'

export type GetDataSourceTypeAllT = {
  name: string
  vyznamCz: string
  popisCz: string
  vyznamEn: string
  popisEn: string
}

export type GetDataSourceBySourceTypeT = {
  id: number
  nazevCz: string
  nazevEn: string
  exkluzivita: boolean
  pozadavekSmena: boolean
  typZdroje: {
    name: string
    vyznam: string
    popis: string
  }
  platnostOd: number
  usernameUprava: string
  datumUprava: number
}

export const getDataSourceTypeAll = () =>
  getApiData<DataSourceTypeT[]>(Endpoints.dataSourceTypeAll())

export const getDataSourceBySourceType = (sourceType: string) =>
  getApiData<DataSourceT[]>(Endpoints.dataSourceBySourceType(sourceType))

export const getDataSource = () => getApiData<DataSourceT[]>(Endpoints.dataSource())
