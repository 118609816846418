import DownloadIcon from '@mui/icons-material/Download'
import { Button, Paper } from '@mui/material'
import dayjs from 'dayjs'
import { saveAs } from 'file-saver'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'

import { getDataExcelRequests } from '../../api/data/excel'
import { getDataProductRequestSourceTypeState } from '../../api/data/productRequest'
import { FORMAT } from '../../constants/format'
import { ROUTES } from '../../constants/routes'
import { COLORS } from '../../constants/styles'
import {
  DataProductRequestHoursRequestT,
  DataRequestProductSourceTypeStateT,
  DataSourceT,
  DataSourceTypeT,
} from '../../types/types'
import { formatDate, formatDateWithIncomingFormat } from '../../utils/format'
import Flex from '../atoms/Flex'
import { SourceTypeIcons } from '../atoms/SourceTypeIcons'
import { BaseTabs } from '../molecules/BaseTabs'
import { ScheduleTable } from '../molecules/ScheduleTable'

type PropsT = {
  title: string
  sources: DataSourceT[]
  sourcesType: DataSourceTypeT[]
  hideShowMoreButton?: boolean
}

const StyledCurrentRequestsContainer = tw.div`
p-6
`

const StyledTitle = tw.h2`
font-bold
text-center
py-4
`

const StyledRow = styled.div`
  width: 100%;
  div:not(:first-of-type) {
    border-top: 1px solid ${COLORS.grey['200']};
  }
`

const StyledTW = tw(StyledRow)`
bg-secondary-light
`

type RenderShiftsPropsT = {
  requests: DataProductRequestHoursRequestT[]
}

const RenderShifts: React.FC<RenderShiftsPropsT> = ({ requests }) => (
  <StyledTW>
    {requests &&
      requests.map((request) => (
        <div className='p-3' key={request.pozadavek.id}>
          <strong>
            {request.pozadavek.zadani.dny[0].hodinaOd}:00-{request.pozadavek.zadani.dny[0].hodinaDo}
            :00
          </strong>{' '}
          {request.zakaznik.nazev}
        </div>
      ))}
  </StyledTW>
)

export const NearestReservationsAdmin: React.FC<PropsT> = ({
  title,
  sources,
  sourcesType,
  hideShowMoreButton = false,
}) => {
  const [currentSourceType, setCurrentSourceType] =
    useState<DataSourceTypeT['name']>('ZEL_INFRASTUKTURA')
  const [requests, setRequests] = useState<DataRequestProductSourceTypeStateT[]>([])

  const tabs = sourcesType.map((source) => ({
    name: source.name,
    label: source.vyznamCz,
    icon: <SourceTypeIcons sourceType={source} />,
  }))

  const handleDownloadExcel = () => {
    const dateFrom = formatDate(dayjs().add(7, 'day').day(1), 'DD.MM.YYYY')
    const dateTo = formatDate(dayjs().add(7, 'day').day(7), 'DD.MM.YYYY')

    getDataExcelRequests(dateFrom, dateTo)
      .then(function (response: any) {
        console.log('response: ', response)
        return new Blob([response.data], {
          type: response.headers['content-type'],
        })
      })
      .then(function (blob) {
        saveAs(blob, `${dateFrom}-${dateTo}.xlsx`)
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  const filteredSources = sources.filter((source) => source.typZdroje.name === currentSourceType)

  const currentSourceTypeArr = sources.filter(
    (source) => source.typZdroje.name === currentSourceType
  )

  const sourcesIds = currentSourceTypeArr.map((source) => ({
    id: source.id,
  }))

  const onChangeSourceTabs = (currentSourceName: DataSourceTypeT['name']) => {
    return setCurrentSourceType(currentSourceName)
  }

  useEffect(() => {
    getDataProductRequestSourceTypeState(
      formatDate(dayjs().add(7, 'day').day(1), 'DD.MM.YYYY'),
      formatDate(dayjs().add(7, 'day').day(7), 'DD.MM.YYYY'),
      currentSourceType,
      'POTVRZENY'
    ).then((request) => request && setRequests(request))
  }, [currentSourceType])

  const columns = React.useMemo(
    () => [
      {
        Header: '',
        accessor: 'time',
        disableFilters: true,
      },
      ...filteredSources.map((source) => ({
        Header: source.nazevCz,
        accessor: source.id.toString(),
        disableFilters: true,
      })),
    ],
    [filteredSources]
  )

  const requestsPairedSourceId = React.useMemo(
    () =>
      requests && requests.length > 0 && sourcesIds
        ? requests.map((request) => {
            const compoundObj: Record<string, any> = {}
            compoundObj.time = formatDateWithIncomingFormat(
              request.datum,
              FORMAT.API_DDMMYYYY,
              'dddd D. MMM',
              'cs'
            )
            const sourcesById = sourcesIds.map((source) => ({
              [source.id]: request.pozadavky
                ? request.pozadavky.filter((request) => request.zdroj.id === source.id)
                : [],
            }))

            sourcesById.map((sourcesArr) => {
              const sourceId = Object.keys(sourcesArr)
              const filteredRequests = request.pozadavky.filter(
                (request) => request.zdroj.id === parseInt(sourceId[0])
              )
              compoundObj[sourceId[0]] = request.pozadavky ? (
                <RenderShifts requests={filteredRequests} />
              ) : (
                []
              )
            })
            return compoundObj
          })
        : [],
    [requests, currentSourceType, columns]
  )

  const data = React.useMemo(() => requestsPairedSourceId, [requestsPairedSourceId])

  return (
    <Paper>
      <StyledCurrentRequestsContainer>
        {title && title.length > 0 && <StyledTitle>{title}</StyledTitle>}
        <BaseTabs value={currentSourceType} tabs={tabs} onChange={onChangeSourceTabs} />
        <ScheduleTable columns={columns} data={data} />
      </StyledCurrentRequestsContainer>
      <Flex justifyContent='center' className='py-6'>
        {!hideShowMoreButton && (
          <Link className='no-underline' to={ROUTES.RESERVATION}>
            <Button variant='outlined' size='medium'>
              Zobrazit více
            </Button>
          </Link>
        )}
        <div className='ml-2'>
          <Button
            variant='contained'
            onClick={() => handleDownloadExcel()}
            startIcon={<DownloadIcon />}>
            Plný časový plán
          </Button>
        </div>
      </Flex>
    </Paper>
  )
}
