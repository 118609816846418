import AdapterDateFns from '@mui/lab/AdapterDateFns'
import DatePicker from '@mui/lab/DatePicker'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import TextField from '@mui/material/TextField'
import * as React from 'react'
import { FieldError } from 'react-hook-form'

import styles from '../../constants/styles'

type BaseDatePickerPropsT = {
  label?: string
  value: string
  onChange: () => void
  hiddenLabel?: boolean
  error: FieldError | undefined
}

export const BaseDatePicker: React.FC<BaseDatePickerPropsT> = ({
  label,
  value,
  onChange,
  hiddenLabel,
  error,
  ...rest
}) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DatePicker
      label={label}
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField
          sx={{
            svg: { color: styles.COLORS.primary.main, backgroundColor: styles.COLORS.grey[50] },
            backgroundColor: styles.COLORS.grey[50],
          }}
          hiddenLabel={hiddenLabel}
          className='w-full'
          {...params}
          error={!!error}
        />
      )}
      {...rest}
    />
  </LocalizationProvider>
)
