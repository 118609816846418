import { yupResolver } from '@hookform/resolvers/yup'
import * as React from 'react'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { createDataProject, updateDataProject } from '../../../api/admin/project'
import { getDataContractsCustomer } from '../../../api/data/contracts'
import { MODALS } from '../../../constants/modals'
import { useDispatch, useSelector } from '../../../store/hooks'
import { modalInitialState, modalSlice } from '../../../store/slices/modal'
import { Contract, Customer } from '../../../types/types'
import { BaseButton } from '../../atoms/BaseButton'
import { BaseLabel } from '../../atoms/BaseLabel'
import Flex from '../../atoms/Flex'
import { FieldBaseInput } from '../../molecules/FieldBaseInput'
import { FieldBaseSelect } from '../../molecules/FieldBaseSelect'
import { Modal } from '../../molecules/Modal'

const schema = yup
  .object({
    nazevCz: yup.string().required(),
    popisCz: yup.string().required(),
    cisloSmlouva: yup.string().required(),
  })
  .required()

type Props = {
  onClose: () => void
  customerIco: Customer['ico']
}

export const CustomerProjectDialog: React.FC<Props> = ({ onClose, customerIco }) => {
  const [contracts, setContracts] = useState<Contract[]>([])
  const { modal } = useSelector((state) => state)
  const isOpened = modal.customerProject
  const initialFormState = modal.customerProjectCreatedOrChangeInitialState
  const isProjectupdate = initialFormState.cisloSmlouva.length > 0

  const loadContracts = async () => {
    const response = await getDataContractsCustomer(customerIco)
    setContracts(response)
  }

  useEffect(() => {
    if (isOpened) loadContracts()
  }, [isOpened])

  const dispatch = useDispatch()
  const handleClose = () => {
    dispatch(
      modalSlice.actions.update({
        [MODALS.CUSTOMER_PROJECT_CREATER_OR_CHANGE_STATE_MODAL]:
          modalInitialState.customerProjectCreatedOrChangeInitialState,
        [MODALS.CUSTOMER_PROJECT_MODAL]: false,
      })
    )
  }

  useEffect(() => {
    reset(initialFormState)
  }, [initialFormState])

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => initialFormState, [initialFormState]),
  })

  const onSubmit = handleSubmit((data) => {
    if (isProjectupdate) {
      updateDataProject(data)
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id: _id, ...dataWithoutId } = data
      createDataProject({ ...dataWithoutId, nazevEn: data.nazevCz, popisEn: data.popisCz })
    }
    handleClose()
    onClose()
  })

  return (
    <Modal
      type='small'
      open={isOpened}
      title={isProjectupdate ? 'Upravit projekt' : 'Přidat projekt'}
      modalId={MODALS.CUSTOMER_PROJECT_MODAL}
      onClose={handleClose}
      centered>
      <form onSubmit={onSubmit} className='w-full'>
        <div className='grid gap-5 grid-cols-1'>
          <div className='col-span-2'>
            <BaseLabel>Název</BaseLabel>
            <FieldBaseInput className='w-full' name='nazevCz' control={control} hiddenLabel />
          </div>
          <div className='col-span-2'>
            <BaseLabel>Popis</BaseLabel>
            <FieldBaseInput className='w-full' name='popisCz' control={control} hiddenLabel />
          </div>
          <div className='col-span-2'>
            <BaseLabel>Smlouva</BaseLabel>
            <FieldBaseSelect
              className='w-full'
              name='cisloSmlouva'
              control={control}
              data={contracts.map((contract) => ({ id: contract.cislo, name: contract.cislo }))}
              hiddenLabel
            />
          </div>
          <Flex justifyContent='end' className='col-span-2'>
            <BaseButton type='submit' variant='contained' color='primary' size='large'>
              {isProjectupdate ? 'Upravit projekt' : 'Přidat projekt'}
            </BaseButton>
          </Flex>
        </div>
      </form>
    </Modal>
  )
}
