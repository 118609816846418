// eslint-disable-next-line @typescript-eslint/no-var-requires
const em = require('../utils/styles/em')

module.exports = {
  COLORS: {
    primary: {
      light: '#3369AB',
      main: '#014497',
      dark: '#091933',
      contrastText: '#fff',
    },
    secondary: {
      light: '#FFFFFF',
      main: '#F5F6FA',
      dark: '#ECEEF2',
      contrastText: '#000',
    },
    error: {
      main: '#d32f2f',
    },
    grey: {
      50: '#fcfcfc',
      100: '#f5f5f5',
      200: '#e5e5e5',
      300: '#cdcdcd',
      400: '#b8bccc',
      500: '#636363',
      800: '#323232',
    },
  },
  COLORS_OTHERS: {
    transparent: 'transparent',
    white: '#ffffff',
    black: '#000000',
    aliceBlue: '#f5f7fa',
  },
  BREAKPOINTS: {
    ms: em(580),
    sm: em(768),
    md: em(992),
    lg: em(1236),
  },
}
