import React from 'react'
import tw from 'tailwind-styled-components'

import { BaseBreadcrumbs } from '../atoms/BreadCrumbs'
import { NewsDetail } from '../organisms/NewsDetail'
import { BaseContainer } from '../templates/BaseContainer'

const StyledNewsContainer = tw.div`
grid
justify-center
mt-10
`

export const NewsDetailPage: React.FC = () => {
  return (
    <BaseContainer>
      <BaseBreadcrumbs />
      <StyledNewsContainer>
        <NewsDetail />
      </StyledNewsContainer>
    </BaseContainer>
  )
}
