import { Endpoints } from '../../constants/endpoints'
import {
  DataRequestContractT,
  DataRequestCustomerT,
  DataRequestProjectT,
  UserDetail,
} from '../../types/types'
import { getApiData } from '../_GLOBAL/getApiData'

export type GetDataProjectsResponseT = {
  id: number
  nazevCz: string
  popisCz: string
  nazevEn: string
  popisEn: string
  cisloSmlouva: string
  usernameVytvoreni: string
  datumVytvoreni: string
  usernameUprava: string
  datumUprava: string
}

export const getDataProjects = (): Promise<any> =>
  getApiData<GetDataProjectsResponseT[]>(Endpoints.dataProject())

export type DataProjectInfoT = {
  projekt: DataRequestProjectT
  smlouva: DataRequestContractT
  zakaznik: DataRequestCustomerT
}

export const getDataProjectsCustomer = (ico: UserDetail['icoZakaznik']): Promise<any> =>
  getApiData<GetDataProjectsResponseT[]>(Endpoints.dataProjectCustomer(ico))

export type GetDataProjectsResponseArrayT = GetDataProjectsResponseT[]

export const getDataProjectInfo = () => getApiData<DataProjectInfoT[]>(Endpoints.dataProjectInfo())

export const getDataProjectInfoCustomer = (ico: UserDetail['icoZakaznik']) =>
  getApiData<DataProjectInfoT[]>(Endpoints.dataProjectInfoCustomer(ico))
