import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import { Button, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { DataNewsT, getNews } from '../../api/data/news'
import { MODALS } from '../../constants/modals'
import { ROUTES } from '../../constants/routes'
import { useSelector } from '../../store/hooks'
import { modalSlice } from '../../store/slices/modal'
import { H2 } from '../atoms/Heading'
import StyledPaper from '../atoms/StyledPaper'
import { NewsBox } from '../molecules/NewsBox'

type NewsBackOfficeOverviewPropsT = {
  title: string
}

const StyledNewsBoxContainer = tw.div`
  grid
  grid-cols-3
  justify-items-center
  my-5
  lg:grid-cols-5
`

const StyledNewsBox = tw.div`
  p-2
`

export const NewsBackOfficeOverview: React.FC<NewsBackOfficeOverviewPropsT> = ({ title }) => {
  const [news, setNews] = useState<DataNewsT[]>([])

  const addNewsArticleModal = useSelector((state) => state.modal.addNewsArticle)

  const dispatch = useDispatch()
  const openAddNewsMessageModal = () => {
    dispatch(
      modalSlice.actions.update({
        [MODALS.ADD_NEWS_ARTICLE]: true,
      })
    )
  }

  useEffect(() => {
    getNews(5, 0).then((response) => setNews(response))
  }, [addNewsArticleModal])

  return (
    <StyledPaper>
      <H2 centered>{title}</H2>
      <StyledNewsBoxContainer>
        {news &&
          news.map((article) => (
            <StyledNewsBox key={article.id}>
              <NewsBox
                title={article.nazev}
                date={article.datumVytvoreni}
                url={`${ROUTES.NEWS_DETAIL}/${article.id}`}
              />
            </StyledNewsBox>
          ))}
      </StyledNewsBoxContainer>
      <Grid container justifyContent='center'>
        <Button
          onClick={() => openAddNewsMessageModal()}
          variant='contained'
          color='primary'
          size='large'
          startIcon={<AddOutlinedIcon />}>
          Přidat zprávu
        </Button>
        <div className='ml-6'>
          <Link to={ROUTES.NEWS} className='no-underline'>
            <Button variant='outlined' size='large' endIcon={<EditOutlinedIcon />}>
              Spravovat
            </Button>
          </Link>
        </div>
      </Grid>
    </StyledPaper>
  )
}
