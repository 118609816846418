import { createSlice } from '@reduxjs/toolkit'

import { DataCustomerT } from '../../types/types'
import { objectReducerGenerator } from '../objectReducerGenerator'

export interface ReservationState {
  isOpen: boolean
  currentCustomerUsername: DataCustomerT['nazev']
  isInProgress: boolean
}

const initialState: ReservationState = {
  isOpen: false,
  currentCustomerUsername: '',
  isInProgress: false,
}

export const reservationSlice = createSlice({
  name: 'reservation',
  initialState,
  reducers: {
    ...objectReducerGenerator<ReservationState>(),
  },
})
