import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded'
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded'
import { Checkbox, Pagination, styled } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import * as React from 'react'
import { useEffect, useImperativeHandle } from 'react'
import {
  Column,
  ColumnInstance,
  Row,
  useFilters,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'

import styles from '../../constants/styles'

const StyledTableRow = styled(TableRow)(({ onClick }) => ({
  // TODO: add hover effect for row if onClick
  ':hover': {
    cursor: onClick ? 'pointer' : 'cursor',
  },
}))

const PaginationWrapper = styled('div')(({ theme: { spacing } }) => ({
  margin: spacing(1, 0),
  display: 'flex',
  justifyContent: 'center',
}))

type PropsT = {
  columns: Array<Column<object>>
  data: Array<object>
  isSortable?: boolean
  onRowClick?: (row: Row<object>) => void
  showPagination?: boolean
  showSelectable?: boolean
  small?: boolean
  onSelectedRowsChange?: (
    selectedFlatRows: Row<object>[],
    selectedRowIds: Record<string, boolean>
  ) => void
}

type TableHeadColumnT = {
  column: ColumnInstance
  isSortable: boolean
  small?: boolean
}

export interface UseMemoColumn<T> {
  Header: string
  accessor: keyof T
}

const StyledTable = styled(Table)`
  .css-1qvwp5v-MuiTableCell-root {
    font-size: 0.75rem;
    font-weight: 400;
  }

  td:first-of-type {
    font-weight: 700;
  }
`

const TableHeadColumn: React.FC<TableHeadColumnT> = ({ column, isSortable, small }) => {
  const columnHeaderProps = column.getHeaderProps(
    isSortable ? column.getSortByToggleProps() : undefined
  )

  const { key, ...restColumn } = columnHeaderProps

  return (
    <TableCell
      key={key}
      {...restColumn}
      sx={{ borderColor: styles.COLORS.grey[200], padding: small ? '0.5rem' : '1rem' }}>
      {column.render('Header')}
      {column.canFilter ? column.render('Filter') : null}
      {isSortable && (
        <span>
          {column.isSorted ? (
            column.isSortedDesc ? (
              <ArrowDropDownRoundedIcon />
            ) : (
              <ArrowDropUpRoundedIcon />
            )
          ) : (
            ''
          )}
        </span>
      )}
    </TableCell>
  )
}

// export const AutocompleteColumnFilter = <T,>({
//   // @ts-ignore This table has shitty TS support :(
//   // column: { filterValue, setFilter, preFilteredRows, id },
//   column,
//   autoCompleteProps,
// }: {
//   autoCompleteProps: BaseAutocompleteProps<T>
// }) => {
//   if (!column) console.log(autoCompleteProps, 'props')
//
//   // // Calculate the options for filtering
//   // // using the preFilteredRows
//   const options = React.useMemo(() => {
//     const options = new Set()
//     preFilteredRows.forEach((row: { values: { [x: string]: unknown } }) => {
//       options.add(row.values[id])
//     })
//     return [...options.values()]
//   }, [id, preFilteredRows])
//
//   // Render a multi-select box
//   return (
//     // <div> test</div>
//     <select
//       value={column?.filterValue}
//       onChange={(e) => {
//         column?.setFilter(e.target.value || undefined)
//       }}>
//       <option value=''>All</option>
//     </select>
//   )
// }

const TABLE_PAGE_SIZE = 10
const TABLE_INITIAL_INDEX = 0

export const BaseTable: React.FC<
  PropsT & { ref?: React.Ref<{ toggleAllRowsSelected: (value?: boolean) => void }> }
> = React.forwardRef(
  (
    {
      small,
      columns,
      data,
      onRowClick,
      showPagination,
      showSelectable,
      onSelectedRowsChange,
      isSortable = false,
    },
    ref
  ) => {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      page,
      prepareRow,
      gotoPage,
      state: { pageIndex, selectedRowIds },
      selectedFlatRows,
      toggleAllRowsSelected,
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: TABLE_INITIAL_INDEX, pageSize: TABLE_PAGE_SIZE },
        pageCount: -1,
      },
      useFilters,
      useSortBy,
      usePagination,
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) =>
              showSelectable ? (
                <div>
                  <Checkbox
                    onClick={(event) => {
                      event.stopPropagation()
                    }}
                    {...getToggleAllRowsSelectedProps()}
                  />
                </div>
              ) : (
                <></>
              ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) =>
              showSelectable ? (
                <div>
                  <Checkbox
                    onClick={(event) => {
                      event.stopPropagation()
                    }}
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ) : (
                <></>
              ),
          },
          ...columns,
        ])
      }
    )

    // Pass func to ref origin
    ref && useImperativeHandle(ref, () => ({ toggleAllRowsSelected }), [])

    useEffect(() => {
      onSelectedRowsChange && onSelectedRowsChange(selectedFlatRows, selectedRowIds)
    }, [selectedRowIds, selectedFlatRows])

    const renderRow = (row: Row<object>) => {
      prepareRow(row)
      const { key, ...restRowProps } = row.getRowProps()
      return (
        <StyledTableRow
          key={key}
          {...restRowProps}
          onClick={() => {
            onRowClick && onRowClick(row)
          }}>
          {row.cells.map((cell) => {
            const { key, ...restCellProps } = cell.getCellProps()
            return (
              <TableCell
                sx={{
                  borderColor: styles.COLORS.grey[200],
                  fontSize: '0.75rem',
                  padding: small ? '0.5rem' : '1rem',
                }}
                key={key}
                {...restCellProps}>
                {cell.render('Cell')}
              </TableCell>
            )
          })}
        </StyledTableRow>
      )
    }

    const pageIndexToPageNumber = pageIndex + 1
    return (
      <>
        <StyledTable {...getTableProps()}>
          <TableHead
            sx={{
              backgroundColor: styles.COLORS_OTHERS.aliceBlue,
              th: {
                padding: small ? '0.25rem 0.5rem' : '1rem',
                fontWeight: 400,
                fontSize: small ? '0.75rem' : '0.875rem',
              },
            }}>
            {headerGroups.map((headerGroup) => {
              const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps()
              return (
                <TableRow key={key} {...restHeaderGroupProps}>
                  {headerGroup.headers.map((column, index) => (
                    <TableHeadColumn key={index} column={column} isSortable={isSortable} />
                  ))}
                </TableRow>
              )
            })}
          </TableHead>
          <TableBody {...getTableBodyProps}>
            {showPagination
              ? page.map((page) => renderRow(page))
              : rows.map((row) => renderRow(row))}
          </TableBody>
        </StyledTable>
        {showPagination && (
          <PaginationWrapper>
            <Pagination
              page={pageIndexToPageNumber}
              count={Math.ceil(data.length / TABLE_PAGE_SIZE)}
              shape='rounded'
              color='primary'
              onChange={(e, value: number) => {
                gotoPage(value - 1)
              }}
            />
          </PaginationWrapper>
        )}
      </>
    )
  }
)

BaseTable.displayName = 'BaseTable'
