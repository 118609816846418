import React from 'react'
import { Controller } from 'react-hook-form'

import type { BaseInputPropsT } from '../atoms/BaseInput'
import { BaseInput } from '../atoms/BaseInput'

interface IFieldInputProps extends BaseInputPropsT {
  name: string
  control: any | undefined
  rules?: Object
}

export const FieldBaseInput: React.FC<IFieldInputProps> = ({
  name,
  label,
  className,
  variant,
  multiline,
  rows,
  control,
  rules = {},
  hiddenLabel,
  defaultValue = '',
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <BaseInput
          hiddenLabel={hiddenLabel}
          label={label}
          className={className}
          variant={variant}
          multiline={multiline}
          rows={rows}
          value={value}
          onChange={onChange}
          error={!!error}
        />
      )}
      rules={rules}
      {...rest}
    />
  )
}
