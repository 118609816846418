export const setToLocalStorage = (key: string, value: string | undefined | void) => {
  return window.localStorage.setItem(key, value || '')
}

export const getFromLocalStorage = (key: string) => {
  return window.localStorage.getItem(key)
}

export const removeFromLocalStorage = (key: string) => {
  return window.localStorage.removeItem(key)
}
