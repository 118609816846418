import { createSlice } from '@reduxjs/toolkit'

import { DataUserT, Language } from '../../types/types'
import { objectReducerGenerator } from '../objectReducerGenerator'

export interface UserState {
  logged: boolean
  firstName: DataUserT['jmeno']
  lastName: DataUserT['prijmeni']
  username: DataUserT['username']
  vuz: boolean
  role: 'admin' | 'vuz_read' | 'vuz_spravce' | null
  language: Language
  icoZakaznik: DataUserT['icoZakaznik']
  platnostOd: DataUserT['platnostOd']
}

const initialState: UserState = {
  logged: false,
  firstName: 'null',
  lastName: 'null',
  username: '',
  vuz: false,
  role: null,
  language: 'CZ',
  icoZakaznik: '',
  platnostOd: 0,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    ...objectReducerGenerator<UserState>(),
    reset(state) {
      Object.assign(state, initialState)
    },
  },
})
