import { yupResolver } from '@hookform/resolvers/yup'
import * as React from 'react'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

import { postAdminCustomer, putAdminCustomer } from '../../../api/admin/customer'
import { MODALS } from '../../../constants/modals'
import { useDispatch, useSelector } from '../../../store/hooks'
import { modalInitialState, modalSlice } from '../../../store/slices/modal'
import { Customer, Language } from '../../../types/types'
import { formatDate } from '../../../utils/format'
import { BaseButton } from '../../atoms/BaseButton'
import { BaseLabel } from '../../atoms/BaseLabel'
import Flex from '../../atoms/Flex'
import { FieldBaseInput } from '../../molecules/FieldBaseInput'
import { Modal } from '../../molecules/Modal'

const schema = yup
  .object({
    nazev: yup.string().required(),
    ico: yup.string().required(),
    adresa: yup.string().required(),
  })
  .required()

type DataWithouSimpleLanguage = Omit<Customer, 'jazyk' | 'platnostDo'> & {
  jazyk: Language
  platnostDo: string | null
}

export const CustomerCreateOrChangeDialog: React.FC = () => {
  const { modal, user } = useSelector((state) => state)
  const isOpened = modal.customerCreateOrChange
  const initialFormState = modal.customerCreatedOrChangeInitialState
  const isCustomerUpdate = initialFormState.id > 0

  const dispatch = useDispatch()

  const handleSendCustomerData = () => {
    dispatch(
      modalSlice.actions.update({
        [MODALS.CUSTOMER_CREATER_OR_CHANGE_MODAL_IS_SEND]: true,
      })
    )
  }

  const handleClose = () => {
    dispatch(
      modalSlice.actions.update({
        [MODALS.CUSTOMER_CREATER_OR_CHANGE_STATE_MODAL]:
          modalInitialState.customerCreatedOrChangeInitialState,
        [MODALS.CUSTOMER_CREATER_OR_CHANGE_MODAL]: false,
      })
    )
  }

  useEffect(() => {
    reset(initialFormState)
  }, [initialFormState])

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues: useMemo(() => initialFormState, [initialFormState]),
  })

  // TODO: add post or submit to endpoint
  const onSubmit = handleSubmit((data) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { jazyk: _jazyk, ...dataWithoutLanguage } = data
    const dataWithout: DataWithouSimpleLanguage = {
      ...dataWithoutLanguage,
      platnostDo: null,
      jazyk: user.language,
    }
    if (isCustomerUpdate) {
      dataWithout.jazyk = data.jazyk.name
      putAdminCustomer(dataWithout, handleSendCustomerData)
    } else {
      dataWithout.platnostOd = formatDate(undefined, 'DD.MM.YYYY')
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id: _id, ...dataWithoutId } = dataWithout
      postAdminCustomer(dataWithoutId, handleSendCustomerData)
    }
    handleClose()
  })

  return (
    <Modal
      type='main'
      open={isOpened}
      title={isCustomerUpdate ? 'Upravit zákazníka' : 'Přidat zákazníka'}
      modalId={MODALS.CUSTOMER_CREATER_OR_CHANGE_MODAL}
      onClose={() => handleClose()}
      centered>
      <form onSubmit={onSubmit} className='w-full'>
        <div className='grid gap-5 grid-cols-1'>
          <div className='col-span-2'>
            <BaseLabel>Název</BaseLabel>
            <FieldBaseInput className='w-full' name='nazev' control={control} hiddenLabel />
          </div>
          <div className='col-span-2'>
            <BaseLabel>IČO</BaseLabel>
            <FieldBaseInput className='w-full' name='ico' control={control} hiddenLabel />
          </div>
          <div className='col-span-2'>
            <BaseLabel>Adresa</BaseLabel>
            <FieldBaseInput className='w-full' name='adresa' control={control} hiddenLabel />
          </div>
          <Flex justifyContent='end' className='col-span-2'>
            <BaseButton type='submit' variant='contained' color='primary' size='large'>
              {isCustomerUpdate ? 'Uložit změny' : 'Přidat zákazníka'}
            </BaseButton>
          </Flex>
        </div>
      </form>
    </Modal>
  )
}
