import { DataNotificatonT, DataRequestT, DataSourceTypeT, UserDetail } from '../types/types'

export const Endpoints = {
  login: (): string => '/login',

  actionSpecifyRequestId: (id: number) => `/action/notifikace/${id}`,
  actionSpecifyRequestIdUserName: (id: number, userName: string): string =>
    `/action/notifikace/${id}/${userName}`,
  actionSpecifyRequest: (): string => `/action/zadanypozadavek`,
  actionSpecifyRequestList: (): string => `/action/zadanypozadavek`,
  actionSpecifyRequestDelete: (): string => `action/zadanypozadavek/delete`,
  actionSpecifyRequestDeleteForUser: (): string => `action/zadanypozadavek/delete/foruser`,
  actionSpecifyRequestForUser: (): string => `/action/zadanypozadavek/foruser`,
  actionNotifications: (listId: number): string => `/action/notifikace/${listId}`,

  adminAdditionalServiceId: (id: number): string => `/admin/doplnkovasluzba/${id}`,
  adminAdditionalService: (): string => `/admin/doplnkovasluzba`,
  adminConfigParameters: (): string => `/admin/konfigParametry`,
  adminProductId: (id: number): string => `/admin/produkt/${id}`,
  adminProduct: (): string => `/admin/produkt`,
  adminContractId: (id: number): string => `/admin/smlouva/${id}`,
  adminContract: (): string => `/admin/smlouva`,
  adminUserUserName: (userName: string): string => `/admin/uzivatel/${userName}`,
  adminUser: (): string => `/admin/uzivatel`,
  adminSourceExclusionId: (id: number): string => `/admin/vylukazdroje/${id}`,
  adminSourceExclusion: (): string => `/admin/vylukazdroje`,
  adminCustomer: (): string => `/admin/zakaznik`,
  adminCustomerId: (id: number): string => `/admin/zakaznik/${id}`,
  adminSourceId: (id: number): string => `/admin/zdroj/${id}`,
  adminSource: (): string => `/admin/zdroj`,
  adminProject: (): string => `/admin/projekt`,
  adminNotification: (listId: DataNotificatonT['id']): string => `/admin/notifikace/${listId}`,
  adminNews: (): string => `/admin/novinka`,
  adminNewsDelete: (articleId: number): string => `/admin/novinka/${articleId}`,

  dataConfigParameters: (): string => `/data/konfigparametry`,
  dataConfigParametersCode: (kod: string): string => `/data/konfigparametry/${kod}`,
  dataNotificationsUserName: (userName: string): string => `/data/notifikace/${userName}`,
  dataNotificationsUserNameCount: (userName: string): string =>
    `/data/notifikace/${userName}/count`,
  dataNotificationsUserNameAll: (userName: string): string => `/data/notifikace/${userName}/all`,
  dataNotificationsId: (id: number): string => `/data/notifikace/${id}`,
  dataProductRequestCountSourceAll: (datumOd: string, datumDo: string): string =>
    `/data/pozadavekcount/zdroj/${datumOd},${datumDo}/all`,
  dataProductRequestCountSourceStateAll: (
    datumOd: string,
    datumDo: string,
    state: DataRequestT['stav']['name']
  ): string => `/data/pozadavekcount/zdroj/${datumOd},${datumDo}/${state}/all`,
  dataProductRequestCount: (datumOd: string, datumDo: string): string =>
    `/data/pozadaveknaprodukt/${datumOd},${datumDo}/count`,
  dataProductRequestCountCustomer: (datumOd: string, datumDo: string): string =>
    `/data/pozadaveknaprodukt/${datumOd},${datumDo}/count/zakaznik`,
  dataProductRequestState: (datumOd: string, datumDo: string, state: string): string =>
    `/data/pozadaveknaprodukt/${datumOd},${datumDo}/${state}`,
  dataProductRequestStateCustomer: (datumOd: string, datumDo: string): string =>
    `/data/pozadaveknaprodukt/${datumOd},${datumDo}/stav/zakaznik`,
  dataProductRequest: (datumOd: string, datumDo: string): string =>
    `/data/pozadaveknaprodukt/${datumOd},${datumDo}`,
  dataProductRequestCustomer: (datumOd: string, datumDo: string): string =>
    `/data/pozadaveknaprodukt/${datumOd},${datumDo}/zakaznik`,
  dataProductRequestSourceState: (
    datumOd: string,
    datumDo: string,
    sourceType: DataSourceTypeT['name'],
    state: DataRequestT['stav']['name']
  ): string => `/data/pozadaveknaprodukt/${datumOd},${datumDo}/${sourceType}/${state}`,
  dataProduct: (): string => `/data/produkt`,
  dataProductId: (id: number): string => `/data/produkt/${id}`,
  dataProductBySource: (source: number): string => `/data/produkt/zdroj/${source}`,
  dataProject: (): string => `/data/projekt`,
  dataProjectInfo: (): string => `/data/projekt/info`,
  dataProjectCustomer: (ico: UserDetail['icoZakaznik']): string => `/data/projekt/zakaznik/${ico}`,
  dataProjectInfoCustomer: (ico: UserDetail['icoZakaznik']): string =>
    `/data/projekt/info/zakaznik/${ico}`,
  dataCustomerId: (id: number): string => `/data/zakaznik/${id}`,
  dataCustomerIdUser: (id: number): string => `/data/zakaznik/${id}/uzivatel`,
  dataCustomer: (): string => `/data/zakaznik`,
  dataApp: (): string => `/data/app`,
  dataRole: (): string => `/data/role`,
  dataUser: (userName: string): string => `/data/uzivatel/${userName}`,
  dataUserActive: (): string => `/data/uzivatel/aktivni`,
  dataSourceExclusion: (datumOd: string, datumDo: string): string =>
    `/data/vylukazdroje/${datumOd}/${datumDo}`,
  dataSourceTypeAll: (): string => `/data/zdroj/typyzdroje`,
  dataSource: (): string => `/data/zdroj`,
  dataSourceBySourceType: (typZdroje: string): string => `/data/zdroj/typzdroje/${typZdroje}`,
  dataAdditionalService: (): string => `/data/doplnkovasluzba`,
  dataContract: (): string => `/data/smlouva`,
  dataContractCustomer: (ico: UserDetail['icoZakaznik']): string => `/data/smlouva/zakaznik/${ico}`,
  dataRequests: (datumOd: string, datumDo: string): string => `/data/zadosti/${datumOd},${datumDo}`,
  dataRequestsDetail: (datumOd: string, datumDo: string): string =>
    `/data/zadosti/detail/${datumOd},${datumDo}`,
  dataNews: (limit: number, offset: number): string => `/data/novinka/${limit}/${offset}`,
  dataNewsArticle: (articleId: number): string => `/data/novinka/${articleId}`,
  dataNewsCount: (): string => `/data/novinka/count`,
  dataExcelRequests: (datumOd: string, datumDo: string): string =>
    `/data/excel/zadanepozadavky/${datumOd},${datumDo}`,
  dataNewsFileRequest: (id: number, name: string): string =>
    `/data/novinka/priloha?id=${id}&name=${name}`,
  dataFixedDemandSource: (username: string, datumOd: string, datumDo: string): string =>
    `/data/fixninarokyzdroj/foruser/${username},${datumOd},${datumDo}`,
  dataFixedDemandSourceAssigned: (username: string, datumOd: string, datumDo: string): string =>
    `/data/fixninarokyzdroj/foruser/zadane/${username},${datumOd},${datumDo}`,
}
