import * as React from 'react'
import { Controller } from 'react-hook-form'

import { BaseDatePicker } from '../atoms/BaseDatePicker'
type FieldDatePickerPropsT = {
  name: string
  label?: string
  control: any | undefined
  hiddenLabel?: boolean
}

export const FieldDatePicker: React.FC<FieldDatePickerPropsT> = ({
  name,
  label,
  control,
  hiddenLabel,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=''
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <BaseDatePicker
          hiddenLabel={hiddenLabel}
          label={label}
          value={value}
          onChange={onChange}
          error={error}
          {...rest}
        />
      )}
    />
  )
}
