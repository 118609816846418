export const ROUTES = {
  DASHBOARD: '/',
  LOGIN: '/login',
  RESET_PASSWORD: '/reset-password',
  ADMINISTRATION: '/administrace',
  ADMINISTRATION_CUSTOMER_DETAIL: '/administrace',
  RESERVATION: '/rezervace',
  CREATE_RESERVATION: '/vytvorit-rezervaci',
  NEWS: '/provozni-informace',
  NEWS_DETAIL: '/provozni-informace',
}
