import DownloadIcon from '@mui/icons-material/Download'
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined'
import { Button, Grid, IconButton } from '@mui/material'
import saveAs from 'file-saver'
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { getDataNewsFileRequests } from '../../api/data/excel'
import { getNewsArticleById } from '../../api/data/news'
import { ROUTES } from '../../constants/routes'
import { useIsImageFileFormatAllowed } from '../../hooks/useIsImageFileFormatAllowed'
import { H1 } from '../atoms/Heading'

interface StateInterface {
  nazev: string
  popis: string
  obsah: string
  datum: string
  files: File[]
}

const RenderHTML = ({ html }: { html: string }) => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />
}

export const NewsDetail: React.FC = () => {
  const params = useParams()
  const [blob, setBlob] = useState<Blob | undefined>()
  const [article, setArticle] = useState<StateInterface>({
    nazev: '',
    popis: '',
    obsah: '',
    datum: '',
    files: [],
  })

  const articleId = parseInt(params.articleId as string)

  const handleDownloadPdf = (articleId: number, fileName: string) => {
    getDataNewsFileRequests(articleId, fileName)
      .then(function (response: any) {
        return new Blob([response.data], {
          type: response.headers['content-type'],
        })
      })
      .then(function (blob) {
        setBlob(blob)
      })
      .catch((error) => {
        console.log('error', error)
      })
  }

  useEffect(() => {
    articleId &&
      getNewsArticleById(articleId).then((response) => {
        response &&
          setArticle({
            nazev: response.nazev,
            popis: response.popis,
            obsah: response.obsah,
            datum: response.datumUprava,
            files: response.files,
          })
      })
  }, [])

  useEffect(() => {
    if (article.files?.length > 0) handleDownloadPdf(articleId, article.files[0].name)
  }, [article])

  return (
    <Grid container direction='column' rowSpacing={3} className='pb-16'>
      <Grid item>
        {article.files?.length > 0 &&
          useIsImageFileFormatAllowed(article.files[0].name) &&
          blob && <img src={URL.createObjectURL(blob)} alt='Background image' />}
      </Grid>
      <Grid item>
        <H1 size='H2'>{article.nazev}</H1>
      </Grid>
      <Grid item>
        <Grid container alignItems='center' columnGap={1} className='text-grey-500'>
          <div className='flex items-center text-sm'>
            {' '}
            <EventNoteOutlinedIcon fontSize='inherit' />
          </div>
          {article.datum}
        </Grid>
      </Grid>
      <Grid item>
        <p className='mb-4'>{article.popis}</p>
        <p className='mb-4'>
          <RenderHTML html={article.obsah} />
        </p>
      </Grid>
      {article?.files?.length > 0 &&
        article.files.map((file, index) => (
          <div key={index}>
            {file.name}
            <IconButton
              onClick={() => {
                if (blob) saveAs(blob, file.name)
              }}>
              <DownloadIcon />
            </IconButton>
          </div>
        ))}

      <Grid item>
        <Grid container justifyContent='center'>
          <Link className='no-underline' to={ROUTES.NEWS}>
            <Button size='large' variant='outlined'>
              Všechny provozní informace
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  )
}
