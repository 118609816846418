import { Endpoints } from '../../constants/endpoints'
import { getApiData } from '../_GLOBAL/getApiData'

export type GetDataAdditionalServiceT = {
  id: number
  nazevCz: string
  nazevEn: string
  platnostOd: number
  usernameUprava: string
  datumUprava: number
}

export const getDataAdditionalService = () =>
  getApiData<GetDataAdditionalServiceT[]>(Endpoints.dataAdditionalService())
