import { Endpoints } from '../../constants/endpoints'
import {
  DataProductRequestCountSourceAllT,
  DataProductRequestT,
  DataRequestProductSourceTypeStateT,
  DataRequestT,
  DataSourceTypeT,
} from '../../types/types'
import { getApiData } from '../_GLOBAL/getApiData'

export const getProductRequestNoDuplicates = (requests: DataProductRequestT) => {
  const getAllRequestsOnProduct = requests.pozadavkyNaProdukt.map((requestPerDay) => requestPerDay)
  const getHours = getAllRequestsOnProduct.flatMap((hours) =>
    hours.hodiny.filter((filter) => filter.pozadavky)
  )
  const getAllRequests = getHours.map((hours) => hours.pozadavky).flat()

  const requestIds = getAllRequests.flat(2).map((o) => o.pozadavek.id)

  return getAllRequests
    .flat(2)
    .filter(({ pozadavek }, index) => !requestIds.includes(pozadavek.id, index + 1))
}

export const getDataProductRequestCount = (datumOd: string, datumDo: string) =>
  getApiData(Endpoints.dataProductRequestCount(datumOd, datumDo))

export const getDataProductRequestCountCustomer = (datumOd: string, datumDo: string) =>
  getApiData(Endpoints.dataProductRequestCountCustomer(datumOd, datumDo))

export const getDataProductRequestState = (
  datumOd: string,
  datumDo: string,
  state: DataRequestT['stav']['name']
) => getApiData<DataProductRequestT>(Endpoints.dataProductRequestState(datumOd, datumDo, state))

export const getDataProductRequestStateCustomer = (datumOd: string, datumDo: string) =>
  getApiData(Endpoints.dataProductRequestStateCustomer(datumOd, datumDo))

export const getDataProductRequest = (datumOd: string, datumDo: string) =>
  getApiData<DataProductRequestT>(Endpoints.dataProductRequest(datumOd, datumDo))

export const getDataProductRequestCustomer = (datumOd: string, datumDo: string) =>
  getApiData<DataProductRequestT>(Endpoints.dataProductRequestCustomer(datumOd, datumDo))

export const getDataProductRequestSourceTypeState = (
  datumOd: string,
  datumDo: string,
  sourceType: DataSourceTypeT['name'],
  state: DataRequestT['stav']['name']
) =>
  getApiData<DataRequestProductSourceTypeStateT[]>(
    Endpoints.dataProductRequestSourceState(datumOd, datumDo, sourceType, state)
  )

export const getDataProductRequestCountSourceAll = (datumOd: string, datumDo: string) =>
  getApiData<DataProductRequestCountSourceAllT[]>(
    Endpoints.dataProductRequestCountSourceAll(datumOd, datumDo)
  )

export const getDataProductRequestCountSourceStateAll = (
  datumOd: string,
  datumDo: string,
  state: DataRequestT['stav']['name']
) =>
  getApiData<DataProductRequestCountSourceAllT[]>(
    Endpoints.dataProductRequestCountSourceStateAll(datumOd, datumDo, state)
  )
