import { Alert, Button, TextField } from '@mui/material'
import jwt_decode from 'jwt-decode'
import React, { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import tw from 'tailwind-styled-components'

import { getDataUser } from '../../api/data/user'
import { getBearerToken } from '../../api/login'
import { API } from '../../constants/api'
import { ROUTES } from '../../constants/routes'
import { useDispatch, useSelector } from '../../store/hooks'
import { userSlice } from '../../store/slices/user'
import { BearerTokenDecodeT } from '../../types/types'
import { setToLocalStorage } from '../../utils/localStorage'
import Flex from '../atoms/Flex'
import { H1 } from '../atoms/Heading'

interface ILoginFormInput {
  username: string
  password: string
}

const StyledLoginForm = tw.form`
  max-w-full
  px-5
  mx-auto
  w-full
  sm:px-7
  lg:max-w-340
`

export const LoginPage: React.FC = () => {
  const { control, handleSubmit, reset } = useForm<ILoginFormInput>()

  const initialFormValues = {
    username: '',
    password: '',
  }

  const [loginCount, setLoginCount] = useState(0)
  const [showLoginError, setShowLoginError] = useState(false)
  const [showResetPasswordError, setShowResetPasswordError] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const errorResponse = useSelector((state) => state.login.errorResponse)

  const handleLogin = () => {
    dispatch(
      userSlice.actions.update({
        logged: true,
      })
    )
    navigate('/')
  }

  const onSubmit: SubmitHandler<ILoginFormInput> = (data) => {
    getBearerToken({ username: data.username, password: data.password }, dispatch).then(
      (bearerToken) => {
        if (bearerToken) {
          const decoded: BearerTokenDecodeT = jwt_decode(bearerToken)
          setToLocalStorage(API.BEARER_TOKEN, bearerToken)
          handleLogin()
          getDataUser(data.username).then((user) => {
            if (user) {
              dispatch(
                userSlice.actions.update({
                  firstName: user.jmeno,
                  lastName: user.prijmeni,
                  username: user.username,
                  role: decoded.role,
                  icoZakaznik: user.icoZakaznik,
                  vuz: user.vuz,
                  platnostOd: user.platnostOd,
                })
              )
            }
          })
        }
        setShowLoginError(true)
        reset(initialFormValues)
        setLoginCount(loginCount + 1)
        if (loginCount >= 2) {
          setShowResetPasswordError(true)
        }
      }
    )
  }

  return (
    <StyledLoginForm onSubmit={handleSubmit(onSubmit)}>
      <Flex flexDirection='column'>
        <H1 centered>
          {' '}
          Vítejte <br /> v B2B portálu VÚŽ
        </H1>
        <Flex flexDirection='column' className='mt-8'>
          <Controller
            name='username'
            control={control}
            defaultValue=''
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label='Přihlašovací jméno'
                className='min-w-full'
                variant='outlined'
                margin='none'
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              required: 'Uživatelské jméno nemůže být prazdné.',
            }}
          />

          <div className='mt-8' />

          <Controller
            name='password'
            control={control}
            defaultValue=''
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                type='password'
                label='Heslo'
                className='min-w-full'
                variant='outlined'
                margin='none'
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
            rules={{
              required: 'Heslo nemůže být prázdné.',
            }}
          />
        </Flex>

        <Flex className='mt-5'>
          Zapomněli jste heslo?
          <Link className='font-bold underline ml-4 text-primary-dark' to={ROUTES.RESET_PASSWORD}>
            Zažádat o nové
          </Link>
        </Flex>

        {showLoginError && (
          <>
            <div className='mt-5' />
            <Alert variant='filled' severity='error'>
              {errorResponse}
            </Alert>
          </>
        )}

        {showResetPasswordError && (
          <>
            <div className='mt-5' />
            <Alert variant='filled' severity='error'>
              Pokud problém přetrvává, zažádejte si o{' '}
              <Link className='font-bold underline' to={ROUTES.RESET_PASSWORD}>
                nové heslo
              </Link>{' '}
              nebo zavolejte na číslo +420 241 493 135
            </Alert>
          </>
        )}

        <Flex justifyContent='center' className='mt-10'>
          <Button variant='contained' size='large' type='submit'>
            Přihlásit se
          </Button>
        </Flex>
      </Flex>
    </StyledLoginForm>
  )
}
