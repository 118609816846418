import { Endpoints } from '../../constants/endpoints'
import { FixedDemandSourceT } from '../../types/types'
import { getApiData } from '../_GLOBAL/getApiData'

export const getDataFixedDemandSource = (username: string, datumOd: string, datumDo: string) =>
  getApiData<FixedDemandSourceT>(Endpoints.dataFixedDemandSource(username, datumOd, datumDo))

export const getDataFixedDemandSourceAssigned = (
  username: string,
  datumOd: string,
  datumDo: string
) =>
  getApiData<FixedDemandSourceT>(
    Endpoints.dataFixedDemandSourceAssigned(username, datumOd, datumDo)
  )
