import React from 'react'
import tw from 'tailwind-styled-components'

import { FORMAT } from '../../../../constants/format'
import { RequestDetail } from '../../../../types/types'
import { formatDateWithIncomingFormat } from '../../../../utils/format'
import { REQUEST_DETAIL_DIALOG_TABS } from './RequestDetailDialog'

interface DetailContentProps {
  setSelectedTab: (tabIndex: string) => void
  selectedRequest: RequestDetail
}

const DetailContentWrapper = tw.div`
  grid
  gap-2
  text-sm
  py-3
`

const DetailContentRow = tw.div`
  grid
  grid-cols-3
  gap-3
`

const DetailContentLeft = tw.div`
  col-span-1
`

const DetailContentRight = tw.div`
  col-span-2
`

export const DetailContent: React.FC<DetailContentProps> = ({
  setSelectedTab,
  selectedRequest,
}) => {
  const posledniPoznamka = selectedRequest.poznamky[selectedRequest.poznamky.length - 1]
  // const posledniPoznamka = selectedRequest.podrobnosti.posledniPoznamka
  return (
    <DetailContentWrapper>
      <DetailContentRow>
        <DetailContentLeft>Zákazník</DetailContentLeft>
        <DetailContentRight>{`${selectedRequest.podrobnosti.zadavatel.zakaznik.nazev}`}</DetailContentRight>
      </DetailContentRow>
      <DetailContentRow>
        <DetailContentLeft>Fixní</DetailContentLeft>
        <DetailContentRight>Fixní</DetailContentRight>
      </DetailContentRow>
      <DetailContentRow>
        <DetailContentLeft>Čas vytvoření</DetailContentLeft>
        <DetailContentRight>Čas</DetailContentRight>
      </DetailContentRow>
      <DetailContentRow>
        <DetailContentLeft>Projekt</DetailContentLeft>
        <DetailContentRight>{`${selectedRequest.podrobnosti.projekt.nazevCz}`}</DetailContentRight>
      </DetailContentRow>
      <DetailContentRow>
        <DetailContentLeft>Vozy</DetailContentLeft>
        <DetailContentRight>Lokomotiva</DetailContentRight>
      </DetailContentRow>
      <DetailContentRow>
        <DetailContentLeft>Produkt</DetailContentLeft>
        <DetailContentRight>{`${selectedRequest.podrobnosti.produkt.nazevCz}`}</DetailContentRight>
      </DetailContentRow>
      <DetailContentRow>
        <DetailContentLeft>Dopln. služby</DetailContentLeft>
        <DetailContentRight>
          <ul>
            {selectedRequest.podrobnosti.doplnkoveSluzby.map((sluzba, index) => (
              <li key={index}>{sluzba.nazevCz}</li>
            ))}
          </ul>
        </DetailContentRight>
      </DetailContentRow>
      {posledniPoznamka && (
        <DetailContentRow>
          <DetailContentLeft>Poslední poznámka</DetailContentLeft>
          <DetailContentRight>
            <div className='text-xs text-gray-500'>
              {`${posledniPoznamka.uzivatel.jmeno} ${posledniPoznamka.uzivatel.prijmeni}`}{' '}
              {formatDateWithIncomingFormat(
                posledniPoznamka.datum,
                FORMAT.API_DDMMYYYY_HHMM,
                'DD. MMM., HH:mm:ss'
              )}
            </div>
            <div> {`${posledniPoznamka.text}`}</div>
            <div onClick={() => setSelectedTab(REQUEST_DETAIL_DIALOG_TABS[1].name)}>Číst další</div>
          </DetailContentRight>
        </DetailContentRow>
      )}
    </DetailContentWrapper>
  )
}
